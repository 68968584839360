import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class IpCheckerService {
	cidrToSubnetMask(cidr: string): string {
		const prefixLength = parseInt(cidr.replace('/', ''), 10);
		const mask = (0xffffffff << (32 - prefixLength)) >>> 0; // Generate the subnet mask
		return [
			(mask >>> 24) & 0xff,
			(mask >>> 16) & 0xff,
			(mask >>> 8) & 0xff,
			mask & 0xff,
		].join('.');
	}

	isIpInRange(serverIp: string, networkIp: string, cidr: string): boolean {
		const subnetMask = this.cidrToSubnetMask(cidr);

		const ipToNumber = (ip: string) =>
			ip.split('.').reduce((acc, octet) => (acc << 8) | parseInt(octet, 10), 0);
		const maskToNumber = (mask: string) => ipToNumber(mask);

		const serverIpNum = ipToNumber(serverIp);
		const networkIpNum = ipToNumber(networkIp);
		const subnetMaskNum = maskToNumber(subnetMask);

		const networkAddress = networkIpNum & subnetMaskNum;
		const broadcastAddress = networkAddress | ~subnetMaskNum;

		return serverIpNum >= networkAddress && serverIpNum <= broadcastAddress;
	}
}
