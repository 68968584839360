<div *ngIf="data.menu === undefined" class="pad-L"
	 style="height: 100%; width: 75vw; max-width: 1200px; min-width: 1000px; overflow: hidden;">
	<div class="disp-f j-c-s a-i-c">
		<div>
			<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10"
				 style="height: 45px; width: 45px;">
				<img alt="" src="../../../../../assets/icons/add-server.svg" class="svg-24 filter-grey">
			</div>
		</div>
		<div class="ml-s flex-column gap-XS">
			<div class="font-L fw-700">{{ 'page.infrastructure.addEquipment.title' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.infrastructure.addEquipment.subtitle' | translate }}</div>
		</div>
		<app-icon-button (click)="closeDialog()" class="ml-auto" [img]="'assets/icons/close.svg'" type="stroked"
						 background [panelClass]="'img-size-30 darken-icon'"></app-icon-button>
	</div>

	<div *ngIf="data.menu === undefined" class="equipment-add-container mt-s flex-column justify-center gap-L"
		 style="padding-left: 40px;">
		<div [@inAnimation1] class="equipment-add-button network flex-space-between flex-align-center prevent-select"
			 (click)="data.menu = EquipmentMenu.NETWORK">
			<div class="flex-align-center gap-M">
				<div class="equipment-add-img network">
					<img src="../../../../../assets/icons/network.svg" class="filter-accent-secondary" alt="">
				</div>
				<div class="flex-column flex-align-start flex-justify-center gap-XS">
					<span class="fw-700 font-L">{{ 'page.infrastructure.network.network' | translate }}</span>
					<span class="font-M">{{ 'page.infrastructure.network.addYourNetworks' | translate }}</span>
				</div>
			</div>
			<img src="../../../../../assets/icons/arrow-right-black.svg" class="filter-grey">
		</div>
		<div [@inAnimation2] class="equipment-add-button server flex-space-between flex-align-center prevent-select"
			 (click)="data.menu = EquipmentMenu.SERVER">
			<div class="flex-align-center gap-M">
				<div class="equipment-add-img server">
					<img src="../../../../../assets/servers/other.svg" class="filter-accent" alt="">
				</div>
				<div class="flex-column flex-align-start flex-justify-center gap-XS">
					<span class="fw-700 font-L">{{ 'page.infrastructure.network.server' | translate }}</span>
					<span class="font-M">{{ 'page.infrastructure.network.addYourServers' | translate }}</span>
				</div>
			</div>
			<img src="../../../../../assets/icons/arrow-right-black.svg" class="filter-grey">
		</div>
		<div [@inAnimation3] class="equipment-add-button server flex-space-between flex-align-center prevent-select"
			 (click)="data.menu = EquipmentMenu.ROUTER">
			<div class="flex-align-center gap-M">
				<div class="equipment-add-img server">
					<img src="../../../../../assets/icons/router.svg" class="filter-accent" alt="">
				</div>
				<div class="flex-column flex-align-start flex-justify-center gap-XS">
					<span class="fw-700 font-L">{{ 'page.infrastructure.router.router' | translate }}</span>
					<span class="font-M">{{ 'page.infrastructure.router.addYourRouters' | translate }}</span>
				</div>
			</div>
			<img src="../../../../../assets/icons/arrow-right-black.svg" class="filter-grey">
		</div>
		<div [@inAnimation4] class="equipment-add-button server flex-space-between flex-align-center prevent-select"
			 (click)="data.menu = EquipmentMenu.FIREWALL">
			<div class="flex-align-center gap-M">
				<div class="equipment-add-img server">
					<img src="../../../../../assets/icons/firewall.svg" class="filter-accent" alt="">
				</div>
				<div class="flex-column flex-align-start flex-justify-center gap-XS">
					<span class="fw-700 font-L">{{ 'page.infrastructure.router.firewall' | translate }}</span>
					<span class="font-M">{{ 'page.infrastructure.router.addYourFirewalls' | translate }}</span>
				</div>
			</div>
			<img src="../../../../../assets/icons/arrow-right-black.svg" class="filter-grey">
		</div>
	</div>
</div>
<!-- Preload image to avoid image appearing on next step -->
<div style="opacity: 0; background: url('/assets/illustrations/add-server-auto-bg.svg')"></div>
<app-add-server *ngIf="data.menu === EquipmentMenu.SERVER" (close)="onClose($event, 'server')"
				(onGoBack)="data.menu = undefined"></app-add-server>
<app-add-network *ngIf="data.menu === EquipmentMenu.NETWORK" (close)="onClose($event, 'network')"
				 (onGoBack)="data.menu = undefined"></app-add-network>
<app-add-router *ngIf="data.menu === EquipmentMenu.ROUTER || data.menu === EquipmentMenu.FIREWALL" [type]="data.menu" (close)="onClose($event, 'router')" (onGoBack)="data.menu = undefined"></app-add-router>
