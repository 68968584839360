import {Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Organization, OrganizationService} from "../../../../../services/organization.service";
import {ApplicationGeneric, Health} from "../../../../../services/model/new-application.model";
import {Cost, Usage} from "../../../../../services/model/application-contract.model";
import {finalize, forkJoin, Observable, of, Subscription, switchMap} from "rxjs";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../../services/front/organization-detail.service";
import {tap} from "rxjs/operators";
import {Rating, User} from "../../../../../services/usage.service";
import {ApplicationMiniWidgetModule} from "../../../../global/application-mini-widget/application-mini-widget.module";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {TendencyWidgetContent} from "../../../../global/application-mini-widget/application-mini-widget.component";
import {OrganizationUsersListComponent} from "./organization-users-list/organization-users-list.component";
import {TeamResponsibleComponent, TeamResponsibleData} from "./team-responsible/team-responsible.component";
import {TenantAccountService} from "../../../../../services/back/tenant-account.service";
import {TenantAccount} from "../../../../../services/model/account.model";
import {OrganizationType} from "../../../../../services/model/autodiscover.model";

@Component({
  selector: 'app-organization-overview-tab',
  standalone: true,
	imports: [CommonModule, ApplicationMiniWidgetModule, MatIconModule, TranslateModule, OrganizationUsersListComponent, TeamResponsibleComponent],
  templateUrl: './organization-overview-tab.component.html',
  styleUrl: './organization-overview-tab.component.scss'
})
export class OrganizationOverviewTabComponent implements OnInit, OnDestroy {
	tenantId: string;
	isEditor: boolean;
	organization: Organization;
	isTenantInactivePlan: boolean;

	_initializing: boolean;
	_loading: boolean = false;
	initialized: boolean = true;

	availabilityWidget: TendencyWidgetContent|undefined;
	tcoWidget: TendencyWidgetContent|undefined;
	usageWidget: TendencyWidgetContent|undefined;

	data?: OrganizationOverviewData;

	subscription: Subscription = new Subscription();

	constructor(private organizationDetailService: OrganizationDetailService,
				private organizationService: OrganizationService) {
	}

	ngOnInit() {
		this.subscription.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap((data) => this.setOrganizationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setOrganizationDetailData(data: OrganizationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.organization = data.organization;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.organizationService.getOrganizationOverviewData(this.tenantId, this.organization.organizationId)),
				tap((data) => this.setData(data)),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	setData(data: OrganizationOverviewData): void {
		this.data = data;
		this.availabilityWidget = {
			value: data.health.percent,
			day: data.health.queryType
		};
		this.tcoWidget = {
			value: data.cost.value,
			day: data.cost.queryType
		};
		this.usageWidget = {
			value: data.usage.value,
			day: data.usage.queryType
		};
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly OrganizationType = OrganizationType;
}

export interface OrganizationOverviewData {
	health: Health;
	usage: Usage;
	cost: Cost;
	users: User[];
	incognito: boolean;
}
