import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";


export const URL_REGEXP: RegExp = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
export const PHONE_REGEXP: RegExp = /^\+[1-9]\d{10,14}$/;
// '*://'
export const PREFIX_REGEXP: RegExp = /:\/\//;
// ':*'
export const SUFFIX_PORT_REGEXP: RegExp = /:\w+$/;
// '.*'
export const SUFFIX_DOMAIN_REGEXP: RegExp = /\./;

export const PORTS_RANGE_REGEXP: RegExp = /^(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|\d)(?:,(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|\d))*$|^(?:(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|\d)-(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|[1-9]\d{1,3}|\d))$/;

export const IP_ADDRESS_REGEXP: RegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export function ipAddressValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		const parts: string[] = control.value.split('.');
		const isLeadingZero: boolean = parts.some((part: string) => part.length > 1 && part.startsWith('0'));

		return IP_ADDRESS_REGEXP.test(control.value) && !isLeadingZero ? null : {ipAddress: true};
	};
}

export function prefixValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		return PREFIX_REGEXP.test(control.value) ? null : {prefix: true};
	};
}

export function suffixDomainValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		if (control.value.endsWith('.')) {
			return {suffixDomain: true};
		}

		return SUFFIX_DOMAIN_REGEXP.test(control.value) ? null : {suffixDomain: true};
	};
}

export function suffixPortValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		const port: string | null = control.value.split('?')[0].split('/').slice(0, 3).join('/').split(':')[2];

		if (port == '') {
			return {suffixPort: true};
		}

		if (!port && port != '') {
			return null;
		}

		return SUFFIX_PORT_REGEXP.test(control.value.split('?')[0].split('/').slice(0, 3).join('/')) ? null : {suffixPort: true};
	};
}

export function urlValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!control.value) {
			return null;
		}

		return URL_REGEXP.test(control.value) ? null : {url: true};
	};
}

function checkSubnet(value: string): boolean {
	const subnetStr = value.startsWith('/') ? value.slice(1) : value;
	const subnet = parseInt(subnetStr, 10);
	return !isNaN(subnet) && subnet >= 1 && subnet <= 30;
}

function checkIpAddress(value: string): boolean {
	const parts: string[] = value.split('.');
	const isLeadingZero: boolean = parts.some((part: string) => part.length > 1 && part.startsWith('0'));
	return IP_ADDRESS_REGEXP.test(value) && !isLeadingZero;
}

// The network address validator
function networkAddressValidator(ipControl: AbstractControl, subnetControl: AbstractControl): ValidationErrors | null {
	const ip = ipControl.value;
	const subnetStr = subnetControl.value.startsWith('/') ? subnetControl.value.slice(1) : subnetControl.value;
	const subnet = parseInt(subnetStr, 10);

	if (!ip || isNaN(subnet)) {
		return {invalidNetworkAddress: true};
	}

	const ipParts = ip.split('.').map(Number);
	const subnetMask = ~(2 ** (32 - subnet) - 1);

	const ipInt = (ipParts[0] << 24) | (ipParts[1] << 16) | (ipParts[2] << 8) | ipParts[3];
	const networkAddressInt = ipInt & subnetMask;
	const reconstructedIp = [
		(networkAddressInt >> 24) & 255,
		(networkAddressInt >> 16) & 255,
		(networkAddressInt >> 8) & 255,
		networkAddressInt & 255,
	].join('.');

	if (reconstructedIp !== ip) {
		return {invalidNetworkAddress: true}; // Network address does not match IP
	}

	return null; // No error if valid
}

function isNetworkAddressValid(ip: string, subnetMaskInput: string): boolean {
	const subnetStr = subnetMaskInput.startsWith('/') ? subnetMaskInput.slice(1) : subnetMaskInput;
	const subnet = parseInt(subnetStr, 10);

	if (!ip || isNaN(subnet)) {
		return true;
	}

	const ipParts = ip.split('.').map(Number);
	const subnetMask = ~(2 ** (32 - subnet) - 1);

	const ipInt = (ipParts[0] << 24) | (ipParts[1] << 16) | (ipParts[2] << 8) | ipParts[3];
	const networkAddressInt = ipInt & subnetMask;
	const reconstructedIp = [
		(networkAddressInt >> 24) & 255,
		(networkAddressInt >> 16) & 255,
		(networkAddressInt >> 8) & 255,
		networkAddressInt & 255,
	].join('.');

	if (reconstructedIp !== ip) {
		return false;
	}

	return true;
}

// Validator to check if the subnet is valid (/1 to /30)
export function subnetValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const value = control.value;

		if (!value) {
			return null; // Allow empty for optional fields (if any)
		}

		if (checkSubnet(value)) {
			return null; // Valid subnet range
		}

		return {invalidSubnet: true}; // Invalid subnet error
	};
}

// Validator to combine IP address and subnet validation
export const networkConfigurationValidator: ValidatorFn = (
	control: AbstractControl,
): ValidationErrors | null => {
	const ipControl = control.get('ipAddress');
	const subnetControl = control.get('subnetMask');

	if (!ipControl || !subnetControl || !ipControl.value || !subnetControl.value) {
		return null; // Do not validate if controls are missing or empty
	}

	const isValidIpAddress = checkIpAddress(ipControl.value);
	const isValidSubnet = checkSubnet(subnetControl.value);

	if (!isValidSubnet || !isValidIpAddress) {
		return null; // If IP or Subnet is invalid, skip further validation
	}

	// Call the network address validator
	const networkValidation = networkAddressValidator(ipControl, subnetControl);
	if (networkValidation) {
		return networkValidation; // Return error if network is invalid
	}

	return null; // All validations pass
};

export function isNetworkConfigurationValid(ipAddress: string, subnet: string): boolean {
	const isValidIpAddress = checkIpAddress(ipAddress);
	const isValidSubnet = checkSubnet(subnet);

	if (!isValidSubnet || !isValidIpAddress) {
		return true;
	}

	return isNetworkAddressValid(ipAddress, subnet);
}
