<div class="flex-column gap-M" [ngClass]="{ 'pad-L': !_loading && (globalAppCount !== undefined && globalAppCount > 0), 'no-app-background': (globalAppCount !== undefined && globalAppCount <= 0) }" style="min-width: 950px; margin-top: 5px;" [style.height]="_loading || (globalAppCount !== undefined && globalAppCount <= 0) ? 'calc(100% - 85px)' : ''">
    <div class="flex gap-M header-container" [ngClass]="{ 'w-100': !_loading && (globalAppCount !== undefined && globalAppCount > 0), 'pad-L': _loading || (globalAppCount !== undefined && globalAppCount <= 0), 'w-auto': _loading || (globalAppCount !== undefined && globalAppCount <= 0) }">
        <div class="flex-align-center search-bar-container bkg-white b-radius-9">
            <app-text-button type="default" color="primary" class="search-btn" panelClass="h-100-imp pad-0-imp"
                             [disabled]="_initializing"
                             (click)="openSearchPanel(input)">
                <div class="flex-center">
                    <img src="assets/icons/search-md.svg" width="18" height="18" alt=""/>
                </div>
            </app-text-button>
			<div class="flex-space-between">
				<input #input class="search-input" [ngClass]="{'display-input': openSearch}"
					   [formControl]="$any(filterForm.get(form.name))" [placeholder]="openSearch ? ('page.application.table.filters.search'|translate) : ''">
				<app-text-button (click)="clearInput()" *ngIf="openSearch && searchValue" type="default" color="primary" panelClass="h-100-imp pad-0-imp" class="delete-text-button mr-xs">
					<div class="flex-center">
						<img src="assets/icons/close.svg" alt="">
					</div>
				</app-text-button>
				<div *ngIf="openSearch && !searchValue" style="width: 35px; height: 30px;">
				</div>
			</div>
        </div>
        <div class="flex-align-center filter-bar bkg-white b-radius-9 w-100">
            <div class="flex-align-center h-100 filter-btn-container" style="z-index: 2;">
                <app-dropdown #filterDropdown>
                    <div class="flex-column gap-S" style="width: 300px;"
                         *ngIf="!_initializing && !_loadingFilter && !!list">
                        <div class="flex-column gap-S">
                            <span class="font-M fw-500 grey">{{'page.application.table.filters.criticality'|translate}}</span>
                            <app-multi-select [inputControl]="$any(filterForm.get(form.criticality))" multiple>
                                <app-select-trigger appearance="field">{{!$any(filterForm.get(form.criticality)).value[0] ? '-' : ((('page.appDetails.businessCriticality.' + $any(filterForm.get(form.criticality)).value[0].toLowerCase())|translate) + ($any(filterForm.get(form.criticality)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.criticality)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-option *ngFor="let level of list.criticality" [value]="level">
                                    <div class="flex-align-center gap-M">
                                        <img src="assets/icons/criticality-{{level.toLowerCase()}}.svg" height="16" alt=""/>
                                        <span>{{('page.appDetails.businessCriticality.' + level.toLowerCase())|translate}}</span>
                                    </div>
                                </app-select-option>
                            </app-multi-select>
                        </div>
                        <div class="flex-column gap-S">
                            <span class="font-M fw-500 grey">{{'page.application.table.filters.category'|translate}}</span>
                            <app-multi-select [inputControl]="$any(filterForm.get(form.category))" multiple [addMinHeight]="true">
                                <app-select-trigger appearance="field">{{!$any(filterForm.get(form.category)).value[0] ? '-' : ($any(filterForm.get(form.category)).value[0].name  + ($any(filterForm.get(form.category)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.category)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-search [searchControl]="$any(filterForm.get(form.searchCategory))"></app-select-search>
                                <app-select-option *ngFor="let cat of list.category" [value]="cat">{{cat.name}}</app-select-option>
                            </app-multi-select>
                        </div>
                        <div class="flex-column gap-S">
                            <span class="font-M fw-500 grey">{{'page.application.table.filters.type'|translate}}</span>
                            <app-multi-select [inputControl]="$any(filterForm.get(form.hostingType))" multiple>
                                <app-select-trigger appearance="field">{{!$any(filterForm.get(form.hostingType)).value[0] ? '-' : ((('page.dashboard.typology.' + $any(filterForm.get(form.hostingType)).value[0])|translate)  + ($any(filterForm.get(form.hostingType)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.hostingType)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-option *ngFor="let type of list.hostingType" [value]="type">
                                    {{('page.dashboard.typology.' + type)|translate}}
                                </app-select-option>
                            </app-multi-select>
                        </div>
                        <div class="flex-column gap-S">
                            <span class="font-M fw-500 grey">{{'page.application.table.filters.team'|translate}}</span>
                            <app-multi-select [inputControl]="$any(filterForm.get(form.organization))" multiple [addMinHeight]="true">
                                <app-select-trigger appearance="field">{{!$any(filterForm.get(form.organization)).value[0] ? '-' : ($any(filterForm.get(form.organization)).value[0].name  + ($any(filterForm.get(form.organization)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.organization)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-search [searchControl]="$any(filterForm.get(form.searchOrganization))"></app-select-search>
                                <app-select-option-group *ngFor="let group of list.organization" [label]="group.organization.name" noOptionPrefix>
                                    <app-select-option *ngFor="let org of group.children" [value]="org.organization">
                                        <div class="flex-align-center gap-M">
                                            <img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
                                            <span>{{org.organization.name}}</span>
                                        </div>
                                    </app-select-option>
                                </app-select-option-group>
                            </app-multi-select>
                        </div>
                        <div class="flex-column gap-S">
                            <span class="font-M fw-500 grey">{{'page.application.table.filters.responsible'|translate}}</span>
                            <app-multi-select [inputControl]="$any(filterForm.get(form.account))" multiple [addMinHeight]="true">
                                <app-select-trigger appearance="field">{{!$any(filterForm.get(form.account)).value[0] ? '-' : ($any(filterForm.get(form.account)).value[0].account.firstName + ' ' + $any(filterForm.get(form.account)).value[0].account.lastName + ($any(filterForm.get(form.account)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.account)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-search [searchControl]="$any(filterForm.get(form.searchAccount))"></app-select-search>
                                <app-select-option *ngFor="let user of list.account" [value]="user">
                                    <app-display-user [user]="user" size="S"></app-display-user>
                                </app-select-option>
                            </app-multi-select>
                        </div>
                        <div class="flex-column gap-S">
                            <span class="font-M fw-500 grey">{{'page.application.table.filters.status'|translate}}</span>
                            <app-multi-select [inputControl]="$any(filterForm.get(form.status))" multiple>
                                <app-select-trigger appearance="field">{{!$any(filterForm.get(form.status)).value[0] ? '-' : ((('page.appDetails.lifeCycle.' + $any(filterForm.get(form.status)).value[0])|translate) + ($any(filterForm.get(form.status)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.status)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-option *ngFor="let appStatus of list.status" [value]="appStatus">
									<app-display-app-status [appStatus]="appStatus"></app-display-app-status>
                                </app-select-option>
                            </app-multi-select>
                        </div>
                        <div class="flex-column gap-S">
                            <span class="font-M fw-500 grey">{{'page.application.table.filters.tags'|translate}}</span>
                            <app-multi-select [inputControl]="$any(filterForm.get(form.tag))" multiple>
                                <app-select-trigger appearance="field">{{!$any(filterForm.get(form.tag)).value[0] ? '-' : ($any(filterForm.get(form.tag)).value[0].name  + ($any(filterForm.get(form.tag)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.tag)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-option *ngFor="let tag of list.tag" [value]="tag">
                                    {{tag.name}}
                                </app-select-option>
                            </app-multi-select>
                        </div>
                    </div>
                    <div class="flex w-100" style="margin: 20px -20px -20px -20px; width: calc(100% + 40px);">
                        <app-text-button color="primary" class="w-100" panelClass="bkg-light-grey-2-imp b-radius-bottom-9"
                                         (click)="clearFilters(); filterDropdown.close();">
                            <div class="flex-align-center gap-S" style="margin-left: -15px;">
                                <img src="assets/icons/close.svg" width="12" height="12" alt=""/>
                                <span>{{'page.application.table.filters.clear'|translate}}</span>
                            </div>
                        </app-text-button>
                    </div>
                </app-dropdown>
                <app-text-button type="default" color="primary" class=filter-btn panelClass="h-100-imp"
                                 [badge]="activeFilters.length > 0 ? activeFilters.length : undefined"
                                 [disabled]="_initializing || !list" [loading]="_loadingFilter"
                                 [dropdownTrigger]="filterDropdown">
                    <div class="flex-center gap-S">
                        <span>{{'page.application.table.filters.button'|translate}}</span>
                        <img src="assets/icons/filter-lines.svg" width="16" height="16" alt=""/>
                    </div>
                </app-text-button>
            </div>
            <div class="flex-align-center w-100 h-100 border-light-grey-left pad-left-S filter-panel" style="z-index: 1; transition: 0s;" #responsiveFilterPanel
                 [ngClass]="{'filter-panel-open': openFilters}" [style.top.px]="(responsiveFilterPanel.offsetHeight / 2) - 26.5" >
                <div class="flex-wrap flex-align-center h-100 gap-S" style="width: calc(100% - 30px); margin: 12px 0;"
                     [ngStyle]="{'max-height': openFilters ? '100%' : '28px'}" [ngClass]="{'overflow-hidden': !openFilters}">
                    @for (filter of activeFilters; track filter.id) {
                        <div class="flex-center horizontal-bar-L bkg-light-grey-2 pad-0-M gap-M">
                            <span class="font-S fw-500">{{filter.value}}</span>
                            <img class="filter-grey c-p" src="assets/icons/close.svg" width="9" height="9" alt="" style="z-index: 9999"
                                 (click)="removeFilter(filter)"/>
                        </div>
                    }
                </div>
                <div class="flex-center b-radius-right-9 c-p" style="width: 30px; height: 30px;"
                     (click)="openFilters = !openFilters" *ngIf="activeFilters.length">
                    <img src="assets/icons/arrow-right-black.svg" class="rotate-90 filter-grey" height="12" alt="">
                </div>
            </div>
        </div>
        <div class="flex-align-center filter-bar bkg-white b-radius-9" style="padding: 3px;">
            <app-text-button type="default" color="primary"  style="width: 43px; height: 43px;" panelClass="h-100-imp pad-0-imp"
                             [matMenuTriggerFor]="selectMenu" [disabled]="_initializing || _loading" [loading]="_exporting">
                <div class="flex-center">
                    <img src="assets/icons/dots-vertical.svg" height="18" alt=""/>
                </div>
            </app-text-button>
            <mat-menu #selectMenu class="mat-menu-custom"
                      xPosition="before" yPosition="below">
                <div class="flex-align-center gap-M greyed-hover b-radius-6 c-p" style="padding: 6px;"
                     (click)="exportCsv()">
                    <img src="assets/icons/share.svg" width="18" height="18" alt=""/>
                    <div class="font-M fw-500">{{'page.application.table.filters.export'|translate}}</div>
                </div>
                <div class="flex-align-center gap-M greyed-hover b-radius-6 c-p" style="padding: 6px;"
                     (click)="exportXlsx()">
                    <img src="assets/icons/layout-grid-02.svg" width="18" height="18" alt=""/>
                    <div class="font-M fw-500">{{'page.application.table.filters.exportX'|translate}}</div>
                </div>
            </mat-menu>
        </div>
        <app-text-button type="stroked" color="accent-secondary" panelClass="h-100-imp b-radius-9"
                         [badge]="pendingAutodiscoverAppCount > 0 ? pendingAutodiscoverAppCount : undefined"
                         [disabled]="_initializing || _loadingAutoDiscover"
                         (click)="openAutoDiscoverDrawer()">
            <div class="flex-align-center gap-S">
                <img src="assets/icons/sonar.svg" width="26" height="26" style="margin-left: -6px; margin-bottom: -3px;" alt=""/>
                <span class="font-M fw-500">{{'page.application.table.autoDiscover'|translate}}</span>
            </div>
        </app-text-button>
        <app-text-button type="flat" color="accent" panelClass="h-100-imp b-radius-9"
                         [disabled]="_initializing"
                         (click)="openCreateApplication()">
            <span class="font-M fw-500">{{'page.application.table.addApplication'|translate}}</span>
        </app-text-button>
    </div>

    <div *ngIf="globalAppCount !== undefined && globalAppCount > 0 && !_loading" class="flex-column app-list-content bkg-white">
        <table class="app-list-table" aria-describedby="apps" [style.height]="applicationRows.length <= 0 ? 'calc(100vh - 193px)' : ''">
            <tr class="app-list-header">
                <th>
                    <div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p" style="border-top-left-radius: 14px; padding-left: 67px;"
                         [ngClass]="{'sort-active': sortFormValue.column === column.NAME}"
                         (click)="sortBy(column.NAME)">
                        <span class="font-M fw-500 text-left grey">{{'page.application.table.name'|translate}}</span>
                        <img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10" alt=""
                             [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.NAME ? '90' : '-90') + 'deg)'}">
                    </div>
                </th>
                <th>
                    <div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
                         [ngClass]="{'sort-active': sortFormValue.column === column.HEALTH}"
                         (click)="!_loading ? sortBy(column.HEALTH) : {}">
                        <span class="font-M fw-500 text-left grey">{{'page.application.table.health'|translate}}</span>
                        <img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10" alt=""
                             [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.HEALTH ? '90' : '-90') + 'deg)'}">
                    </div>
                </th>
                <th>
                    <div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
                         [ngClass]="{'sort-active': sortFormValue.column === column.CATEGORY}"
                         (click)="sortBy(column.CATEGORY)">
                        <span class="font-M fw-500 text-left grey">{{'page.application.table.category'|translate}}</span>
                        <img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10" alt=""
                             [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.CATEGORY ? '90' : '-90') + 'deg)'}">
                    </div>
                </th>
                <th>
                    <div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
                         [ngClass]="{'sort-active': sortFormValue.column === column.CRITICALITY}"
                         (click)="sortBy(column.CRITICALITY)">
                        <span class="font-M fw-500 text-left grey">{{'page.application.table.criticality'|translate}}</span>
                        <img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10" alt=""
                             [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.CRITICALITY ? '90' : '-90') + 'deg)'}">
                    </div>
                </th>
                <th>
                    <div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
                         [ngClass]="{'sort-active': sortFormValue.column === column.USAGE}"
                         (click)="!_loading ? sortBy(column.USAGE) : {}">
                        <span class="font-M fw-500 text-left grey">{{'page.application.table.users'|translate}}</span>
                        <img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10" alt=""
                             [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.USAGE ? '90' : '-90') + 'deg)'}">
                    </div>
                </th>
                <th>
                    <div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p" style="border-top-right-radius: 14px;"
                         [ngClass]="{'sort-active': sortFormValue.column === column.COST}"
                         (click)="sortBy(column.COST)">
                        <span class="font-M fw-500 text-left grey">{{'page.application.table.cost'|translate}}</span>
                        <img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10" alt=""
                             [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.COST ? '90' : '-90') + 'deg)'}">
                    </div>
                </th>
            </tr>
            @for (app of applicationRows; track app.instance.applicationId) {
                <tr class="app-list-row font-M fw-500 greyed-hover c-p"
                    (click)="openApplicationDrawer(app.instance.applicationId)">
                    <td class="w-25">
                        <div class="flex" style="max-width: 300px; padding-left: 3px;">
                            <app-display-application class="w-100" size="L" [application]="app.instance" [description]="app.instance.description" bold></app-display-application>
                        </div>
                    </td>
                    <td class="w-10">
                        <div class="flex">
                            <app-chips-health [health]="!app.instance.usageActivated ? null : app.health" tooltipPosition="right"
                                              [tooltip]="('page.appDetails.usage.tooltipHistogramDeactivated1'|translate) + ' ' + ('page.appDetails.usage.settings'|translate)"
                                              [tooltipDisabled]="app.instance.usageActivated"></app-chips-health>
                        </div>
                    </td>
                    <td class="w-20">
                        <span class="font-M fw-500">{{app.category?.name}}</span>
                    </td>
                    <td class="w-10">
                        <div class="flex">
                            <app-chips-criticality [criticality]="app.instance.criticality" short></app-chips-criticality>
                        </div>
                    </td>
                    <td class="w-10">
                        <div class="flex">
                            <app-chips-count [value]="!app.instance.usageActivated ? undefined : app.usage?.value || 0" type="user" bold tooltipPosition="right"
                                             [tooltip]="('page.appDetails.usage.tooltipHistogramDeactivated1'|translate) + ' ' + ('page.appDetails.usage.settings'|translate)"
                                             [tooltipDisabled]="app.instance.usageActivated"></app-chips-count>
                        </div>
                    </td>
                    <td class="w-25">
                        <div class="flex-space-between pad-right-S">
                            <span class="font-M fw-500">
                                {{(app.cost.value|cost)}}
                            </span>
                            <img src="assets/icons/arrow-right.svg" width="9" alt=""/>
                        </div>
                    </td>
                </tr>
            } @empty {
				<tr>
					<td colspan="6">
						<div class="flex-center flex-column gap-XL">
							<img src="assets/illustrations/empty-app.svg">
							<span class="grey">{{'page.application.table.noData'|translate}}</span>
						</div>
					</td>
				</tr>
			}
        </table>
        <div *ngIf="applicationRows.length > 0" class="app-list-footer bkg-white">
            <div class="font-M fw-500 pad-M grey">{{'Total ' + applicationRows.length + ' application(s)'}}</div>
        </div>
    </div>
	<div *ngIf="_loading" class="h-100 flex-justify-center flex-align-center flex-column gap-20">
		<app-spinner color="grey" [scale]="0.8"></app-spinner>
	</div>
	<div *ngIf="globalAppCount !== undefined && globalAppCount <= 0 && !_loading" class="h-100 flex-justify-center flex-align-center flex-column gap-20">
		<span class="font-XL fw-700 text-center primary">{{ 'page.application.noApplication.1' | translate }}</span>
		<span class="fw-500 text-center font-L primary" style="line-height: 1.6rem;">
            {{ 'page.application.noApplication.2' | translate }}
            <span class="fw-700">{{ 'page.application.noApplication.3' | translate }}</span>
            {{ 'page.application.noApplication.4' | translate }}
            <span class="fw-700">{{ 'page.application.noApplication.5' | translate }}</span>
            {{ 'page.application.noApplication.6' | translate }}<br>
			{{ 'page.application.noApplication.7' | translate }}
        </span>
		<app-text-button (click)="openCreateApplication()" class="mt-l w-20" style="min-width: 200px;" color='accent' type='flat'>
			{{ 'global.letsGo' | translate }}
		</app-text-button>
	</div>
</div>
