import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from './security.utils';

@Injectable()
export class AccountService {

	private serviceUrl = () => `${environment.engineURL}/accounts`;

	constructor(protected httpClient: HttpClient) {
	}

	createAccount(form: CreateAccountForm): Observable<ValidateAccountId> {
		return this.httpClient.post<ValidateAccountId>(`${this.serviceUrl()}`, form, {
			headers: SecurityUtils.getHttpHeaders()
		});
	}

	resendValidationEmail(email: ResendEmailForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.serviceUrl()}/resendEmail`, email, {
			headers: SecurityUtils.getHttpHeaders()
		});
	}

	askForPasswordRenew(request: RenewPasswordForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.serviceUrl()}/password/reset`, request, {
			headers: SecurityUtils.getHttpHeaders()
		});
	}

	renewPassword(request: RenewPasswordValidationForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.serviceUrl()}/password/validate`, request, {
			headers: SecurityUtils.getHttpHeaders()
		});
	}

	deleteAccount(): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl()}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	changePasswordWithCheck(request: ChangePasswordWithCheckRequest): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl()}/password`, request, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getSignupDate(): Observable<Date> {
		return this.httpClient.get<Date>(`${this.serviceUrl()}/sign-up-date`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}
}

interface CreateAccountForm {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	reCaptchaToken: string;
}

interface ResendEmailForm {
	email: string;
}

interface RenewPasswordForm {
	email: string;
}

interface RenewPasswordValidationForm {
	password: string;
	token: string;
}

interface ChangePasswordWithCheckRequest {
	oldPassword: string,
	newPassword: string,
	userId: string
}

interface ValidateAccountId {
	validationId: string;
}
