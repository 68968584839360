<div class="flex-align-center flex-space-between mb-m">
	<span class="font-MM fw-700">Charge</span>
</div>
<div class="w-100" style="position: relative" *ngIf="data.length > 0" (mouseout)="tooltip.display = 'none'" [style.height.px]="185">
    <canvas baseChart
            [data]="lineChartData"
            [type]="chartType"
            [options]="chartOptions"
    ></canvas>
    <div class="tooltip" style="padding: 3px 7px; pointer-events: none;" [ngStyle]="{'top': tooltip.top, 'left': tooltip.left, 'display': tooltip.display}" >
        <div class="flex-space-between font-M fw-700">
            <div>
                <span>{{ tooltip.day }} {{ 'global.monthShort.' + tooltip.month | translate }} {{ tooltip.year }}</span>
            </div>
            <div>
                <span *ngIf="!tooltip.noData" style="color:#3DC0FF;">{{ tooltip.value }}%</span>
                <span *ngIf="tooltip.noData" style="color:#B5B5C3;">-</span>
            </div>
        </div>
    </div>
</div>
