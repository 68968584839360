import {Component, OnDestroy, OnInit} from '@angular/core';
import {TenantService} from 'src/app/services/tenant.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService, UserInfo} from 'src/app/services/authentication.service';
import {filter, switchMap, tap} from 'rxjs/operators';
import {InviteUsersComponent} from './invite-users.component';
import {finalize, Observable, of, Subscription} from 'rxjs';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {ConfirmComponent, ConfirmModel} from "../../../global/dialog/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';
import {TenantAccountRole, TenantAccountRoleForm, TenantAccount} from 'src/app/services/model/account.model';
import {FormControl} from "@angular/forms";

@Component({
	selector: 'app-settings-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

	tenantId: string;
	currentUser: UserInfo | null;
	isAdmin = false;
	// Users
	isLoadingUsers = false;
	roles: SelectRole[] = [];
	users: TenantAccount[] = [];
	userRoleForms: Map<string, FormControl> = new Map();

	inviting: {
		[key: string]: boolean;
	}

	isRefreshing = false;

	subscription: Subscription = new Subscription();

	constructor(protected currentTenantService: CurrentTenantService,
	            protected tenantService: TenantService,
	            protected tenantAccountService: TenantAccountService,
	            protected authenticationService: AuthenticationService,
	            protected rightSliderService: RightSliderService,
	            protected matDialog: MatDialog,
	            protected snackbar: SnackbarService,
	            protected translateService: TranslateService) {
		this.roles = [
			{id: 'read_only', value: translateService.instant('role.read_only')},
			{id: 'read_write', value: translateService.instant('role.read_write')},
			{id: 'admin', value: translateService.instant('role.admin')},
			{id: 'no_access', value: translateService.instant('role.no_access')}
		];
	}

	ngOnInit() {
		this.currentUser = this.authenticationService.getUserInfo();
		this.isLoadingUsers = true;
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(
				tap(tenant => this.tenantId = tenant.configuration.id),
				tap(tenant => this.isAdmin = tenant.configuration.role === 'admin'),
				switchMap(() => this.fetchUsers()))
			.subscribe(() => this.isLoadingUsers = false));
	}

	fetchUsers(): Observable<{}> {
		return this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId)
			.pipe(tap((users) => this.setUsers(users)));
	}

	private setUsers(tenantUsers: TenantAccount[]): void {
		this.users = tenantUsers;
		this.users.forEach(user => {
			this.userRoleForms.set(user.account.accountId, new FormControl(user.role));
			this.userRoleForms.get(user.account.accountId)!.valueChanges
				.subscribe(value => this.updateRole(user, value));
		});
	}

	getUserRoleControlForm(userId: string): FormControl {
		return this.userRoleForms.get(userId) as FormControl;
	}

	updateRole(user: TenantAccount, role: TenantAccountRole): void {
		const form: TenantAccountRoleForm = {
			role: role
		};
		this.tenantAccountService.updateTenantAccountRole(this.tenantId, user.account.accountId, form)
			.subscribe(() => {
				user.role = role;
				this.snackbar.show(user.account.firstName + ' ' + user.account.lastName + this.translateService.instant('page.user.isNow') + this.translateService.instant('global.role.' + role), 'success-snack')
			});
	}

	openInviteUsersPopup(): void {
		this.matDialog.open(InviteUsersComponent).afterClosed().subscribe(() => this.onRefresh(false));
	}

	inviteUser(user: TenantAccount): void {
		this.switchInviting(user)
			.pipe(
				switchMap(() => this.tenantAccountService.sendInviteEmail(this.tenantId, user.account.accountId)),
				tap(() => this.snackbar.show(this.translateService.instant('page.user.invitationSent'))),
				switchMap(() => this.fetchUsers()),
				finalize(() => this.switchInviting(user))
			).subscribe()
	}

	switchInviting(user: TenantAccount): Observable<{}> {
		this.inviting = this.inviting || {};
		this.inviting[user.account.accountId] = !this.inviting[user.account.accountId];
		return of({});
	}

	isInviting(user: TenantAccount): boolean {
		if (!this.inviting) {
			return false;
		} else {
			return this.inviting[user.account.accountId] || false;
		}
	}

	onRefresh(showSnackbar = true): void {
		this.isRefreshing = true;
		this.fetchUsers().subscribe(() => {
			if (showSnackbar) {
				this.snackbar.show('List of user has been refreshed.', 'success-snack');
			}
			this.isRefreshing = false;
		});
	}

	onOpenUserPopup(user: TenantAccount, event: MouseEvent) {
		event.stopPropagation();
		const dialogData: ConfirmModel = {
			message: user.pending
				? this.translateService.instant('confirmModal.deletePendingUser')
				: this.translateService.instant('confirmModal.deleteAccount'),
			confirmButton: this.translateService.instant('button.delete'),
			closeButton: this.translateService.instant('button.cancel')
		};
		this.matDialog.open(ConfirmComponent, { data: dialogData }).afterClosed()
			.pipe(filter(dialogResult => !!dialogResult))
			.subscribe(() => this.deleteAccount(user));
	}

	private deleteAccount(user: TenantAccount): void {
		this.tenantAccountService.deleteTenantAccount(this.tenantId, user.account.accountId).subscribe(() => {
			this.onRefresh(false);
			this.snackbar.show(this.translateService.instant('page.application.detail.update.success'));
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.rightSliderService.close();
	}

	protected readonly TenantAccountRole = TenantAccountRole;
}

interface SelectRole {
	id: string;
	value: string;
}
