import { Component } from '@angular/core';
import {Server} from "../../../../../../../services/model/resource.model";
import {Network, NetworkService} from "../../../../../../../services/back/network.service";
import {FormControl} from "@angular/forms";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {ServerDetailData, ServerDetailService} from "../../../../../../../services/front/server-detail.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ServerService} from "../../../../../../../services/back/server.service";
import {filter} from "rxjs/operators";
import {DesignSystemModule} from "../../../../../../design-system/design-system.module";
import {MiniButtonModule} from "../../../../../../global/button/mini-button/mini-button.module";
import {NgForOf, NgIf} from "@angular/common";
import {Router} from "@angular/router";
import {
	INFRASTRUCTURE_NETWORKS_URL,
} from "../../../../../../../models/home/navigation.model";
import {SnackbarService} from "../../../../../../../services/front/snackbar.service";
import {IpCheckerService} from "../../../../../../../services/front/ip-checker.service";

@Component({
  selector: 'app-server-network',
  standalone: true,
	imports: [
		DesignSystemModule,
		MiniButtonModule,
		NgIf,
		TranslateModule,
		NgForOf
	],
  templateUrl: './server-network.component.html',
  styleUrl: './server-network.component.scss'
})
export class ServerNetworkComponent {
	tenantId: string;
	server: Server;

	_initializing: boolean;
	_loading: boolean;
	_saving: boolean;
	initialized: boolean = false;

	network: Network | null;
	networks: Network[] = [];

	networkFormControl: FormControl;

	subscription: Subscription = new Subscription();

	constructor(private serverDetailService: ServerDetailService,
				private serverService: ServerService,
				private snackBarService: SnackbarService,
				private networkService: NetworkService,
				private translateService: TranslateService,
				private router: Router,
				private ipCheckerService: IpCheckerService) {
	}

	ngOnInit() {
		this.buildForm();
		this.subscription.add(this.serverDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.serverDetailService.getServerDetailDataChanges()
			.pipe(tap(data => this.setServerDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	buildForm(): void {
		this.networkFormControl = new FormControl<Network | null>(null);

		this.networkFormControl.valueChanges
			.pipe(
				tap(() => this.switchSaving()),
				filter(() => this.networkFormControl.valid),
				switchMap(network => this.serverService.updateServerNetwork(this.tenantId, this.server.id, network.id)),
				tap(() => this.switchSaving()),
				filter((result) => result),
				tap(() => this.snackBarService.show(this.translateService.instant('page.infrastructure.server.network.success')))
			).subscribe(() => this.serverDetailService.refreshServer());
	}

	setServerDetailData(data: ServerDetailData): void {
		this.tenantId = data.tenantId;
		this.server = data.server;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.serverService.getServerNetwork(this.tenantId, this.server.id),
					this.networkService.getTenantNetworks(this.tenantId)
				])),
				tap(([network, networks]) => {
					this.network = network;
					this.networks = networks;
					this.networkFormControl.setValue(network, {emitEvent: false});
				}),
				finalize(() => this.switchLoading())
			)
			.subscribe());
	}

	goToNetwork(network: Network): void {
		this.router.navigate([INFRASTRUCTURE_NETWORKS_URL], {
			queryParams: {
				tenant: this.tenantId,
				networkId: network.id
			}
		});
	}

	isIpInRange(serverIp: string, networkIp: string, subnet: string): boolean {
		return this.ipCheckerService.isIpInRange(serverIp, networkIp, subnet);
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}
}
