import {ChangeDetectorRef, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {TenantAccount} from "../../../../../../services/model/account.model";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {
	ApplicationDetailData,
	ApplicationDetailService
} from "../../../../../../services/front/application-detail.service";
import {
	ApplicationInstance, ApplicationResponsible, ApplicationResponsibleForm,
} from "../../../../../../services/model/new-application.model";
import {TenantAccountService} from "../../../../../../services/back/tenant-account.service";
import {NewApplicationService} from "../../../../../../services/back/new-application.service";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {filter, map} from "rxjs/operators";
import {TenantService} from "../../../../../../services/tenant.service";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-application-responsible',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule, ContentLoaderModule],
  templateUrl: './application-responsible.component.html',
  styleUrl: './application-responsible.component.scss'
})
export class ApplicationResponsibleComponent implements OnInit, OnDestroy {
	subscription: Subscription = new Subscription();

	application: ApplicationInstance;
	tenantId: string;

	hovered: boolean = false;

	tenantAccounts: TenantAccount[] = [];
	tenantAccountFilteredList: TenantAccount[] = [];
	applicationResponsibles: TenantAccount[] = [];

	validators: Validators[] = [
		Validators.required,
		Validators.minLength(1),
		Validators.email
	];

	applicationResponsiblesControl: FormControl<TenantAccount[]> = new FormControl([], { nonNullable: true });
	searchUserControl: FormControl<string|null> = new FormControl('');

	initialized: boolean = false;
	_initializing: boolean;
	_loading: boolean;

	constructor(private applicationDetailService: ApplicationDetailService,
				private tenantAccountService: TenantAccountService,
				private newApplicationService: NewApplicationService,
				private tenantService: TenantService,
				private snackBarService: SnackbarService,
				private translateService: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.application = data.instance;
		this.tenantId = data.tenantId;
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId),
					this.newApplicationService.getApplicationResponsibles(this.tenantId, this.application.applicationId)
				])),
				tap(([usersList, responsibles]) => this.setData(usersList, responsibles)),
				finalize(() => this.switchLoading())
			).subscribe(() => this.initialized = true);

		this.subscription.add(this.applicationResponsiblesControl.valueChanges
			.pipe(
				tap((value: TenantAccount[]) => {
					const toAdd = value.filter(user => !this.applicationResponsibles.find(responsible => responsible.account.accountId === user.account.accountId));
					const toRemove = this.applicationResponsibles.filter(responsible => !value.find(user => user.account.accountId === responsible.account.accountId));
					toAdd.forEach(user => this.addResponsible(user));
					toRemove.forEach(user => this.removeResponsible(user));
				})
			).subscribe());

		this.subscription.add(this.searchUserControl.valueChanges
			.pipe(
				tap(value => this.tenantAccountFilteredList = this.tenantAccounts.filter(user => {
					const search = value?.toLowerCase() || '';
					return user.account.firstName.toLowerCase().includes(search)
						|| user.account.lastName.toLowerCase().includes(search)
						|| user.account.email.toLowerCase().includes(search);
				}))
			).subscribe());
	}

	setData(usersList: TenantAccount[], responsibles: TenantAccount[]): void {
		this.tenantAccountFilteredList = usersList
		this.tenantAccounts = usersList;
		this.applicationResponsibles = responsibles;
		this.applicationResponsiblesControl.setValue(responsibles, {emitEvent: false});
	}

	onInviteResponsible(value: { value: string, toggle: boolean }): void {
		const existingUser = this.tenantAccounts.find(user => user.account.email === value.value);
		if (!existingUser) {
			this.tenantService.inviteUsers(this.tenantId, [{ email: value.value, grantAccess: value.toggle }])
				.pipe(
					switchMap(() => this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId)),
					tap(users => {
						this.tenantAccounts = users
							.sort((a, b) => `${a.account.firstName} ${a.account.lastName}`.localeCompare(`${b.account.firstName} ${b.account.lastName}`))
							.sort((a, b) => a.pending ? 1 : -1)

						this.tenantAccountFilteredList = this.tenantAccounts;
					}),
					map(() => this.tenantAccounts.find(user => user.account.email === value.value)),
					filter(user => !!user),
					tap(user => this.addResponsible(user!)))
				.subscribe();
		} else {
			this.addResponsible(existingUser);
		}
	}

	addResponsible(account: TenantAccount): void {
		if (this.applicationResponsibles.find(responsible => responsible.account.accountId === account.account.accountId)) {
			this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'));
		} else {
			const form: ApplicationResponsibleForm = {
				accountId: account.account.accountId
			};
			this.newApplicationService.addApplicationResponsible(this.tenantId, this.application.applicationId, form)
				.pipe(
					filter(success => success),
					tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success')))
				).subscribe(() => {
				this.applicationResponsibles = [...this.applicationResponsibles, account];
				this.applicationResponsiblesControl.setValue(this.applicationResponsibles);
			});
		}
	}

	removeResponsible(user: TenantAccount): void {
		const form: ApplicationResponsibleForm = {
			accountId: user.account.accountId
		};
		this.newApplicationService.deleteApplicationResponsible(this.tenantId, this.application.applicationId, form)
			.pipe(
				filter(success => success),
				tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success')))
			).subscribe(() => {
				this.applicationResponsibles = this.applicationResponsibles.filter(responsible => responsible.account.accountId !== user.account.accountId);
				this.applicationResponsiblesControl.setValue(this.applicationResponsibles);
			});
	}

	getInvertedIndex(index: number): number {
		const maxIndex = this.applicationResponsiblesControl.value.length - 1;
		return maxIndex - index;
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

export interface ApplicationResponsibleData {
	tenantUsers: TenantAccount[];
	selected: TenantAccount[];
}
