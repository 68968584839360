import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter, inject,
	OnDestroy,
	Output,
	ViewChild
} from '@angular/core';
import {DesignSystemModule} from "../design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {animate, style, transition, trigger} from "@angular/animations";
import {CommonModule} from "@angular/common";
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {NewsElement, NewsService} from "../../../services/front/news.service";
import {AccountService} from "../../../services/account.service";

@Component({
  selector: 'app-news-popup',
  standalone: true,
	imports: [
		CommonModule,
		DesignSystemModule,
		TranslateModule
	],
  templateUrl: './news-popup.component.html',
  styleUrl: './news-popup.component.scss',
	animations: [
		trigger(
			'inOutAnimation',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(300%)' }),
						animate('500ms 500ms ease-out',
							style({ transform: 'translateY(0)'}))
					]
				)
			]
		)
	],
})
export class NewsPopupComponent implements AfterViewInit, OnDestroy {
	constructor(private accountService: AccountService) {
	}

	newsService = inject(NewsService);

	news: NewsElement[] = [];

	seenNews: string[] = [];

	unseenNews: NewsElement[] = [];

	@Output() close: EventEmitter<void> = new EventEmitter<void>();
	@ViewChild('newsDialog') newsDialog!: ElementRef<HTMLDialogElement>;

	currentNews: NewsElement;

	subscription: Subscription = new Subscription();

	loading: boolean = false;
	initialized: boolean = false;

	ngAfterViewInit() {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.accountService.getSignupDate()),
				tap((date) => this.setDefaultData(date)),
				filter(() => this.unseenNews.length > 0),
				tap(() => {
					this.currentNews = this.unseenNews[0];
					this.newsDialog.nativeElement.showModal();
					this.seenNews.push(this.currentNews.id);
					localStorage.setItem('seenNews', JSON.stringify(this.seenNews));
					this.listenToBackdropClick();
				}),
				finalize(() => this.switchLoading())
			).subscribe(() => this.initialized = true));
	}

	setDefaultData(date: Date) {
		this.news = this.newsService.news;
		this.seenNews = this.newsService.seenNews;
		this.unseenNews = this.newsService.unseenNews
			.filter(news => !this.seenNews.includes(news.id))
			.filter(news => new Date(news.creationDate) > new Date(date));
		if (this.unseenNews.length === 0) {
			this.close.emit();
		}
	}

	listenToBackdropClick() {
		this.newsDialog.nativeElement.addEventListener('click', (event: any) => {
			const rect = this.newsDialog.nativeElement.getBoundingClientRect();
			const isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
				rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
			if (!isInDialog) {
				this.onClose();
			}
		});
	}

	onNext() {
		if (this.news.indexOf(this.currentNews) === this.news.length - 1) {
			this.onClose();
			return;
		}
		const currentIndex = this.news.indexOf(this.currentNews);
		this.currentNews = this.news[currentIndex + 1];
		this.seenNews.push(this.currentNews.id);
		this.newsService.setSeenNews(this.seenNews);
	}

	getCurrentPage(): number {
		return this.news.indexOf(this.currentNews) + 1;
	}

	onClose() {
		this.seenNews = this.news.map(news => news.id);
		this.newsService.setSeenNews(this.seenNews);
		this.newsDialog.nativeElement.close();
		this.close.emit();
	}

	switchLoading(): Observable<{}> {
		this.loading = !this.loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
