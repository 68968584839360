import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {TenantService} from '../../../../services/tenant.service';
import {mergeMap, tap} from 'rxjs/operators';
import {CurrentTenantService} from '../../../../services/front/current-tenant.service';
import {SnackbarService} from "../../../../services/front/snackbar.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
	styleUrls: ["./users.component.scss"],
	template: `
        <div class="pad-L" style="height: 100%; width: 75vw;">
			<!-- Title -->
			<div class="disp-f j-c-s a-i-c">
				<div>
					<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10"
						 style="height: 45px; width: 45px;">
						<img alt="" src="assets/icons/user-plus.svg" class="svg-24">
					</div>
				</div>
				<div class="ml-s flex-column gap-XS">
					<div class="font-L fw-700">{{ 'page.title.invitePeople' | translate }}</div>
					<div class="font-M fw-300">{{ 'page.subtitle.invitePeople' | translate }}</div>
				</div>

				<!-- Close button -->
				<app-icon-button class="ml-auto" [img]="'assets/icons/close.svg'" type="stroked" background [panelClass]="'img-size-30 darken-icon'" (click)="close()"></app-icon-button>
			</div>

			<!-- Body -->
			<div class="invitation-body mt-s disp-f a-i-c j-c-c">
				<div class="invitation-card pad-XL gap-L disp-f f-d-col">
					<div>
                        <div class="font-MM fw-600">Invitation par email</div>
                        <div class="font-M mt-s">Vos collaborateurs recevront une invitation par email</div>
					</div>
                    <app-text-input (enter)="add()" (blur)="add()" [input]="emailCtrl" placeholder="Adresse email du collaborateur"></app-text-input>
					<div [style.display]="emails.length > 0 ? 'flex' : 'none'" class="flex-wrap gap-S">
                        @for (email of emails; track email) {
							<app-chips-text color="grey">
								<div class="disp-f a-i-c gap-XS">
                                    <span>{{email}}</span>
                                    <span (click)="remove(email)"><img src="assets/icons/close.svg" width="9" height="9" alt="" class="filter-grey c-p"></span>
								</div>
							</app-chips-text>
                        }
					</div>

					<app-text-button [loading]='isLoading' (click)="inviteUsers()" color="accent" type="flat">Inviter des collaborateurs</app-text-button>
				</div>
			</div>
		</div>
		<app-snackbar></app-snackbar>
	`
})
export class InviteUsersComponent {

	constructor(
		protected dialogRef: MatDialogRef<InviteUsersComponent>,
		protected currentTenantService: CurrentTenantService,
		protected tenantService: TenantService,
		protected snackbarService: SnackbarService
	) {}

	@Output() onClose = new EventEmitter<any>();

	emailCtrl: FormControl = new FormControl('', [Validators.email])
	emails: string[] = [];

	add(): void {
		if (this.emailCtrl.value.trim().length > 0 && this.emailCtrl.valid) {
			if (this.emails.indexOf(this.emailCtrl.value) === -1) {
				this.emails.push(this.emailCtrl.value);
			}

			this.emailCtrl.setValue(null);
		}
	}

	remove(email: string): void {
		const index = this.emails.indexOf(email);
		if (index >= 0) {
			this.emails.splice(index, 1);
		}
	}

	isLoading = false;

	inviteUsers() {
		this.isLoading = true;

		if (this.emailCtrl.valid && (this.emailCtrl.value?.trim().length ?? 0) > 0) {
			this.emails.push(this.emailCtrl.value)
			this.emailCtrl.reset();
		}

		if (this.emails.length > 0) {
			this.currentTenantService.getCurrentTenantId().pipe(
				mergeMap(tenantId => this.tenantService.inviteUsers(tenantId, this.emails.map(email => ({ email: email, grantAccess: true })))),
				tap(() => this.isLoading = false),
				tap(() => this.snackbarService.show('Collaborateurs invités avec succès!', 'success-snack')),
				tap(() => this.emails = [])
			).subscribe({
				next: () => setTimeout(() => this.close(), 3000),
				error: error => {
					console.error("Failed to invite users.", error);
					this.isLoading = false;
					this.snackbarService.show('Une erreur est survenue lors de l\'invitation des collaborateurs', 'danger-snack')
				}
			})
		} else {
			this.isLoading = false;
		}
	}

	close() {
		this.dialogRef.close();
	}
}
