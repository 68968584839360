import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {
	InfrastructureOverview,
	MetricAverage, Server,
	ServerAgent,
	ServerForm, ServerInformationForm,
	ServerTableData
} from 'src/app/services/model/resource.model';
import {ApplicationHealth, ServerApplication} from 'src/app/services/model/new-application.model';
import {CriticalityLevel} from "../tenant.service";
import {Network} from "./network.service";
import {Router} from "./router.service";

@Injectable()
export class ServerService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/resources`;

	private infraUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/infrastructures`;

	constructor(protected httpClient: HttpClient) {
	}

	deleteServer(tenantId: string, resourceId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${resourceId}`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getTenantServersTableData(tenantId: string): Observable<ServerTableData[]> {
		return this.httpClient.get<ServerTableData[]>(`${this.infraUrl(tenantId)}/servers/table-data`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getTenantServers(tenantId: string): Observable<Server[]> {
		return this.httpClient.get<Server[]>(`${this.infraUrl(tenantId)}/servers`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getServer(tenantId: string, serverId: string): Observable<Server> {
		return this.httpClient.get<Server>(`${this.infraUrl(tenantId)}/servers/${serverId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getServerMetricAverage(tenantId: string, serverId: string): Observable<MetricAverage> {
		return this.httpClient.get<MetricAverage>(`${this.infraUrl(tenantId)}/servers/${serverId}/metric-average`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateServerInformation(tenantId: string, serverId: string, form: ServerInformationForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.infraUrl(tenantId)}/servers/${serverId}/information`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getServerCriticality(tenantId: string, serverId: string): Observable<CriticalityLevel | null> {
		return this.httpClient.get<CriticalityLevel | null>(`${this.infraUrl(tenantId)}/servers/${serverId}/criticality`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getServerApplications(tenantId: string, serverId: string): Observable<ServerApplication[]> {
		return this.httpClient.get<ServerApplication[]>(`${this.infraUrl(tenantId)}/servers/${serverId}/applications`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getServerMetrics(tenantId: string, infrastructureId: string, os: string): Observable<InfrastructureOverview> {
		return this.httpClient.get<any>(`${this.infraUrl(tenantId)}/${infrastructureId}/${os}/metrics`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantMonthlyAverageMetrics(tenantId: string): Observable<MetricAverage> {
		return this.httpClient.get<MetricAverage>(`${this.infraUrl(tenantId)}/monthly-average`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getServerAgent(tenantId: string, resourceId: string): Observable<ServerAgent> {
		return this.httpClient.get<ServerAgent>(`${this.serviceUrl(tenantId)}/${resourceId}/agent`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	createManualServer(tenantId: string, body: ServerForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.serviceUrl(tenantId)}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getTenantServerCount(tenantId: string): Observable<number> {
		return this.httpClient.get<number>(`${this.infraUrl(tenantId)}/servers/count`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, 0)));
	}

	getServerNetwork(tenantId: string, serverId: string): Observable<Network | null> {
		return this.httpClient.get<Network | null>(`${this.infraUrl(tenantId)}/servers/${serverId}/network`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateServerNetwork(tenantId: string, serverId: string, networkId: string): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.infraUrl(tenantId)}/servers/${serverId}/network`, {networkId}, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getServerSchema(tenantId: string, serverId: string): Observable<InfrastructureSchema|null> {
		return this.httpClient.get<InfrastructureSchema|null>(`${this.infraUrl(tenantId)}/servers/${serverId}/schema`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}
}

export interface InfrastructureSchema {
	servers: SchemaServer[],
	network: Network
	routers: Router[]
}

export interface SchemaServer {
	server: Server,
	current: boolean,
	criticality: CriticalityLevel | null,
}
