import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
	ApplicationActivityLog, ApplicationLogFieldType,
	LogActionType, ServerActivityLog, ServerLogFieldType,
	sortApplicationActivityLogs, sortServerActivityLogs
} from "../../../../../../../services/model/activity-log.model";
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {
	ApplicationDetailData,
	ApplicationDetailService
} from "../../../../../../../services/front/application-detail.service";
import {ActivityLogService} from "../../../../../../../services/back/activity-log.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DateTranslateRef, DateUtils} from "../../../../../../../utils/date.utils";
import {ServerDetailData, ServerDetailService} from "../../../../../../../services/front/server-detail.service";
import {DesignSystemModule} from "../../../../../../design-system/design-system.module";
import {DeckContentLoaderModule} from "../../../../../../global/title-content-loader/deck-content-loader.module";

@Component({
	selector: 'app-server-log-preview',
	standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule, DeckContentLoaderModule],
	templateUrl: './server-log-preview.component.html',
	styleUrl: './server-log-preview.component.scss'
})
export class ServerLogPreviewComponent implements OnInit, OnDestroy {
	tenantId: string;
	serverId: string;

	_initializing: boolean;
	_loading: boolean;
	initialized: boolean = false;

	logs: ServerActivityLog[] = [];
	logOffset: number = 0;
	readonly LIMIT: number = 3;

	subscription: Subscription = new Subscription();

	constructor(private serverDetailService: ServerDetailService,
				private activityLogService: ActivityLogService,
				private translate: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.serverDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.serverDetailService.getServerDetailDataChanges()
			.pipe(tap(data => this.setServerDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	setServerDetailData(data: ServerDetailData): void {
		this.tenantId = data.tenantId;
		this.serverId = data.server.id;
	}

	initialize(): void {
		this.fetchServerLogs(this.logOffset);
	}

	fetchServerLogs(offset: number): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.activityLogService.getAllServerActivityLog(this.tenantId, this.serverId, this.LIMIT, offset)),
				tap(logs => this.logs = logs.sort(sortServerActivityLogs)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	getDateGap(date: string): string {
		let translateRef: DateTranslateRef | null = DateUtils.getDateTranslateRef(date)
		return !!translateRef
			? this.translate.instant('page.home.homepage.history.date.' + translateRef.translateRef, {value: translateRef.value})
			: '-'
	}

	displayMore(): void {
		// TODO
		this.logOffset += this.LIMIT;
		this.fetchServerLogs(this.logOffset);
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	getActionLabel(log: ServerActivityLog) {
		switch (log.actionType) {
			case LogActionType.CREATE:
				return this.translate.instant('page.home.homepage.history.createOf');
			case LogActionType.UPDATE:
				return this.translate.instant('page.home.homepage.history.updateOf');
			case LogActionType.DELETE:
				return this.translate.instant('page.home.homepage.history.deleteOf');
			default:
				this.translate.instant('page.home.homepage.history.updateOf');
		}
	}

	getFieldLabel(log: ServerActivityLog) {
		switch (log.fieldType) {
			// TODO : add more ?
			default:
				return log.server.name;
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
