<div class="pad-L" style="height: 100%; width: 435px; overflow: hidden;">
	<div class="disp-f j-c-s a-i-c">
		<div>
			<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10"
				 style="height: 45px; width: 45px;">
				<img alt="" src="../../../../../../assets/icons/network.svg" class="svg-24 filter-grey">
			</div>
		</div>
		<div class="ml-s flex-column gap-XS">
			<div class="font-L fw-700">{{ 'page.infrastructure.network.addANetwork' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.infrastructure.network.addANetworkSubtitle' | translate }}</div>
		</div>
		<app-icon-button (click)="closeDialog(false)" class="ml-auto" [img]="'assets/icons/close.svg'" type="stroked"
						 background [panelClass]="'img-size-30 darken-icon'"></app-icon-button>
	</div>

	<form [formGroup]="formGroup">
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.infrastructure.network.inputNetworkName' | translate }}*</div>
			<app-text-input [input]="nameFormControl"
							[placeholder]="'page.infrastructure.network.inputNetworkName' | translate"></app-text-input>
		</div>
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.infrastructure.network.role' | translate }}*</div>
			<app-multi-select class="w-100" [inputControl]="roleFormControl"
							  [disabled]="_saving">
				<app-select-trigger appearance="field">
					<span
						*ngIf="roleFormControl.value">{{ 'page.infrastructure.network.roles.' + roleFormControl.value | translate }}</span>
					<span class="grey" *ngIf="!roleFormControl.value">{{ 'page.infrastructure.network.selectARole' | translate }}</span>
				</app-select-trigger>
				<app-select-option *ngFor="let role of networkRoleList" [value]="role">
					{{ 'page.infrastructure.network.roles.' + role | translate }}
				</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex gap-XS mt-l">
			<div class="flex-column gap-XS" style="flex: 7">
				<div
					class="font-M fw-500 grey">{{ ('page.infrastructure.overview.ipAddress' | translate).slice(0, -1) }}
				</div>
				<app-text-input [error]="formGroup.hasError('invalidNetworkAddress') && formGroup.dirty"
								[input]="ipAddressFormControl"
								[placeholder]="'page.infrastructure.overview.ipAddressPlaceholder' | translate"></app-text-input>
			</div>
			<div class="flex-column gap-XS" style="flex: 3">
				<div class="font-M fw-500 grey">{{ 'page.infrastructure.network.subnet' | translate }}*</div>
				<app-multi-select class="w-100" [inputControl]="subnetMaskFormControl"
								  [disabled]="_saving">
					<app-select-trigger [error]="formGroup.hasError('invalidNetworkAddress') && formGroup.dirty"
										appearance="field">
						<span [class.danger]="formGroup.hasError('invalidNetworkAddress') && formGroup.dirty"
							  *ngIf="subnetMaskFormControl.value">{{ subnetMaskFormControl.value }}</span>
						<span class="grey" *ngIf="!subnetMaskFormControl.value">/32</span>
					</app-select-trigger>
					<app-select-option *ngFor="let mask of subnetMaskList" [value]="mask">
						{{ mask }} ({{ cidrToMask(mask) }})
					</app-select-option>
				</app-multi-select>
			</div>
		</div>
		<span @validators-appear *ngIf="ipAddressFormControl.errors?.ipAddress && formGroup.dirty"
			  class="danger">{{ 'page.infrastructure.overview.ipAddressValidator' | translate }}</span>
		<span @validators-appear *ngIf="formGroup.hasError('invalidNetworkAddress') && formGroup.dirty"
			  class="danger">{{ 'page.infrastructure.network.ipError' | translate }}</span>
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.infrastructure.network.description' | translate }}</div>
			<app-text-area [placeholder]="'page.infrastructure.network.inputDescription' | translate" [rows]="5"
						   [input]="descriptionFormControl"></app-text-area>
		</div>
		<div class="flex gap-M mt-l">
			<app-text-button type="stroked" class="w-50" [disabled]="_saving"
							 (click)="onGoBack.emit()">
				{{ 'page.appDetails.finance.contracts.cancel'|translate }}
			</app-text-button>
			<app-text-button type="flat" class="w-50" [disabled]="formGroup.invalid" [loading]="_saving"
							 (click)="submit()">
				{{ 'page.appDetails.files.dialog.add' | translate }}
			</app-text-button>
		</div>
	</form>
</div>
