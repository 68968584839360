import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, Provider} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {GlobalHttpInterceptorService} from './services/global-http-interceptor.service';
import {AccountService} from 'src/app/services/account.service';
import {AuthenticationService} from './services/authentication.service';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {AuthGuard} from './guards/auth.guard';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatToolbarModule} from '@angular/material/toolbar';
import {SidenavService} from 'src/app/services/front/sidenav.service';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyOptionModule as MatOptionModule} from '@angular/material/legacy-core';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ContentLoaderModule} from '@ngneat/content-loader';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {ApikeyService} from './services/apikey.service';
import {ServerService} from 'src/app/services/back/server.service';
import {MsalModule} from '@azure/msal-angular';
import {PublicClientApplication} from '@azure/msal-browser';
import {environment} from "../environments/environment";
import {ApplicationInstanceService} from 'src/app/services/application-instance.service';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations"
import {TenantFileService} from 'src/app/services/back/tenant-file.service';
import {ResourceMappingService} from "./services/back/resource-mapping.service";
import {DatePipe, registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {UserPreferenceService} from 'src/app/services/front/user-preference.service';
import {ApplicationUrlService} from "src/app/services/front/application-url.service";
import {InfrastructureUrlService} from "src/app/services/front/infrastructure-url.service";
import {ApplicationAutodiscoverService} from "src/app/services/back/application-autodiscover.service";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {ImageService} from "src/app/services/front/image.service";
import {RoverService} from "./services/rover.service";
import {UsageService} from "./services/usage.service";
import {MatNativeDateModule} from "@angular/material/core";
import {MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER} from "@angular/material/datepicker";
import {HomePageDrawerComponent} from './modules/home/home-page/home-page-drawer/home-page-drawer.component';
import {
	HomePageLogHistoryComponent
} from './modules/home/home-page/home-page-log-history/home-page-log-history.component';
import {ApplicationIconModule} from "./modules/global/application-icon/application-icon.module";
import {HomeModule} from "./pages/home/home.module";
import {NotificationService} from "./services/notification.service";
import {ApplicationDetailService} from "./services/front/application-detail.service";
import {CurrentTenantService} from "./services/front/current-tenant.service";
import {AnnouncementService} from "./services/announcement.service";
import {CatalogTechnologyService} from './services/back/catalog-technology.service';
import {ApplicationTechnologyService} from './services/back/application-technology.service';
import {ActivityLogService} from 'src/app/services/back/activity-log.service';
import {ApplicationMappingService} from 'src/app/services/back/application-mapping.service';
import {CatalogApplicationService} from 'src/app/services/back/catalog-application.service';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {ApplicationCategoryService} from 'src/app/services/back/application-category.service';
import {CatalogTagService} from 'src/app/services/back/catalog-tag.service';
import {ApplicationCommentService} from 'src/app/services/back/application-comment.service';
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import {TenantFinanceService} from 'src/app/services/back/tenant-finance.service';
import {ApplicationDocumentService} from 'src/app/services/back/application-document.service';
import {ApplicationContractService} from 'src/app/services/back/application-contract.service';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';
import {HealthService} from "./services/back/health.service";
import {PerformanceService} from "./services/back/performance.service";
import {ApplicationLifeCycleService} from 'src/app/services/back/application-life-cycle.service';
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {CurrencyService} from 'src/app/services/back/currency.service';
import {ExportService} from 'src/app/services/front/export.service';
import {PdfService} from 'src/app/services/front/pdf.service';
import {OrganizationUrlService} from "./services/front/organization-url.service";
import {OrganizationDetailService} from "./services/front/organization-detail.service";
import {VendorService} from "./services/back/vendor.service";
import {ServerDetailService} from "./services/front/server-detail.service";
import {ServerUrlService} from "./services/front/server-url.service";
import {NetworkDetailService} from "./services/front/network-detail.service";
import {NetworkUrlService} from "./services/front/network-url.service";
import {IpCheckerService} from "./services/front/ip-checker.service";
import {RouterDetailService} from "./services/front/router-detail.service";
import {RouterUrlService} from "./services/front/router-url.service";
import {InfrastructureStateService} from "./services/front/infrastructure-state.service";
import {NewsService} from "./services/front/news.service";

registerLocaleData(localeFr);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const sentryProviders = (environment.sentryConfiguration.enabled) ? [
	{
		provide: ErrorHandler,
		useValue: Sentry.createErrorHandler({
			showDialog: false,
		}),
	}, {
		provide: Sentry.TraceService,
		deps: [Router],
	},
	{
		provide: APP_INITIALIZER,
		useFactory: () => () => {
		},
		useValue: () => new Promise(resolve =>
			setTimeout(resolve, 2300)
		),
		multi: true,
		deps: [Sentry.TraceService],
	}
] : [
	{
		provide: APP_INITIALIZER,
		useValue: () => new Promise(resolve =>
			setTimeout(resolve, 2300)
		),
		multi: true,
	}
];

@NgModule({
	declarations: [
		AppComponent,
		HomePageDrawerComponent,
		HomePageLogHistoryComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		ContentLoaderModule,
		MatFormFieldModule,
		MatButtonModule,
		MatCheckboxModule,
		MatIconModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		HttpClientModule,
		MatProgressSpinnerModule,
		MatListModule,
		MatToolbarModule,
		MatMenuModule,
		MatBadgeModule,
		MatAutocompleteModule,
		MatOptionModule,
		MatChipsModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatSelectModule,
		MatSnackBarModule,
		MatNativeDateModule,
		MatDialogModule,
		MsalModule.forRoot(new PublicClientApplication({
			auth: {
				clientId: environment.clientId365,
				authority: environment.authority365,
				redirectUri: environment.redirectUri365// This is your redirect URI
			},
			cache: {
				cacheLocation: 'localStorage',
				storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
			}
			// @ts-ignore
		}), null, null),
		ApplicationIconModule,
		HomeModule,
		DesignSystemModule,
	],
	providers: [
		MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: GlobalHttpInterceptorService,
			multi: true
		},
		{provide: LOCALE_ID, useValue: 'fr-FR'},
		{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {}},
		{provide: MAT_DIALOG_DATA, useValue: {}},
		AccountService,
		ApikeyService,
		AuthenticationService,
		AuthGuard,
		SidenavService,
		TopbarService,
		RightSliderService,
		ApplicationInstanceService,
		TenantFileService,
		DatePipe,
		UserPreferenceService,
		ApplicationUrlService,
		ServerUrlService,
		NetworkUrlService,
		RouterUrlService,
		OrganizationUrlService,
		InfrastructureUrlService,
		InfrastructureStateService,
		ApplicationAutodiscoverService,
		SnackbarService,
		ImageService,
		IpCheckerService,
		RoverService,
		UsageService,
		NotificationService,
		ApplicationDetailService,
		ServerDetailService,
		NetworkDetailService,
		RouterDetailService,
		OrganizationDetailService,
		CurrentTenantService,
		ExportService,
		PdfService,
		AnnouncementService,
		ActivityLogService,
		ApplicationMappingService,
		ApplicationTechnologyService,
		CatalogApplicationService,
		CatalogTechnologyService,
		ServerService,
		ResourceMappingService,
		NewApplicationService,
		ApplicationCategoryService,
		VendorService,
		CatalogTagService,
		ApplicationCommentService,
		TenantFinanceService,
		ApplicationDocumentService,
		ApplicationContractService,
		TenantAccountService,
		HealthService,
		PerformanceService,
		ApplicationLifeCycleService,
		CurrencyService,
		NewsService
	].concat(sentryProviders as any[]),
	bootstrap: [AppComponent]
})
export class AppModule {
}
