<div class="flex-column gap-L" *ngIf="initialized">
	<div class="flex-align-center gap-S">
		<div class="font-MM fw-700">{{ 'page.home.homepage.history.lastActions'|translate }}</div>
	</div>
	<div class="flex-column">
		@for (log of logs; track log) {
			<div>
				<div class="flex-space-between flex-align-center">
					<div class="flex-align-center gap-XS">
						<app-display-user [user]="log.user" bold></app-display-user>
						<div class="font-M fw-500">{{ getActionLabel(log) }}</div>
						<div class="font-M fw-700">{{ getFieldLabel(log) }}</div>
					</div>
					<div>{{ getDateGap(log.creationDate) }}</div>
				</div>
				<div class="flex" *ngIf="$index < logs.length - 1">
					<div class="border-grey-left" style="height: 20px; margin: 3px 14.5px;"></div>
				</div>
			</div>
		} @empty {
			<div class="w-100 flex-center flex-column" style="gap: 30px; height: 250px;">
				<img src="../../../../../../../../assets/illustrations/instances-empty.svg" width="150">
				<span class="grey">{{ 'page.infrastructure.overview.noContribution' | translate }}</span>
			</div>
		}
	</div>
</div>
<app-dashboard-page-loader *ngIf="!initialized"></app-dashboard-page-loader>
