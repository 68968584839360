<app-snackbar></app-snackbar>
<form [formGroup]="licenceForm">
    <div class="flex-column gap-M">
        <div class="flex-column gap-XS">
            <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.subscribers'|translate}}</span>
            <app-multi-select class="w-100" [inputControl]="$any(licenceForm.get(form.organizations))" multiple [addMinHeight]="true">
                <app-select-trigger appearance="field">{{!licenceForm.get(form.organizations)!.value.length ? '-' : (licenceForm.get(form.organizations)!.value[0].name  + ($any(licenceForm.get(form.organizations)).value.length > 1 ? ' (+' + ($any(licenceForm.get(form.organizations)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                <app-select-search [searchControl]="searchOrganizationControl"></app-select-search>
                <app-select-option-group *ngFor="let group of filteredOrganizations" [label]="group.organization.name" noOptionPrefix>
                    <app-select-option *ngFor="let org of group.children" [value]="org.organization">
                        <div class="flex-align-center gap-M">
                            <img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
                            <span>{{org.organization.name}}</span>
                        </div>
                    </app-select-option>
                </app-select-option-group>
            </app-multi-select>
        </div>
        <div class="flex-column gap-XS">
            <div class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.amount'|translate}}</div>
            <app-text-input [input]="$any(licenceForm.get(form.amount))" type="number"
                            [placeholder]="'page.appDetails.finance.contracts.amountPlaceholder'|translate">
                <div icon-suffix class="pad-S font-L fw-500">{{currencySymbol}}</div>
            </app-text-input>
        </div>
        <div class="flex-align-end gap-L">
            <div class="flex-column gap-XS w-70">
                <div class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.amortizationPeriod'|translate}}</div>
                <app-text-input [input]="$any(licenceForm.get(form.amortizationValue))" type="number"
                                [placeholder]="'page.appDetails.finance.contracts.amortizationPlaceholder'|translate"></app-text-input>
            </div>
            <div class="flex-column gap-XS w-30">
                <app-multi-select [inputControl]="$any(licenceForm.get(form.amortizationUnit))">
                    <app-select-trigger appearance="field">{{$any(licenceForm.get(form.amortizationUnit)).value.name}}</app-select-trigger>
                    <app-select-option *ngFor="let type of amortizationUnitChoices" [value]="type">{{type.name}}</app-select-option>
                </app-multi-select>
            </div>
        </div>
        <div class="flex gap-L">
            <div class="flex-column gap-XS w-50">
                <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.subscriptionDate'|translate}}</span>
                <div class="flex-align-center custom-calendar-input" (click)="picker.open()">
                    <input [formControl]="$any(licenceForm.get(form.subscriptionDate))" [matDatepicker]="picker" [placeholder]="'DD/MM/YYYY'">
                    <img class="c-p" src="assets/icons/calendar2.svg" alt="" width="18" height="18">
                    <mat-datepicker #picker></mat-datepicker>
                </div>
            </div>
            <div class="flex-column gap-XS w-50">
                <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.durationOfUse'|translate}}</span>
                <app-multi-select [inputControl]="$any(licenceForm.get(form.durationOfUse))">
                    <app-select-trigger appearance="field">{{$any(licenceForm.get(form.durationOfUse)).value.name}}</app-select-trigger>
                    <app-select-option *ngFor="let type of durationOfUseChoices" [value]="type">{{type.name}}</app-select-option>
                </app-multi-select>
            </div>
        </div>
    </div>
</form>
