<div class="bg-rounded pad-M">
	<span class="font-M fw-700">Général</span>

	<form [formGroup]="formGroup">
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ ('page.infrastructure.overview.machineName' | translate).slice(0, -1) }}*</div>
			<app-text-input (keyup.enter)="updateOnBlur.emit(Form.name)" (blur)="updateOnBlur.emit(Form.name)" [disabled]="server.automatic" [input]="nameFormControl" [placeholder]="'page.infrastructure.overview.namePlaceholder' | translate"></app-text-input>
		</div>
	</form>
</div>
