<form [formGroup]="serviceForm">
    <div class="flex-column gap-M">
        <div class="flex-column gap-XS">
            <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.type'|translate}}</span>
            <app-multi-select [inputControl]="$any(serviceForm.get(form.serviceType))">
                <app-select-trigger appearance="field">{{$any(serviceForm.get(form.serviceType)).value.name}}</app-select-trigger>
                <app-select-option *ngFor="let type of serviceTypeChoices" [value]="type">{{type.name}}</app-select-option>
            </app-multi-select>
        </div>
        <div class="flex-column gap-XS">
            <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.subscribers'|translate}}</span>
            <app-multi-select class="w-100" [inputControl]="$any(serviceForm.get(form.organizations))" multiple [addMinHeight]="true">
                <app-select-trigger appearance="field">{{!serviceForm.get(form.organizations)!.value.length ? '-' : (serviceForm.get(form.organizations)!.value[0].name  + ($any(serviceForm.get(form.organizations)).value.length > 1 ? ' (+' + ($any(serviceForm.get(form.organizations)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                <app-select-search [searchControl]="searchOrganizationControl"></app-select-search>
                <app-select-option-group *ngFor="let group of filteredOrganizations" [label]="group.organization.name" noOptionPrefix>
                    <app-select-option *ngFor="let org of group.children" [value]="org.organization">
                        <div class="flex-align-center gap-M">
                            <img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
                            <span>{{org.organization.name}}</span>
                        </div>
                    </app-select-option>
                </app-select-option-group>
            </app-multi-select>
        </div>
        <div class="flex gap-L">
            <div class="flex-column gap-XS w-70">
                <div class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.amount'|translate}}</div>
                <app-text-input [input]="$any(serviceForm.get(form.amount))" type="number"
                                [placeholder]="'page.appDetails.finance.contracts.amountPlaceholder'|translate">
                    <div icon-suffix class="pad-S font-L fw-500">{{currencySymbol}}</div>
                </app-text-input>
            </div>
            <div class="flex-column gap-XS w-30">
                <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.recurrence'|translate}}</span>
                <app-multi-select [inputControl]="$any(serviceForm.get(form.recurrencePeriod))">
                    <app-select-trigger appearance="field">{{$any(serviceForm.get(form.recurrencePeriod)).value.name}}</app-select-trigger>
                    <app-select-option *ngFor="let type of recurrenceChoices" [value]="type">{{type.name}}</app-select-option>
                </app-multi-select>
            </div>
        </div>
        <div class="flex-column gap-XS">
            <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.beginningDate'|translate}}</span>
            <div class="flex-align-center custom-calendar-input" (click)="picker.open()">
                <input [formControl]="$any(serviceForm.get(form.beginningDate))" [matDatepicker]="picker" [placeholder]="'DD/MM/YYYY'">
                <img class="c-p" src="assets/icons/calendar2.svg" alt="" width="18" height="18">
                <mat-datepicker #picker></mat-datepicker>
            </div>
        </div>
        @if (!$any(serviceForm.get(form.recurrencePeriod)).value.id) {
            <div class="flex-align-end gap-L">
                <div class="flex-column gap-XS w-70">
                    <div class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.amortizationPeriod'|translate}}</div>
                    <app-text-input [input]="$any(serviceForm.get(form.amortizationValue))" type="number"
                                    [placeholder]="'page.appDetails.finance.contracts.amortizationPlaceholder'|translate"></app-text-input>
                </div>
                <div class="flex-column gap-XS w-30">
                    <app-multi-select [inputControl]="$any(serviceForm.get(form.amortizationPeriod))">
                        <app-select-trigger appearance="field">{{$any(serviceForm.get(form.amortizationPeriod)).value.name}}</app-select-trigger>
                        <app-select-option *ngFor="let type of amortizationPeriodChoices" [value]="type">{{type.name}}</app-select-option>
                    </app-multi-select>
                </div>
            </div>
        }
    </div>
</form>
