<div class="h-100">
	<div  class="pl-xl pr-xl pt-l pb-l slider-header">
		<div class="disp-f j-c-s a-i-c w-100">
			<div>
				<div class="disp-f a-i-c j-c-c col bkg-accent-light radius-10" style="height: 45px; width: 45px">
					<img src="assets/icons/clock-rewind.svg" class="svg-24 filter-accent">
				</div>
			</div>
			<div class="ml-s flex-column gap-XS">
				<div class="font-L fw-700">{{'page.home.homepage.history.lastActions' | translate}}</div>
				<div class="font-M fw-300">{{'page.home.homepage.history.history' | translate}}</div>
			</div>
		</div>

		<div class="flex-align-center gap-S">
			<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
							 (click)="close()">
			</app-icon-button>
		</div>
	</div>

	<div class="pt-xl pr-xl pl-xl pb-xl disp-f f-d-col j-c-sb drawer-content">
		<div class="bg-rounded pt-l pb-l pr-l pl-l" >
			<div *ngIf="loading">
				<app-home-page-history-loader></app-home-page-history-loader>
				<div class="mt-l"></div>
				<app-home-page-history-loader ></app-home-page-history-loader>
				<div class="mt-l"></div>
				<app-home-page-history-loader></app-home-page-history-loader>
				<div class="mt-l"></div>
				<app-home-page-history-loader></app-home-page-history-loader>
			</div>
			<div *ngIf="!loading">
				<div *ngFor="let action of logs">
					<div class="flex-align-center flex-space-between log-row mb-l">
						<app-display-logo [logo]="action.application.logo" [size]="38"></app-display-logo>
						<div class="flex-wrap w-100 log-text">
							<span class="font-M fw-700 c-p" (click)="goToApp(action.application.id)">{{action.application.name}}</span>
							<span class="font-M fw-500">{{(action.actionType === 'create' ? 'page.home.homepage.history.createdBy' : 'page.home.homepage.history.updatedBy') | translate}}</span>
							<span class="font-M fw-700">{{(!action.user ? 'Kabeen' : action.user!.account.firstName + ' ' + action.user!.account.lastName)}}</span>
						</div>
						<div class="log-date flex-justify-end">{{getDateGap(action.creationDate)}}</div>
					</div>
				</div>
				<div *ngIf="logs.length == 0" class="font-S fw-500 grey mt-l w-100 text-center"> {{'page.home.homepage.history.noHistory' | translate}} </div>
				<div *ngIf="!noMore && logs.length > 0" class="flex justify-center">
					<div class="button-style" (click)="loadLogs()" >
						<span class="font-M fw-500">{{ 'page.home.homepage.history.loadMore' | translate }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
