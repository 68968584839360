<div class="pad-L" style="height: 100%; width: 435px; overflow: hidden;">
	<div class="disp-f j-c-s a-i-c">
		<div>
			<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10"
				 style="height: 45px; width: 45px;">
				<img alt="" src="../../../../../../../assets/icons/add-server.svg" class="svg-24 filter-grey">
			</div>
		</div>
		<div class="ml-s flex-column gap-XS">
			<div class="font-L fw-700">{{ 'page.infrastructure.add.title' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.infrastructure.add.subtitle' | translate }}</div>
		</div>
		<app-icon-button (click)="closeDialog()" class="ml-auto" [img]="'assets/icons/close.svg'" type="stroked"
						 background [panelClass]="'img-size-30 darken-icon'"></app-icon-button>
	</div>

	<form [formGroup]="formGroup">
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ ('page.infrastructure.overview.machineName' | translate).slice(0, -1) }}
				*
			</div>
			<app-text-input [input]="nameFormControl"
							[placeholder]="'page.infrastructure.overview.namePlaceholder' | translate"></app-text-input>
		</div>
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ ('page.infrastructure.overview.system' | translate).slice(0, -1) }}</div>
			<app-multi-select class="w-100" [inputControl]="osFormControl"
							  [disabled]="_initializing || _saving">
				<app-select-trigger appearance="field">
					{{ osFormValue }}
				</app-select-trigger>
				<app-select-option *ngFor="let os of osList" [value]="os.id">
					{{ os.name }}
				</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ ('page.infrastructure.overview.ipAddress' | translate).slice(0, -1) }}
			</div>
			<app-text-input [input]="ipAddressFormControl"
							[placeholder]="'page.infrastructure.overview.ipAddressPlaceholder' | translate"></app-text-input>
			<span @validators-appear *ngIf="ipAddressFormControl.errors" class="danger text-right">
				{{ 'page.infrastructure.overview.ipAddressValidator' | translate }}
			</span>
		</div>
		<div class="mt-l warning-message flex-column flex-center gap-XS pad-M">
			<img width="25" src="../../../../../../../assets/icons/info-black.svg" class="filter-warning">
			<span class="warning text-center">
				{{ 'page.infrastructure.overview.warningMsg' | translate }}
			</span>
		</div>
		<div class="flex gap-M mt-l">
			<app-text-button type="stroked" class="w-50" [disabled]="_saving"
							 (click)="onGoBack.emit()">
				{{ 'page.appDetails.finance.contracts.cancel'|translate }}
			</app-text-button>
			<app-text-button type="flat" class="w-50" [disabled]="formGroup.invalid" [loading]="_saving"
							 (click)="save()">
				{{ 'page.appDetails.files.dialog.add' | translate }}
			</app-text-button>
		</div>
	</form>
</div>
