<div class="flex-column gap-S" style="padding-bottom: 5px" [class.opened]="openForm">
    <div class="flex-align-center gap-S pad-S c-p insert-button prevent-select"
         (click)="setFormOpened()">
		<img src="assets/icons/add.svg" width="11" class="filter-accent" alt="">
        <span class="accent font-M">{{titlePath|translate}}</span>
    </div>
    <div *ngIf="openForm" class="flex-column gap-S">
		<div class="flex-align-center p-relative">
			<div class="input-with-delete flex-align-center w-100 pad-S">
				<input #search [placeholder]="placeholder|translate" (keydown.enter)="onCreate()" class="input-no-style h-100" type="text" [formControl]="createForm">
				<div class="flex-align-center flex-justify-end p-absolute" style="right: 9px">
					<button class="add-insert-btn flex-center"
							[class.c-p]="formValid"
							[class.c-na]="!formValid"
							[disabled]="!formValid"
							(click)="onCreate()">
						<img src="assets/icons/add.svg"
							 class="filter-white">
					</button>
				</div>
			</div>
		</div>
		<div *ngIf="withToggle" class="flex-align-center flex-space-between">
			<span class="font-M" style="max-width: 70%">{{ toggleText|translate }}</span>
			<app-toggle-button [scale]="0.9" [toggleInput]="toggleFormControl"></app-toggle-button>
		</div>
    </div>
	<div class="hr-document"></div>
</div>
