import {Injectable} from "@angular/core";
import {TenantOverview} from "../tenant.service";
import {BehaviorSubject, distinctUntilChanged, finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {CurrentTenantService} from './current-tenant.service';
import {Router, RouterService} from "../back/router.service";

@Injectable()
export class RouterDetailService {

	private tenantId: string;
	private routerId: string;

	private dataBS = new BehaviorSubject<RouterDetailData | undefined>(undefined);
	private initializingBS = new BehaviorSubject<boolean>(false);
	private refreshingBS = new BehaviorSubject<boolean>(false);
	private refreshingStack: {}[] = [];
	private subscription: Subscription;

	constructor(private routerService: RouterService,
				private currentTenantService: CurrentTenantService) {
	}

	initialize(routerId: string): void {
		this.routerId = routerId;
		this.subscription = new Subscription();
		this.subscription.add(this.switchInitializing()
			.pipe(
				switchMap(() => this.currentTenantService.getCurrentTenant()),
				tap(tenant => this.tenantId = tenant.configuration.id),
				switchMap(tenant => this.routerService.getRouter(this.tenantId, routerId)
					.pipe(tap(router => this.setRouterDetailData(tenant, router)))),
				finalize(() => this.switchInitializing()))
			.subscribe());
	}

	private setRouterDetailData(tenant: TenantOverview, router: Router): void {
		const data: RouterDetailData = {
			tenantId: tenant.configuration.id,
			isEditor: tenant.configuration.role !== 'read_only',
			router: router,
			isInactivePlan: tenant.plan.isInactivePlan()
		};
		this.dataBS.next(data);
	}

	getRouterDetailDataChanges(): Observable<RouterDetailData> {
		return this.dataBS.asObservable().pipe(
			filter(data => data !== undefined),
			switchMap(data => of(data!)));
	}

	refreshRouter(): void {
		this.subscription.add(this.switchRefreshing(true)
			.pipe(
				switchMap(() => this.routerService.getRouter(this.tenantId, this.routerId)),
				tap(router => this.dataBS.value!.router = router),
				tap(() => this.dataBS.next(this.dataBS.value)),
				finalize(() => this.switchRefreshing(false)))
			.subscribe());
	}

	getInitializingChanges(): Observable<boolean> {
		return this.initializingBS.asObservable();
	}

	getRefreshingChanges(): Observable<boolean> {
		return this.refreshingBS.asObservable()
			.pipe(distinctUntilChanged());
	}

	clearRouterDetailData(): void {
		this.refreshingStack = [];
		this.subscription.unsubscribe();
		this.dataBS.next(undefined);
	}

	private switchInitializing(): Observable<{}> {
		this.initializingBS.next(!this.initializingBS.value);
		return of({});
	}

	private switchRefreshing(refreshing: boolean): Observable<{}> {
		if (refreshing) {
			this.refreshingStack.push({});
		} else {
			this.refreshingStack.pop();
		}
		this.refreshingBS.next(this.refreshingStack.length > 0);
		return of({});
	}
}

export interface RouterDetailData {
	tenantId: string;
	isEditor: boolean;
	router: Router;
	isInactivePlan: boolean;
}
