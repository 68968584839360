import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ApplicationVendorCreateForm, Vendor} from "../model/new-application.model";
import {Observable} from "rxjs";
import {SecurityUtils} from "../security.utils";
import {catchError} from "rxjs/operators";
import {HttpClientUtils} from "../../utils/http-client.utils";

@Injectable()
export class VendorService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/vendors`;

	constructor(protected httpClient: HttpClient) {
	}

	public search(tenantId: string, search: string, page: number, initial: boolean): Observable<Vendor[]> {
		const encodedTerm = encodeURIComponent(search);
		return this.httpClient.get<Vendor[]>(`${this.serviceUrl(tenantId)}?search=${encodedTerm}&page=${page}&initial=${initial}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	public createTenantVendor(tenantId: string, form: ApplicationVendorCreateForm): Observable<Vendor> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}
}
