import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class InfrastructureStateService {
	private networkCountSubject: BehaviorSubject<number|undefined> = new BehaviorSubject<number|undefined>(undefined);
	private serverCountSubject: BehaviorSubject<number|undefined> = new BehaviorSubject<number|undefined>(undefined);
	private routerCountSubject: BehaviorSubject<number|undefined> = new BehaviorSubject<number|undefined>(undefined);

	get networkCount$(): Observable<number | undefined> {
		return this.networkCountSubject.asObservable();
	}

	get serverCount$(): Observable<number | undefined> {
		return this.serverCountSubject.asObservable();
	}

	get routerCount$(): Observable<number | undefined> {
		return this.routerCountSubject.asObservable();
	}

	// Setter methods to update counts
	setNetworkCount(count: number): void {
		this.networkCountSubject.next(count);
	}

	setServerCount(count: number): void {
		this.serverCountSubject.next(count);
	}

	setRouterCount(count: number): void {
		this.routerCountSubject.next(count);
	}
}
