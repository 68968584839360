<div class="flex-column gap-S" *ngIf="initialized">
	<div class="flex-align-center flex-space-between">
		<div class="font-MM fw-700">Applications</div>
		<app-mini-button (onClick)="openLinkServerToTeam()" size="s">
			<img src="../../../../../../../../assets/icons/add.svg" class="filter-accent" alt="">
		</app-mini-button>
	</div>
	@for (app of linkedApps; track app.application.application.applicationId) {
		<div class="server-card">
			<div (click)="goToApp(app.application.application.applicationId)"
				 class="pl-s pr-m pt-s pb-s flex-space-between flex-align-center gap-S c-p greyed-hover-2">
				<div class="flex-align-center c-p">
					<div style="user-select: none" class="flex-center gap-S">
						<app-display-logo [size]="38" [logo]="app.application.application.logo"></app-display-logo>
						<div class="flex-justify-center flex-column gap-XS" style="width: 130px">
							<div class="text-overflow-ellipsis font-M fw-700">{{ app.application.application.name }}
							</div>
							<div *ngIf="app.application.category"
								 class="text-overflow-ellipsis font-S">{{ app.application.category.name }}
							</div>
						</div>
						<app-chips-criticality short
											   [criticality]="app.application.application.criticality"></app-chips-criticality>
					</div>
				</div>
				<div class="flex-align-center gap-XL">
					<div class="disp-f f-d-r a-i-c j-c-s" style="padding-left: 10px;">
						<div class="disp-f f-d-r a-i-c j-c-s" style="margin-left: 16px;"
							 *ngIf="app.teams.length > 0"
							 [tooltipTriggerFor]="tooltip2" tooltipPosition="right">
							<div *ngFor="let team of app.teams| slice:0:3;let i = index" style="margin-left: -16px;"
								 [style.z-index]="5 + i">
								<div class="disp-f a-i-c j-c-c col bkg-accent-light radius-half white-border"
									 style="height: 40px; width: 40px">
									<img
										[src]="!!$any(team).icon ? '/assets/icons/team_icon/' + $any(team).icon + '.svg' : 'assets/icons/team.svg'"
										class="svg-22 filter-accent">
								</div>
							</div>
							<div *ngIf="app.teams.length > 3"
								 class="disp-f a-i-c j-c-c col bkg-accent-light radius-half white-border"
								 style="height: 40px; width: 40px; margin-left: -16px; z-index: 8">
								<span class="font-M fw-500 accent">+{{ app.teams.length - 3 }}</span>
							</div>
						</div>
						<app-tooltip #tooltip2>
							<div class="flex-column"
								 *ngFor="let structure of buildTeamTooltipView(app.teams) | keyvalue">
								<span class="fw-700">{{ structure.value.name }}</span>
								<ul class="pl-l pr-l">
									<li class="font-S"
										[ngClass]="{'mb-s': i + 1 < structure.value.businessServices.length}"
										*ngFor="let team of structure.value.businessServices; let i = index">{{ team }}
									</li>
								</ul>
							</div>
						</app-tooltip>
					</div>
					<div>
						<app-chips-count type="user" bold [value]="app.usage.value"></app-chips-count>
					</div>
					<div style="margin-left: auto">
						<div class="disp-f justify-center a-i-c">
							<img src="../../../../../../../../assets/icons/arrow-right.svg">
						</div>
					</div>
				</div>
			</div>
		</div>
	} @empty {
		<div class="w-100 flex-center flex-column" style="gap: 30px; height: 280px;">
			<img src="../../../../../../../../assets/illustrations/empty-app.svg" width="150">
			<span class="grey">{{ 'page.infrastructure.overview.noApps' | translate }}</span>
		</div>
	}
</div>
<div *ngIf="!initialized">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
