<div class="flex-column gap-widget">
	<!-- Automatic server -->
	<div *ngIf="!_initializing && server.automatic" class="bg-rounded pad-M-M-0" style="padding-bottom: 5px">
		<app-server-technical-information></app-server-technical-information>
	</div>
	<div *ngIf="!_initializing && !_loading && server.automatic" class="bg-rounded pad-M">
		<app-server-load-graph
			*ngIf="metrics && metrics.metrics.loadList"
			[data]="metrics.metrics.loadList"
		></app-server-load-graph>
	</div>
	<div *ngIf="!_initializing && !_loading && server.automatic" class="bg-rounded pad-M">
		<app-server-storage-graph
			*ngIf="metrics && metrics.metrics.storageList"
			[data]="metrics.metrics.storageList"
		></app-server-storage-graph>
	</div>

	<!-- Manual server -->
	<div *ngIf="!_initializing && !server.automatic" class="bg-rounded pad-M">
		<div class="flex-align-center flex-space-between mb-m">
			<span class="font-MM fw-700">{{ 'page.infrastructure.operation.technicalInformation' | translate }}</span>
		</div>
		<div class="manual-placeholder h-100 flex-center flex-column gap-XL" style="padding: 60px 0;">
			<img src="../../../../../../../assets/illustrations/no-users.svg" alt="">
			<span
				class="text-center w-70 grey font-MM">{{ 'page.infrastructure.operation.deployKapsul' | translate }}</span>
			<button type="button" class="deploy-btn" (click)="redirectToDoc()">
				<img width="20" src="../../../../../../../assets/icons/alert-circle.svg" class="filter-grey" alt="">
				<span>{{ 'page.infrastructure.operation.howToDeployKapsul' | translate }}</span>
			</button>
		</div>
	</div>


	<!-- Loaders -->
	<div *ngIf="_initializing || _loading" class="bg-rounded pad-M-M-0" style="padding-bottom: 5px">
		<app-list-loader></app-list-loader>
	</div>
	<div *ngIf="_initializing || _loading" class="bg-rounded pad-M-M-0" style="padding-bottom: 5px">
		<app-list-loader></app-list-loader>
	</div>

</div>
