import {Component, Inject, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {animate, style, transition, trigger} from "@angular/animations";
import {CopyTextModule} from "../../../global/input/copy-text/copy-text.module";
import {SnackbarModule} from "../../../global/snackbar/snackbar.module";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {ApikeyService} from "src/app/services/apikey.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AddServerComponent} from "./add-server/add-server.component";
import {AddNetworkComponent} from "./add-network/add-network.component";
import {AddRouterComponent} from "./add-router/add-router.component";

@Component({
	selector: 'app-add-equipment',
	standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule, CopyTextModule, SnackbarModule, AddServerComponent, AddNetworkComponent, AddRouterComponent],
	templateUrl: './add-equipment.component.html',
	styleUrl: './add-equipment.component.scss',
	animations: [
		trigger(
			'inAnimation1',
			[
				transition(
					':enter',
					[
						style({transform: 'translateY(100%)'}),
						animate('300ms 0ms cubic-bezier(.47,1.64,.41,.8)',
							style({transform: 'translateY(0)'}))
					]
				),
			]
		),
		trigger(
			'inAnimation2',
			[
				transition(
					':enter',
					[
						style({transform: 'translateY(100%)'}),
						animate('300ms 100ms cubic-bezier(.47,1.64,.41,.8)',
							style({transform: 'translateY(0)'}))
					]
				),
			]
		),
		trigger(
			'inAnimation3',
			[
				transition(
					':enter',
					[
						style({transform: 'translateY(300%)'}),
						animate('300ms 200ms cubic-bezier(.47,1.64,.41,.8)',
							style({transform: 'translateY(0)'}))
					]
				),
			]
		),
		trigger(
			'inAnimation4',
			[
				transition(
					':enter',
					[
						style({transform: 'translateY(300%)'}),
						animate('300ms 300ms cubic-bezier(.47,1.64,.41,.8)',
							style({transform: 'translateY(0)'}))
					]
				),
			]
		)
	],
})
export class AddEquipmentComponent implements OnInit {
	apiKey: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: {menu?: EquipmentMenu},
				private currentTenantService: CurrentTenantService,
				private apiKeyService: ApikeyService,
				private dialogRef: MatDialogRef<AddEquipmentComponent>) {
	}

	ngOnInit() {
		this.currentTenantService.getCurrentTenantIdChanges().subscribe(tenantId => {
			this.apiKeyService.getDefaultApiKey(tenantId).subscribe(apiKey => {
				this.apiKey = apiKey.apiKey.value;
			});
		});
	}

	onClose(event: boolean, type: 'server' | 'network' | 'router') {
		this.dialogRef.close({refresh: event, type});
	}

	closeDialog() {
		this.dialogRef.close({refresh: false, type: 'server'});
	}

	protected readonly close = close;
	protected readonly EquipmentMenu = EquipmentMenu;
}

export enum EquipmentMenu {
	SERVER,
	NETWORK,
	ROUTER,
	FIREWALL
}
