import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {INFRASTRUCTURE_URL} from "../../models/home/navigation.model";

@Injectable()
export class NewsService {
	constructor(private router: Router) {
	}
	news: NewsElement[] = [
		{
			id: 'infrastructure-news-1',
			icon: 'assets/icons/news/infrastructure-news-1.svg',
			iconBackgroundColor: 'rgba(168, 144, 243, 0.10)',
			title: 'page.home.news.infrastructureNews1.title',
			content: 'page.home.news.infrastructureNews1.content',
			image: 'assets/illustrations/news/infrastructure-news-1.png',
			creationDate: new Date(2025, 1, 13),
			expirationDate: new Date(2025, 3, 13),
			buttonLabel: 'page.home.news.infrastructureNews1.button',
			buttonAction: () => this.router.navigate([INFRASTRUCTURE_URL])
		}
	]
		.filter(news => news.expirationDate > new Date())
		.sort((a, b) => a.creationDate.getTime() - b.creationDate.getTime());

	seenNews: string[] = JSON.parse(localStorage.getItem('seenNews') || '[]');

	unseenNews: NewsElement[] = this.news.filter(news => !this.seenNews.includes(news.id));

	setSeenNews(news: string[]): void {
		this.seenNews = news;
		localStorage.setItem('seenNews', JSON.stringify(this.seenNews));
		this.unseenNews = this.news.filter(news => !this.seenNews.includes(news.id));
	}
}

export interface NewsElement {
	id: string;
	icon: string;
	iconBackgroundColor: string;
	title: string;
	content: string;
	image: string;
	creationDate: Date;
	expirationDate: Date;
	buttonLabel?: string;
	buttonAction?: () => void;
}
