import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class InsertOpenedService {
	private subject = new Subject();

	sendInsertOpenState(opened: boolean) {
		this.subject.next(opened);
	}

	getInsertOpenState(): Observable<any> {
		return this.subject.asObservable();
	}
}
