import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {homeRoutes} from './home-routing.routing';
import {HomeComponent} from './home.component';
import {TopbarComponent} from '../../modules/home/topbar/topbar.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {TableContentLoaderModule} from '../../modules/global/table-content-loader/table-content-loader.module';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {InviteUsersComponent} from '../../modules/home/settings/users/invite-users.component';
import {AlertModule} from '../../modules/global/alert/alert.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {CommonModule, DatePipe, NgOptimizedImage} from '@angular/common';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {ContentLoaderModule} from '@ngneat/content-loader';
import {MatStepperModule} from '@angular/material/stepper';
import {ApikeyService} from '../../services/apikey.service';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ChipsModule} from '../../modules/global/chips/chips/chips.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRippleModule} from '@angular/material/core';
import {NgChartsModule} from 'ng2-charts';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {
	ApplicationDetailComponent
} from '../../modules/home/applications/application-detail/application-detail.component';
import {TagsModule} from '../../modules/global/tags/tags.module';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {NotSetModule} from "../../modules/global/not-set/not-set.module";
import {
	ApplicationCostDistributionComponent
} from 'src/app/modules/home/applications/application-detail/finance-tab/application-cost-distribution/application-cost-distribution.component';
import {
	OperationTabComponent
} from 'src/app/modules/home/applications/application-detail/operation-tab/operation-tab.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DashboardPageLoaderComponent} from "../../modules/global/title-content-loader/dashboard-page-loader.component";
import {CalendarModule} from "../../modules/global/calendar/calendar.module";
import {EndTruncatePipe} from "../../utils/end-truncate.pipe";
import {DeckContentLoaderModule} from "../../modules/global/title-content-loader/deck-content-loader.module";
import {MiniWidgetComponent} from "../../modules/global/mini-widget/mini-widget.component";
import {LineCardModule} from "../../modules/global/cards/line-card/line-card.module";
import {TextareaModule} from "../../modules/global/input/textarea/textarea.module";
import {MiniButtonModule} from "../../modules/global/button/mini-button/mini-button.module";
import {ComingSoonChipsComponent} from "../../modules/global/chips/coming-soon-chips/coming-soon-chips.component";
import {SimpleTextInputModule} from "../../modules/global/input/simple-text-input/simple-text-input.module";
import {
	ApplicationSettingsComponent
} from "../../modules/home/applications/application-detail/application-settings/application-settings.component";
import {LinearGaugeComponent} from "../../modules/global/charts/linear-gauge/linear-gauge.component";
import {ApplicationIconModule} from "../../modules/global/application-icon/application-icon.module";

import {
	OverviewTabComponent
} from "src/app/modules/home/applications/application-detail/overview-tab/overview-tab.component";
import {
	ApplicationEventsPreviewComponent
} from "src/app/modules/home/applications/application-detail/operation-tab/application-events-preview/application-events-preview.component";
import {AutodiscoverModule} from "../../modules/home/applications/autodiscover/autodiscover.module";
import {ApplicationMiniWidgetModule} from "../../modules/global/application-mini-widget/application-mini-widget.module";
import {TendencyChipsModule} from "../../modules/global/chips/tendency-chips/tendency-chips.module";
import {SnackbarModule} from "../../modules/global/snackbar/snackbar.module";
import {OperationDashboardComponent} from "../../modules/home/operation-dashboard/operation-dashboard.component";
import {
	AnnouncementsWidgetComponent
} from "../../modules/home/operation-dashboard/announcements-widget/announcements-widget.component";
import {SettingsModule} from "../../modules/home/settings/settings.module";
import {SubmenuModule} from "../../modules/global/submenu/submenu.module";
import {TabsModule} from "../../modules/global/tabs/tabs.module";
import {TabModule} from "../../modules/global/tabs/tab/tab.module";
import {UsageTabModule} from "src/app/modules/home/applications/application-detail/usage-tab/usage-tab.module";
import {FilterModule} from "../../modules/global/filter/filter.module";
import {FinanceDashboardComponent} from "../../modules/home/finance-dashboard/finance-dashboard.component";
import {
	ApplicationDeadlinesComponent
} from "../../modules/home/finance-dashboard/application-deadlines/application-deadlines.component";
import {
	MostExpensiveAppsComponent
} from "../../modules/home/finance-dashboard/most-expensive-apps/most-expensive-apps.component";
import {
	MostExpensiveDoughnutComponent
} from "src/app/modules/home/finance-dashboard/most-expensive-doughnut/most-expensive-doughnut.component";
import {OverlapsComponent} from "../../modules/home/finance-dashboard/overlaps/overlaps.component";
import {DiagramsModule} from "src/app/modules/home/diagrams/diagrams.module";
import {UsageDashboardComponent} from "../../modules/home/usage-dashboard/usage-dashboard.component";
import {UsageGraphComponent} from "../../modules/home/usage-dashboard/usage-graph/usage-graph.component";
import {MostUsedAppsComponent} from "../../modules/home/usage-dashboard/most-used-apps/most-used-apps.component";
import {WorstNotedAppsComponent} from "../../modules/home/usage-dashboard/worst-noted-apps/worst-noted-apps.component";
import {UsageDoughnutComponent} from "../../modules/home/usage-dashboard/usage-doughnut/usage-doughnut.component";
import {
	HourlyAmplitudeRadialComponent
} from "../../modules/home/usage-dashboard/hourly-amplitude-radial/hourly-amplitude-radial.component";
import {HomePageComponent} from "../../modules/home/home-page/home-page.component";
import {
	HomePageHistoryLoaderComponent
} from "../../modules/global/title-content-loader/home-page-history-loader.component";
import {
	InfrastructureWidgetComponent
} from "../../modules/home/operation-dashboard/infrastructure-widget/infrastructure-widget.component";
import {
	PerformanceChartComponent
} from "../../modules/home/applications/application-detail/operation-tab/performance-chart/performance-chart.component";
import {
	ApplicationCommentsComponent
} from "src/app/modules/home/applications/application-detail/application-comments/application-comments.component";
import {MentionModule} from "angular-mentions";
import {CatalogModule} from "../../modules/home/applications/catalog-v2/catalog.module";
import {MiniDoughnutChartModule} from "../../modules/global/charts/mini-doughnut/mini-doughnut-chart.module";
import {
	AnnouncementListComponent
} from "../../modules/home/operation-dashboard/announcement-list/announcement-list.component";
import {
	ArchitectureTabComponent
} from '../../modules/home/applications/application-detail/architecture-tab/architecture-tab.component';
import {
	ApplicationTechnologyPreviewComponent
} from '../../modules/home/applications/application-detail/architecture-tab/application-technology-preview/application-technology-preview.component';
import {
	ApplicationMappingResourcePreviewComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-resource-preview/application-mapping-resource-preview.component';
import {
	ApplicationMappingApplicationPreviewComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-application-preview/application-mapping-application-preview.component';
import {
	ApplicationDocumentPreviewComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-document-preview/application-document-preview.component';
import {
	ApplicationTechnologyFormComponent
} from '../../modules/home/applications/application-detail/architecture-tab/application-technology-form/application-technology-form.component';
import {
	ApplicationTechnologyFormDialogComponent
} from '../../modules/home/applications/application-detail/architecture-tab/application-technology-form/dialog/application-technology-form-dialog.component';
import {TechnologyChipsModule} from '../../modules/global/chips/technology-chips/technology-chips.module';
import {ResourceMappingCardModule} from 'src/app/modules/global/resource-mapping-card/resource-mapping-card.module';
import {
	ApplicationMappingResourceFormComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-resource-form/application-mapping-resource-form.component';
import {
	ApplicationMappingResourceFormDialogComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-resource-form/dialog/application-mapping-resource-form-dialog.component';
import {
	ApplicationMappingApplicationFormComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-application-form/application-mapping-application-form.component';
import {
	ApplicationMappingApplicationFormDialogComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-application-form/dialog/application-mapping-application-form-dialog.component';
import {
	ArchitectureDashboardComponent
} from 'src/app/modules/home/architecture-dashboard/architecture-dashboard.component';
import {
	SystemAgeDistributionComponent
} from 'src/app/modules/home/architecture-dashboard/system-age-distribution/system-age-distribution.component';
import {
	SystemAgeAverageComponent
} from 'src/app/modules/home/architecture-dashboard/system-age-average/system-age-average.component';
import {
	MostUsedTechnologyComponent
} from 'src/app/modules/home/architecture-dashboard/most-used-technology/most-used-technology.component';
import {
	MostObsoleteApplicationComponent
} from 'src/app/modules/home/architecture-dashboard/most-obsolete-application/most-obsolete-application.component';
import {
	MostComplexApplicationComponent
} from 'src/app/modules/home/architecture-dashboard/most-complex-application/most-complex-application.component';
import {
	ApplicationTypeDistributionComponent
} from 'src/app/modules/home/architecture-dashboard/application-type-distribution/application-type-distribution.component';
import {
	ApplicationDocumentAverageComponent
} from 'src/app/modules/home/architecture-dashboard/application-document-average/application-document-average.component';
import {
	ApplicationContactPreviewComponent
} from 'src/app/modules/home/applications/application-detail/overview-tab/application-contact-preview/application-contact-preview.component';
import {
	ApplicationInformationFormComponent
} from 'src/app/modules/home/applications/application-detail/overview-tab/application-information-form/application-information-form.component';
import {
	AddEquipmentComponent
} from "../../modules/home/infrastructure/add-equipment/add-equipment.component";
import {
	ApplicationSettingGeneralComponent
} from 'src/app/modules/home/applications/application-detail/application-settings/application-setting-general/application-setting-general.component';
import {
	ApplicationSettingUsageComponent
} from 'src/app/modules/home/applications/application-detail/application-settings/application-setting-usage/application-setting-usage.component';
import {
	ApplicationLogPreviewComponent
} from 'src/app/modules/home/applications/application-detail/overview-tab/application-log-preview/application-log-preview.component';
import {MainMenuComponent} from "../../modules/global/main-menu/main-menu.component";
import {
	FinanceTabComponent
} from 'src/app/modules/home/applications/application-detail/finance-tab/finance-tab.component';
import {
	ApplicationDocumentFormDialogComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-document-form/dialog/application-document-form-dialog.component';
import {
	ApplicationDocumentFormComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-document-form/application-document-form.component';
import {
	ApplicationContractPreviewComponent
} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-preview/application-contract-preview.component';
import {
	ApplicationContractFormDialogComponent
} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/application-contract-form-dialog.component';
import {
	SubscriptionFormComponent
} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/subscription-form/subscription-form.component';
import {
	LicenceFormComponent
} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/licence-form/licence-form.component';
import {
	ServiceFormComponent
} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/service-form/service-form.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DropdownTriggerDirective} from 'src/app/modules/design-system/dropdown/dropdown-trigger.directive';
import {HealthGraphComponent} from "../../modules/home/operation-dashboard/health-graph/health-graph.component";
import {
	PerformanceRepartitionDoughnutComponent
} from "../../modules/home/operation-dashboard/performance-repartition-doughnut/performance-repartition-doughnut.component";
import {
	MostImpactedAppsComponent
} from "../../modules/home/operation-dashboard/most-impacted-apps/most-impacted-apps.component";
import {
	ApplicationLifeCycleComponent
} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-life-cycle/application-life-cycle.component';
import {ApplicationListComponent} from 'src/app/modules/home/applications/application-list/application-list.component';
import {DemoComponent} from 'src/app/modules/home/demo/demo.component';
import {CopyTextModule} from 'src/app/modules/global/input/copy-text/copy-text.module';
import {
	DialogPlaceholderCardModule
} from "../../modules/design-system/dialog-placeholder-card/dialog-placeholder-card.module";
import {DesignSystemModule} from "../../modules/design-system/design-system.module";
import {ExtensionComponent} from "../../modules/home/extension/extension.component";
import {
	ExtensionsDeployedGraphComponent
} from "../../modules/home/extension/extensions-deployed-graph/extensions-deployed-graph.component";
import {ExtensionSettingsComponent} from "../../modules/home/extension/extension-settings/extension-settings.component";
import {
	WorstPerformanceAppsComponent
} from "../../modules/home/operation-dashboard/worst-performance-apps/worst-performance-apps.component";
import {DirectivesModule} from 'src/app/directives/directives.module';
import {OnboardingComponent} from "../../modules/home/onboarding/onboarding.component";
import {MatDialogModule} from "@angular/material/dialog";
import {
	FinancialProjectionComponent
} from 'src/app/modules/home/finance-dashboard/financial-projection/financial-projection.component';
import {CustomDatePipe} from 'src/app/pipes/date/custom-date.pipe';
import {CostPipe} from 'src/app/pipes/number/cost.pipe';
import {
	MostComplexApplicationDrawerComponent
} from 'src/app/modules/home/architecture-dashboard/most-complex-application/drawer/most-complex-application-drawer.component';
import {
	MostObsoleteApplicationDrawerComponent
} from 'src/app/modules/home/architecture-dashboard/most-obsolete-application/drawer/most-obsolete-application-drawer.component';
import {
	ApplicationDeadlinesDrawerComponent
} from 'src/app/modules/home/finance-dashboard/application-deadlines/drawer/application-deadlines-drawer.component';
import {
	MostExpensiveAppsDrawerComponent
} from 'src/app/modules/home/finance-dashboard/most-expensive-apps/drawer/most-expensive-apps-drawer.component';
import {
	WorstPerformanceAppsDrawerComponent
} from 'src/app/modules/home/operation-dashboard/worst-performance-apps/drawer/worst-performance-apps-drawer.component';
import {
	MostImpactedAppsDrawerComponent
} from 'src/app/modules/home/operation-dashboard/most-impacted-apps/drawer/most-impacted-apps-drawer.component';
import {
	WorstNotedAppsDrawerComponent
} from 'src/app/modules/home/usage-dashboard/worst-noted-apps/drawer/worst-noted-apps-drawer.component';
import {
	MostUsedAppsDrawerComponent
} from 'src/app/modules/home/usage-dashboard/most-used-apps/drawer/most-used-apps-drawer.component';
import {MatMenuModule} from '@angular/material/menu';
import {
	ApplicationOptimizationsComponent
} from 'src/app/modules/home/finance-dashboard/application-optimizations/application-optimizations.component';
import {
	ApplicationOptimizationsDrawerComponent
} from 'src/app/modules/home/finance-dashboard/application-optimizations/drawer/application-optimizations-drawer.component';
import {DisplayAppStatusComponent} from "../../modules/design-system/display-app-status/display-app-status.component";
import {TableComponent} from "../../modules/design-system/table/table.component";
import {DiagramTooltipComponent} from "../../modules/home/diagrams/diagram-tooltip/diagram-tooltip.component";
import {
	MappingDrawerTooltipComponent
} from "../../modules/home/applications/application-detail/architecture-tab/application-mapping-application-preview/mapping-drawer-tooltip/mapping-drawer-tooltip.component";
import {
	ApplicationResponsibleComponent
} from "../../modules/home/applications/application-detail/overview-tab/application-responsible/application-responsible.component";
import {
	ApplicationAuthenticationComponent
} from "../../modules/home/applications/application-detail/architecture-tab/application-authentication/application-authentication.component";
import {ServerDetailComponent} from "../../modules/home/infrastructure/server/server-detail/server-detail.component";
import {
	ServerOverviewTabComponent
} from "../../modules/home/infrastructure/server/server-detail/server-overview-tab/server-overview-tab.component";
import {
	ServerInformationFormComponent
} from "../../modules/home/infrastructure/server/server-detail/server-overview-tab/server-information-form/server-information-form.component";
import {
	ServerArchitectureTabComponent
} from "../../modules/home/infrastructure/server/server-detail/server-architecture-tab/server-architecture-tab.component";
import {
	ServerSettingsComponent
} from "../../modules/home/infrastructure/server/server-detail/server-settings/server-settings.component";
import {
	ServerOperationTabComponent
} from "../../modules/home/infrastructure/server/server-detail/server-operation-tab/server-operation-tab.component";
import {
	ServerApplicationsComponent
} from "../../modules/home/infrastructure/server/server-detail/server-overview-tab/server-applications/server-applications.component";
import {
	ServerLogPreviewComponent
} from "../../modules/home/infrastructure/server/server-detail/server-overview-tab/server-log-preview/server-log-preview.component";
import {NewsPopupComponent} from "../../modules/design-system/news-popup/news-popup.component";

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(homeRoutes),
		DirectivesModule,
		AddEquipmentComponent,
		AlertModule,
		ApplicationIconModule,
		ApplicationMiniWidgetModule,
		AutodiscoverModule,
		CalendarModule,
		CatalogModule,
		ChipsModule,
		ClipboardModule,
		ContentLoaderModule,
		ContentLoaderModule,
		DeckContentLoaderModule,
		DiagramsModule,
		FilterModule,
		FormsModule,
		FilterModule,
		FormsModule,
		LineCardModule,
		MainMenuComponent,
		MatBadgeModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatListModule,
		MatPaginatorModule,
		MatProgressSpinnerModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSlideToggleModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MentionModule,
		MiniButtonModule,
		MiniDoughnutChartModule,
		NgChartsModule,
		NgOptimizedImage,
		NotSetModule,
		ReactiveFormsModule,
		ResourceMappingCardModule,
		ScrollingModule,
		SettingsModule,
		SimpleTextInputModule,
		SnackbarModule,
		SubmenuModule,
		TabModule,
		TableContentLoaderModule,
		TabsModule,
		TagsModule,
		TechnologyChipsModule,
		TendencyChipsModule,
		TextareaModule,
		TranslateModule,
		UsageTabModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		DropdownTriggerDirective,
		HealthGraphComponent,
		PerformanceRepartitionDoughnutComponent,
		EndTruncatePipe,
		CopyTextModule,
		DialogPlaceholderCardModule,
		TextareaModule,
		TranslateModule,
		UsageTabModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		DropdownTriggerDirective,
		HealthGraphComponent,
		PerformanceRepartitionDoughnutComponent,
		EndTruncatePipe,
		CopyTextModule,
		DesignSystemModule,
		ExtensionsDeployedGraphComponent,
		ExtensionSettingsComponent,
		OnboardingComponent,
		CustomDatePipe,
		CostPipe,
		MatMenuModule,
		DisplayAppStatusComponent,
		TableComponent,
		DiagramTooltipComponent,
		MappingDrawerTooltipComponent,
		ApplicationAuthenticationComponent,
		ApplicationResponsibleComponent,
		ApplicationAuthenticationComponent,
		ServerOperationTabComponent,
		ServerArchitectureTabComponent,
		ServerSettingsComponent,
		ServerApplicationsComponent,
		ServerLogPreviewComponent,
		NewsPopupComponent,
	],
	declarations: [
		HomeComponent,
		DemoComponent,
		TopbarComponent,
		InviteUsersComponent,
		ApplicationListComponent,
		ApplicationDetailComponent,
		ServerDetailComponent,
		OverviewTabComponent,
		ServerOverviewTabComponent,
		ApplicationCostDistributionComponent,
		ApplicationContractPreviewComponent,
		ApplicationContractFormDialogComponent,
		SubscriptionFormComponent,
		LicenceFormComponent,
		ServiceFormComponent,
		FinanceTabComponent,
		OperationTabComponent,
		MiniWidgetComponent,
		ComingSoonChipsComponent,
		ApplicationSettingsComponent,
		ApplicationSettingGeneralComponent,
		ApplicationSettingUsageComponent,
		LinearGaugeComponent,
		ApplicationEventsPreviewComponent,
		OperationDashboardComponent,
		AnnouncementsWidgetComponent,
		FinanceDashboardComponent,
		ApplicationDeadlinesComponent,
		ApplicationDeadlinesDrawerComponent,
		FinancialProjectionComponent,
		MostExpensiveAppsComponent,
		MostExpensiveAppsDrawerComponent,
		MostExpensiveDoughnutComponent,
		OverlapsComponent,
		ApplicationOptimizationsComponent,
		ApplicationOptimizationsDrawerComponent,
		UsageDashboardComponent,
		UsageGraphComponent,
		MostUsedAppsComponent,
		MostUsedAppsDrawerComponent,
		WorstNotedAppsComponent,
		WorstNotedAppsDrawerComponent,
		MostImpactedAppsComponent,
		MostImpactedAppsDrawerComponent,
		WorstPerformanceAppsComponent,
		WorstPerformanceAppsDrawerComponent,
		UsageDoughnutComponent,
		HourlyAmplitudeRadialComponent,
		HomePageComponent,
		HomePageHistoryLoaderComponent,
		InfrastructureWidgetComponent,
		PerformanceChartComponent,
		ApplicationCommentsComponent,
		AnnouncementListComponent,
		ArchitectureTabComponent,
		ApplicationTechnologyPreviewComponent,
		ApplicationTechnologyFormComponent,
		ApplicationTechnologyFormDialogComponent,
		ApplicationMappingResourcePreviewComponent,
		ApplicationMappingResourceFormComponent,
		ApplicationMappingResourceFormDialogComponent,
		ApplicationMappingApplicationPreviewComponent,
		ApplicationMappingApplicationFormComponent,
		ApplicationMappingApplicationFormDialogComponent,
		ApplicationDocumentPreviewComponent,
		ApplicationDocumentFormComponent,
		ApplicationDocumentFormDialogComponent,
		ApplicationLifeCycleComponent,
		ArchitectureDashboardComponent,
		SystemAgeDistributionComponent,
		SystemAgeAverageComponent,
		MostUsedTechnologyComponent,
		MostObsoleteApplicationComponent,
		MostObsoleteApplicationDrawerComponent,
		MostComplexApplicationComponent,
		MostComplexApplicationDrawerComponent,
		ApplicationTypeDistributionComponent,
		ApplicationDocumentAverageComponent,
		ApplicationContactPreviewComponent,
		ApplicationInformationFormComponent,
		ServerInformationFormComponent,
		ApplicationLogPreviewComponent,
		ExtensionComponent
	],
	exports: [
		RouterModule,
		ContentLoaderModule,
		DashboardPageLoaderComponent,
		MiniWidgetComponent,
		HomePageHistoryLoaderComponent,
	],
	providers: [
		ApikeyService,
		DatePipe,
	]
})
export class HomeModule {
}
