import {inject, Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SecurityUtils} from "../security.utils";
import {catchError} from "rxjs/operators";
import {HttpClientUtils} from "../../utils/http-client.utils";
import {CriticalityLevel} from "../tenant.service";
import {Network, NetworkServers} from "./network.service";
import {Server} from "../model/resource.model";

@Injectable({
	providedIn: 'root'
})
export class RouterService {

	constructor() {
	}

	private backendUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/routers`;

	private httpClient = inject(HttpClient);

	create(tenantId: string, body: RouterForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.backendUrl(tenantId)}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	update(tenantId: string, routerId: string, body: RouterForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${routerId}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	delete(tenantId: string, routerId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.backendUrl(tenantId)}/${routerId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getRouter(tenantId: string, routerId: string): Observable<Router> {
		return this.httpClient.get<Router>(`${this.backendUrl(tenantId)}/${routerId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantRouterCount(tenantId: string): Observable<number> {
		return this.httpClient.get<number>(`${this.backendUrl(tenantId)}/count`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, 0)));
	}

	getTenantRoutersTableData(tenantId: string): Observable<RouterTableData[]> {
		return this.httpClient.get<RouterTableData[]>(`${this.backendUrl(tenantId)}/table-data`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getRouterNetworks(tenantId: string, routerId: string): Observable<Network[]> {
		return this.httpClient.get<Network[]>(`${this.backendUrl(tenantId)}/${routerId}/networks`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getRouterCriticality(tenantId: string, routerId: string): Observable<CriticalityLevel | null> {
		return this.httpClient.get<CriticalityLevel | null>(`${this.backendUrl(tenantId)}/${routerId}/criticality`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateRouterNetworkList(tenantId: string, routerId: string, networks: {
		networkId: string,
		selected: boolean
	}[]): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${routerId}/networks`, networks, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getTenantRouters(tenantId: string): Observable<Router[]> {
		return this.httpClient.get<Router[]>(`${this.backendUrl(tenantId)}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}
}

export interface RouterForm {
	name: string,
	type: RouterType,
	ipAddress?: string,
	location?: string,
	description?: string,
}

export interface RouterTableData {
	router: Router,
	criticality: CriticalityLevel | null
}

export enum RouterType {
	ROUTER = "router",
	FIREWALL = "firewall",
}

export interface Router {
	id: string,
	name: string,
	type: RouterType,
	ipAddress: string | null,
	location: string | null,
	description: string | null,
}

export interface RouterSchemaData {
	router: Router,
	networks: NetworkServers[]
}
