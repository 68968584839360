<div class="flex-column gap-L">
	<div class="flex-align-center gap-S">
		<div class="font-MM fw-700">{{ 'page.infrastructure.operation.technicalInformation' | translate }}</div>
	</div>
	<div class="flex-column">
		<div class="flex-align-center border-light-grey-top" style="height: 50px;">
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img src="../../../../../../../../assets/icons/infrastructure_overview/machine_name.svg"
						 height="14px" alt="">
					<span
						class="font-M fw-500 grey">{{ 'page.infrastructure.operation.machineName' | translate }}</span>
				</div>
				<div class="flex-align-center justify-flex-end w-60">
					<span class="font-M fw-500">{{ server.name }}</span>
				</div>
			</div>
			<div class="border-light-grey-left" style="height: 25px"></div>
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img src="../../../../../../../../assets/icons/infrastructure_overview/model.svg" height="14px"
						 alt="">
					<span class="font-M fw-500 grey">{{ 'page.infrastructure.operation.model' | translate }}</span>
				</div>
				<div class="flex-align-center justify-flex-end w-60">
					<span class="font-M fw-500">{{ server.model || '-' }}</span>
				</div>
			</div>
		</div>
		<div class="flex-align-center border-light-grey-top" style="height: 50px;">
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img src="../../../../../../../../assets/icons/infrastructure_overview/serial_number.svg"
						 height="14px" alt="">
					<span
						class="font-M fw-500 grey">{{ 'page.infrastructure.operation.serialNumber' | translate }}</span>
				</div>
				<div class="flex-align-center justify-flex-end w-60">
					<span class="font-M fw-500">{{ server.serialNumber || '-' }}</span>
				</div>
			</div>
			<div class="border-light-grey-left" style="height: 25px"></div>
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img src="../../../../../../../../assets/icons/infrastructure_overview/uptime.svg" height="14px"
						 alt="">
					<span class="font-M fw-500 grey">{{ 'page.infrastructure.operation.uptime' | translate }}</span>
				</div>
				<div class="flex-align-center justify-flex-end w-60">
					<span class="font-M fw-500">{{ getUptime(server.uptime) || '-' }}</span>
				</div>
			</div>
		</div>
	</div>
</div>
