<div class="select-trigger-content flex-space-between flex-align-center bkg-light-grey c-p pad-0-S border-box-full"
	 [style.min-height.px]="minHeight ? minHeight : undefined"
	 [style.border]="border ? border : undefined"
	 [class]="'appearance-' + appearance" [class.error]="error" [class.warning]="warning">
	<div class="flex-align-center gap-XS select-trigger-text font-M fw-500 text-overflow-ellipsis"
		 [class.warning]="warning">
		<img *ngIf="warning" width="12" src="assets/icons/warning-triangle.svg">
		<ng-content></ng-content>
	</div>
	<img class="select-trigger-icon" [ngClass]="{'filter-grey': appearance === 'chips' && !warning,  'filter-warning': appearance === 'chips' && warning}"
		 style="transform: rotate(90deg)"
		 ngSrc="assets/icons/arrow-right-black.svg" height="11" width="11" alt="">
</div>
