<app-snackbar></app-snackbar>
<div class="w-100 h-100">
	<div class="header-height">
		<!-- Title loader -->
		<div class="pad-L" *ngIf="_loading || _initializing" style="height: 37px">
			<app-title-content-loader></app-title-content-loader>
		</div>

		<div class="pad-L slider-header" *ngIf="!_loading && !_initializing" >
			<div class="flex gap-S" style="width: 550px;">
				<!-- TITLE -->
				<div class="flex-align-center gap-S" style="max-width: 450px;">
					<app-display-logo [logo]="application.logo" [size]="45"></app-display-logo>
					<span class="font-M fw-700 primary text-overflow-ellipsis">{{ application.name }}</span>
				</div>
				<!-- TAGS -->
				<div class="flex-align-center overflow-x" style="min-width: 100px">
					<app-tags size="s" [icon]="'sell'" [canBeDeleted]="isEditor"
							  *ngFor="let tag of applicationTags"
							  (onDelete)="onRemoveTag(tag)">
						{{ tag.name }}
					</app-tags>

					<app-multi-select [inputControl]="tagsFormControl" multiple
									  [disabled]="_initializing || _savingTag"
									  (confirm)="confirmUpdateTags()">
						<div select-trigger>
							<app-tags [icon]="'add'" size="s" class="uppercase c-p">
								<span class="font-XS fw-500" style="white-space: nowrap">{{ 'button.addTag' | translate }}</span>
							</app-tags>
						</div>
						<app-select-search [searchControl]="searchTagControl"></app-select-search>
						<app-select-insert [saving]="_savingTag" (create)="createTag($event)"></app-select-insert>
						<app-select-option *ngFor="let tag of tagsFilteredList" [value]="tag">{{tag.name}}</app-select-option>
					</app-multi-select>
				</div>
			</div>

			<div class="flex-align-center gap-S">
				<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
								 (click)="close()">
				</app-icon-button>
				<app-icon-button type="stroked" background [img]="'assets/icons/save.svg'"
								 *ngIf="!!settings"
								 (click)="onSaveSettings()">
				</app-icon-button>
				<div style="position: relative">
					<div *ngIf="unreadCommentsNotifications > 0" class="unread-notifications"></div>
					<div *ngIf="unreadCommentsNotifications == 0 && commentsCount > 0" class="total-comments flex-center">
						<span class="font-S fw-700">{{ commentsCount < 10 ? commentsCount : '9+' }}</span>
					</div>
					<app-icon-button type="stroked" background [img]="!comments ? 'assets/icons/comment-inactive.svg' : 'assets/icons/comment-active.svg'"
									 [focused]="!!comments"
									 (click)="onOpenAppComments()">
					</app-icon-button>
				</div>
				<app-icon-button type="stroked" background [img]="!settings ? 'assets/icons/cog-inactive.svg' : 'assets/icons/cog-active.svg'"
								 [focused]="!!settings"
								 (click)="onOpenAppSettings()">
				</app-icon-button>
				<app-icon-button type="stroked" background color="danger" [img]="'assets/icons/trash.svg'"
								 (click)="onOpenDeleteAppPopup()">
				</app-icon-button>
			</div>
		</div>
	</div>

	<div *ngIf="!!settings || !!comments" [ngClass]="{'settings-triangle': !!settings, 'comments-triangle': !!comments}"></div>

	<div class="content-height pad-L border-box overflow-y above-triangle">
		<app-tabs-group [hidden]="!!settings || !!comments"
						[selectedIndex]="sliderIndex"
						(selectedIndexChange)="onSliderChange($event)">

			<!-- Tab Overview -->
			<app-tab label="{{ 'menu.overview2' | translate }}">
				<div *ngIf="lazyLoadedTabs[0]" class="flex-column gap-XL mt-m">
					<app-overview-tab [tabActive]="sliderIndex === 0 && !settings && !comments"></app-overview-tab>
				</div>
			</app-tab>

			<!-- Tab Operations -->
			<app-tab label="{{ 'menu.overview' | translate }}">
				<div *ngIf="lazyLoadedTabs[1]" class="flex-column gap-XL mt-m">
					<app-operation-tab></app-operation-tab>
				</div>
			</app-tab>

			<!-- Tab Finance -->
			<app-tab label="{{ 'menu.finance' | translate }}">
				<div *ngIf="lazyLoadedTabs[2]" class="flex-column gap-XL mt-m">
                    <app-finance-tab></app-finance-tab>
				</div>
			</app-tab>

			<!-- Tab Usage -->
			<app-tab [badge]="autodiscoveredTeamsCount > 0 && displayAutodiscoverTeamsNotification ? autodiscoveredTeamsCount.toString() : undefined" label="{{ 'menu.usage' | translate }}">
				<div *ngIf="lazyLoadedTabs[3]" class="flex-column gap-XL mt-m">
					<app-usage-tab (autodiscoveredTeamsChanged)="onAutodiscoverTeamsChanged($event)" (autodiscoveredTeamsSeen)="setLocalStorageTeamsCount()"></app-usage-tab>
				</div>
			</app-tab>

			<!-- Tab Design -->
			<app-tab label="{{ 'menu.architecture' | translate }}">
				<div *ngIf="lazyLoadedTabs[4]" class="flex-column gap-XL mt-m">
					<app-architecture-tab></app-architecture-tab>
				</div>
			</app-tab>
		</app-tabs-group>

		<app-application-comments *ngIf="!!comments"
								  (onCommentsCountChange)="commentsCount = $event"></app-application-comments>

		<app-application-settings *ngIf="!!settings"></app-application-settings>
	</div>
</div>
