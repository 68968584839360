import {ActivatedRoute, Router} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable()
export class NetworkUrlService {
	constructor(protected activatedRoute: ActivatedRoute, protected router: Router) {
	}

	public getCurrentNetworkTab(): number {
		let index = this.activatedRoute.snapshot.queryParamMap.get('selectedNetworkTab');
		if (index != null)
			return parseInt(index)
		return 0;
	}

	public getParameter(): boolean {
		let settingsPresent = this.activatedRoute.snapshot.queryParamMap.get('settings');
		return settingsPresent != null && settingsPresent != 'false';
	}

	public changeCurrentNetworkTab(index: number | null) {
		this.router.navigate([this.router.url.split('?')[0]], {
			queryParams: {'selectedNetworkTab': index},
			queryParamsHandling: "merge"
		})
	}

	public getCurrentNetwork(): string | null {
		return this.activatedRoute.snapshot.queryParamMap.get('networkId');
	}

	public changeCurrentNetwork(id: string | null) {
		if (id !== null)
			this.router.navigate([], {queryParams: {networkId: id}, queryParamsHandling: "merge"})
		else
			this.router.navigate([], {
				queryParams: {networkId: null, selectedNetworkTab: null, settings: null},
				queryParamsHandling: "merge"
			})
	}

	navigateInAndOutOfParameter(parameter: boolean) {
		this.navigateInAndOut(parameter);
	}

	private navigateInAndOut(setting: boolean): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {settings: setting},
			queryParamsHandling: 'merge'
		});
	}
}
