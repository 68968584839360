<div class="flex gap-M pad-XL-XL-0 w-100 border-box">
	<app-diagram-breadcrumb class="w-100"></app-diagram-breadcrumb>

	<div class="filter-panel-container bkg-white b-radius-9">
		<app-icon-button color="accent" type="stroked" [img]="'assets/icons/settings.svg'" class="filter-panel-btn" panelClass="h-100-imp w-100-imp bkg-transparent"
						 [disabled]="_initializing" [loading]="_loading" [focused]="miniDrawer.opened()"
						 [miniDrawerTriggerFor]="miniDrawer" [matBadge]="activeFilters > 0 ? activeFilters : undefined" matBadgeColor="accent">
		</app-icon-button>
		<app-mini-drawer #miniDrawer>
			<div class="flex-column gap-M" *ngIf="!_loading">
				<div class="flex-column gap-S">
					<div class="flex-align-center flex-space-between">
						<span class="font-M fw-700">{{'page.diagram.filters.organization'|translate}}</span>
						<span *ngIf="activeFilters > 0" class="font-M fw-500 danger underlined-hover c-p"
							  (click)="resetFilters()">{{'button.deleteFilters'|translate}}</span>
					</div>
					<app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.organization))" multiple [addMinHeight]="true">
						<app-select-trigger appearance="field">{{!organizationFormValue.length ? ('page.diagram.filters.allOrganization'|translate) : (organizationFormValue[0].name  + (organizationFormValue.length > 1 ? ' (+' + (organizationFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
						<app-select-search [searchControl]="searchOrganizationControl"></app-select-search>
						<app-select-option-group *ngFor="let group of filterData.organization" [label]="group.organization.name" noOptionPrefix>
							<app-select-option *ngFor="let org of group.children" [value]="org.organization">
								<div class="flex-align-center gap-M">
									<img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
									<span>{{org.organization.name}}</span>
								</div>
							</app-select-option>
						</app-select-option-group>
					</app-multi-select>
				</div>
				<div class="border-light-grey-top w-100"></div>
				<div class="flex-column gap-S">
					<span class="font-M fw-700">{{'page.diagram.filters.criticality'|translate}}</span>
					<app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.criticality))" multiple>
						<app-select-trigger appearance="field">{{!criticalityFormValue.length ? ('page.diagram.filters.allCriticality'|translate) : ((('page.appDetails.businessCriticality.' + criticalityFormValue[0].toLowerCase())|translate) + (criticalityFormValue.length > 1 ? ' (+' + (criticalityFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
						<app-select-option *ngFor="let level of filterData.criticality" [value]="level">
							<div class="flex-align-center gap-M">
								<img src="assets/icons/criticality-{{level.toLowerCase()}}.svg" height="16" alt=""/>
								<span>{{('page.appDetails.businessCriticality.' + level.toLowerCase())|translate}}</span>
							</div>
						</app-select-option>
					</app-multi-select>
				</div>
				<div class="border-light-grey-top w-100"></div>
				<div class="flex-column gap-S">
					<span class="font-M fw-700">{{'page.diagram.filters.category'|translate}}</span>
					<app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.category))" multiple [addMinHeight]="true">
						<app-select-trigger appearance="field">{{!categoryFormValue.length ? ('page.diagram.filters.allCategory'|translate) : (categoryFormValue[0].name  + (categoryFormValue.length > 1 ? ' (+' + (categoryFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
						<app-select-search [searchControl]="searchCategoryControl"></app-select-search>
						<app-select-option *ngFor="let category of filterData.category" [value]="category">
							{{category.name}}
						</app-select-option>
					</app-multi-select>
				</div>
				<div class="border-light-grey-top w-100"></div>
				<div class="flex-column gap-S">
					<span class="font-M fw-700">{{'page.diagram.filters.application'|translate}}</span>
					<app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.application))" multiple [addMinHeight]="true">
						<app-select-trigger appearance="field">{{!applicationFormValue.length ? ('page.diagram.filters.allApplication'|translate) : (applicationFormValue[0].name  + (applicationFormValue.length > 1 ? ' (+' + (applicationFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
						<app-select-search [searchControl]="searchApplicationControl"></app-select-search>
						<app-select-option *ngFor="let app of filterData.applications" [value]="app">
							<app-display-application [application]="app"></app-display-application>
						</app-select-option>
					</app-multi-select>
				</div>
				<div class="border-light-grey-top w-100"></div>
				<div class="flex-column gap-S">
					<span class="font-M fw-700">{{'page.application.table.filters.status'|translate}}</span>
					<app-multi-select [inputControl]="$any(filterForm.get(formName.status))" multiple>
						<app-select-trigger appearance="field">{{!$any(filterForm.get(formName.status)).value[0] ? '-' : ((('page.appDetails.lifeCycle.' + $any(filterForm.get(formName.status)).value[0])|translate) + ($any(filterForm.get(formName.status)).value.length > 1 ? ' (+' + ($any(filterForm.get(formName.status)).value.length - 1) + ')' : ''))}}</app-select-trigger>
						<app-select-option *ngFor="let appStatus of filterData.status" [value]="appStatus">
							<app-display-app-status [appStatus]="appStatus"></app-display-app-status>
						</app-select-option>
					</app-multi-select>
				</div>
				<div class="border-light-grey-top w-100"></div>
				<div class="flex-column gap-S">
					<span class="font-M fw-700">{{'page.application.table.filters.tags'|translate}}</span>
					<app-multi-select [inputControl]="$any(filterForm.get(formName.tag))" multiple>
						<app-select-trigger appearance="field">{{!$any(filterForm.get(formName.tag)).value[0] ? '-' : ($any(filterForm.get(formName.tag)).value[0].name  + ($any(filterForm.get(formName.tag)).value.length > 1 ? ' (+' + ($any(filterForm.get(formName.tag)).value.length - 1) + ')' : ''))}}</app-select-trigger>
						<app-select-option *ngFor="let tag of filterData.tag" [value]="tag">
							{{tag.name}}
						</app-select-option>
					</app-multi-select>
				</div>
			</div>
		</app-mini-drawer>
	</div>
</div>

<div *ngIf="!diagramApplications || !isEmpty" [style.visibility]="!_loading && !displayProgressBar ? 'visible' : 'hidden'" class="pad-XL" style="height: 650px;">
	<div class="axis-container h-100 pad-top-L pad-right-L p-relative" #container style="min-width: 800px;">
		<div class="p-absolute empty-container flex-center flex-column gap-L" *ngIf="isEmptyWithCurrentAxis && !isEmptyWithFilters">
			<img src="assets/illustrations/quadrant-no-axis-result.svg">
			<span class="grey w-70 text-center">{{ 'page.diagram.quadrant.placeholder1' | translate }}</span>
		</div>
		<div style="width: 400px;" class="p-absolute empty-container flex-space-between flex-column flex-align-center pad-M" *ngIf="isEmptyWithFilters && !isEmptyWithCurrentAxis">
			<div class="flex-center flex-column gap-L w-100" style="margin-top: 50px">
				<img src="assets/illustrations/quadrant-no-axis-result.svg">
				<span class="grey w-70 text-center">{{ 'page.diagram.quadrant.placeholder2' | translate }}</span>
			</div>
			<app-text-button (click)="resetFilters()" class="w-100" type="flat" color="warn">
				{{ 'page.application.table.filters.clear' | translate }}
			</app-text-button>
		</div>
		<div [matMenuTriggerFor]="yAxisMenu" class="p-absolute axis-select select-y flex-space-between pad-top-XS pad-bottom-XS pad-right-S pad-left-S font-MM flex-align-center">
			<span>
				{{ 'page.diagram.quadrant.' + yAxisMetric | translate }}
			</span>
			<img ngSrc="assets/icons/arrow-right-black.svg" style="transform: rotate(90deg)" class="filter-white" alt=""
				 height="12" width="8">
		</div>
		<mat-menu class="quadrant-mat-menu" xPosition="after" #yAxisMenu="matMenu">
			<div class="flex-column" style="min-width: 200px;">
				<div class="c-p app-option flex-space-between flex-align-center"
					 (click)="changeAxisMetric('y', metric.value)"
					 *ngFor="let metric of metricYChoices">
					<span>{{ 'page.diagram.quadrant.' + metric.value | translate }}</span>
					<img ngSrc="assets/icons/check-purple.svg" *ngIf="metric.value === yAxisMetric" alt=""
						 height="20" width="20">
				</div>
			</div>
		</mat-menu>
		<div [matMenuTriggerFor]="xAxisMenu" class="p-absolute axis-select select-x flex-space-between pad-top-XS pad-bottom-XS pad-right-S pad-left-S font-MM flex-align-center">
			<span>
				{{ 'page.diagram.quadrant.' + xAxisMetric | translate }}
			</span>
			<img ngSrc="assets/icons/arrow-right-black.svg" style="transform: rotate(90deg)" class="filter-white" alt=""
				 height="12" width="8">
		</div>
		<mat-menu class="quadrant-mat-menu" xPosition="after" #xAxisMenu="matMenu">
			<div class="flex-column" style="min-width: 200px;">
				<div class="c-p app-option flex-space-between flex-align-center"
					 (click)="changeAxisMetric('x', metric.value)"
					 *ngFor="let metric of metricXChoices">
					<span>{{ 'page.diagram.quadrant.' + metric.value | translate }}</span>
					<img ngSrc="assets/icons/check-purple.svg" *ngIf="metric.value === xAxisMetric" alt=""
						 height="20" width="20">
				</div>
			</div>
		</mat-menu>
		<div class="p-absolute center-line-vertical"></div>
		<div class="p-absolute center-line-horizontal"></div>
		<i class="p-absolute arrow arrow-up"></i>
		<div class="p-absolute y-axis"></div>
		<div class="p-absolute x-axis"></div>
		<i class="p-absolute arrow arrow-right"></i>

		<!-- Apps -->
		<div class="p-absolute"
			 (click)="openApplicationDetails(app.application.application)"
			 [tooltipTriggerFor]="appTooltip" tooltipPosition="right"
			 [tooltipOffset]="10"
			 *ngFor="let app of displayedApplications"
			 [style.left.px]="app.xPosition"
			 [style.bottom.px]="app.yPosition">
			<div class="flex-center mini-app">
				<app-display-logo [logo]="app.application.application.logo"
								  [size]="38">
				</app-display-logo>
			</div>
			<div *ngIf="app.application.application.criticality" class="criticality-circle p-absolute flex-center c-p" style="right: -5px; bottom: -5px" [ngClass]="'criticality-circle-' + app.application.application.criticality.toLowerCase()">
				<img src="assets/icons/criticality-{{app.application.application.criticality.toLowerCase()}}.svg" height="12" alt="">
			</div>
			<app-tooltip #appTooltip>
				<div style="width: 250px;">
					<div class="flex-space-between">
						<span>{{ 'page.diagram.quadrant.tooltip.' + app.xLabel | translate }}</span>
						<span *ngIf="app.xLabel === AxisChoice.cost" class="fw-700">{{ app.xValue | cost }}</span>
						<span *ngIf="app.xLabel === AxisChoice.health && app.xValue !== 0" class="fw-700">{{ app.xValue | number:'1.0-2' }}%</span>
						<span *ngIf="app.xLabel === AxisChoice.health && app.xValue === 0" class="fw-700">-</span>
						<span *ngIf="app.xLabel === AxisChoice.performance" class="fw-700">{{ app.xValue / 1000 | number:'1.0-2' }}s</span>
						<span *ngIf="app.xLabel === AxisChoice.usage" class="fw-700">{{ app.xValue }}</span>
					</div>
					<div class="flex-space-between">
						<span>{{ 'page.diagram.quadrant.tooltip.' + app.yLabel | translate }}</span>
						<span *ngIf="app.yLabel === AxisChoice.cost" class="fw-700">{{ app.yValue | cost }}</span>
						<span *ngIf="app.yLabel === AxisChoice.health && app.yValue !== 0" class="fw-700">{{ app.yValue | number:'1.0-2' }}%</span>
						<span *ngIf="app.yLabel === AxisChoice.health && app.yValue === 0" class="fw-700">-</span>
						<span *ngIf="app.yLabel === AxisChoice.performance" class="fw-700">{{ app.yValue / 1000 | number:'1.0-2' }}s</span>
						<span *ngIf="app.yLabel === AxisChoice.usage" class="fw-700">{{ app.yValue }}</span>
					</div>
				</div>
			</app-tooltip>
		</div>

		<!-- Clusters -->
		<div class="p-absolute"
			 *ngFor="let appCluster of displayedApplicationsCluster"
			 [style.left.px]="appCluster.xPosition"
			 [style.bottom.px]="appCluster.yPosition">
			<div class="flex-center app-cluster" [matMenuTriggerFor]="clusterMenu">
				<span class="bold accent-secondary">{{ appCluster.applications.length }}</span>
			</div>
			<mat-menu class="quadrant-mat-menu" xPosition="after" #clusterMenu="matMenu">
				<div class="flex-column" style="min-width: 200px;">
					<div [tooltipTriggerFor]="appClusterTooltip" tooltipPosition="right"
						 [tooltipOffset]="10" class="c-p app-option"
						 *ngFor="let app of appCluster.applications"
						 (click)="openApplicationDetails(app.application.application)">
						<app-display-application [application]="app.application.application"></app-display-application>
						<app-tooltip #appClusterTooltip>
							<div style="width: 250px;">
								<div class="flex-space-between">
									<span>{{ 'page.diagram.quadrant.tooltip.' + app.xLabel | translate }}</span>
									<span *ngIf="app.xLabel === AxisChoice.cost" class="fw-700">{{ app.xValue | cost }}</span>
									<span *ngIf="app.xLabel === AxisChoice.health && app.xValue !== 0" class="fw-700">{{ app.xValue | number:'1.0-2' }}%</span>
									<span *ngIf="app.xLabel === AxisChoice.health && app.xValue === 0" class="fw-700">-</span>
									<span *ngIf="app.xLabel === AxisChoice.performance" class="fw-700">{{ app.xValue / 1000 | number:'1.0-2' }}s</span>
									<span *ngIf="app.xLabel === AxisChoice.usage" class="fw-700">{{ app.xValue }}</span>
								</div>
								<div class="flex-space-between">
									<span>{{ 'page.diagram.quadrant.tooltip.' + app.yLabel | translate }}</span>
									<span *ngIf="app.yLabel === AxisChoice.cost" class="fw-700">{{ app.yValue | cost }}</span>
									<span *ngIf="app.yLabel === AxisChoice.health && app.yValue !== 0" class="fw-700">{{ app.yValue | number:'1.0-2' }}%</span>
									<span *ngIf="app.yLabel === AxisChoice.health && app.yValue === 0" class="fw-700">-</span>
									<span *ngIf="app.yLabel === AxisChoice.performance" class="fw-700">{{ app.yValue / 1000 | number:'1.0-2' }}s</span>
									<span *ngIf="app.yLabel === AxisChoice.usage" class="fw-700">{{ app.yValue }}</span>
								</div>
							</div>
						</app-tooltip>
					</div>
				</div>
			</mat-menu>
		</div>
	</div>
</div>

<div *ngIf="displayProgressBar" class="p-absolute" style="left: 37%; width: 25%; top: 50vh;">
	<app-progress-bar-loader [loadingTime]="1000" (onFinish)="displayProgressBar = false"></app-progress-bar-loader>
</div>

<div class="no-app-background" *ngIf="!_loading && isEmpty && !displayProgressBar" style="height: calc(100% - 162px);">
	<div class="h-100 flex-justify-center flex-align-center flex-column gap-20">
		<span class="font-XL fw-700 text-center primary">{{ 'page.diagram.applicationMatrix.noData.1' | translate }}</span>
		<span class="font-L fw-500 text-center primary" style="line-height: 1.6rem;">
            {{ 'page.diagram.applicationMatrix.noData.2' | translate }}
			<span class="fw-700">{{ 'page.diagram.flowDiagram.noApplication.3' | translate }}</span>
			{{ 'page.diagram.applicationMatrix.noData.4' | translate }}
			<span class="fw-700">{{ 'page.diagram.applicationMatrix.noData.5' | translate }}</span>
            <br>
            <span class="fw-700">{{ 'page.diagram.applicationMatrix.noData.6' | translate }}</span>
			{{ 'page.diagram.applicationMatrix.noData.7' | translate }}
        </span>
		<app-text-button type="flat" class="mt-l w-20" style="min-width: 200px;"
						 (click)="navigateToApps()">
			{{ 'global.letsGo' | translate }}
		</app-text-button>
	</div>
</div>
