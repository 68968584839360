import {environment} from "../../../../environments/environment";
import * as AppUrl from "../../../models/home/navigation.model";
import {INFRASTRUCTURE_URL} from "../../../models/home/navigation.model";

export interface MenuEntry {
	id: number;
	title: string;
	icon: string;
	iconHeight: number;
	link: AppUrl.AppRouteUrl | `https://${string}` | `http://${string}`;
	isSelected: boolean;
	noFilter?: boolean;
	indicator?: string;
}

export interface MenuGroup {
	title?: string;
	parent?: ParentMenuGroup;
	entries: MenuEntry[];
}

export interface ParentMenuGroup {
	title: string;
	icon: string;
	iconHeight: number;
	isSelected: boolean;
	noFilter?: boolean;
}

export const menuGroups: MenuGroup[] = [
	{
		entries: [
			{
				id: 4,
				title: 'menu.home',
				icon: '/assets/icons/home.svg',
				iconHeight: 22,
				link: AppUrl.HOME_URL,
				isSelected: false
			}
		]
	},
	{
		parent: {
			title: 'menu.analyze',
			icon: '/assets/icons/analyze.svg',
			iconHeight: 22,
			isSelected: false
		},
		entries: [
			{
				id: 5,
				title: 'menu.operation',
				icon: '/assets/icons/heartbeat-outline.svg',
				iconHeight: 26,
				link: AppUrl.OPERATION_URL,
				isSelected: false
			},
			{
				id: 6,
				title: 'menu.finance',
				icon: '/assets/icons/finance-outline.svg',
				iconHeight: 26,
				link: AppUrl.FINANCE_URL,
				isSelected: false
			},
			{
				id: 7,
				title: 'menu.usage',
				icon: '/assets/icons/usage-outline.svg',
				iconHeight: 18,
				link: AppUrl.USAGE_URL,
				isSelected: false
			},
			{
				id: 12,
				title: 'Architecture',
				icon: '/assets/icons/compass.svg',
				iconHeight: 20,
				link: AppUrl.ARCHITECTURE_URL,
				isSelected: false
			}
		]
	},
	{
		entries: [
			{
				id: 1,
				title: 'menu.organization',
				icon: '/assets/icons/menu-orga-outline.svg',
				iconHeight: 20,
				link: AppUrl.ORGANIZATION_URL,
				isSelected: false
			}
		]
	},
	{
		entries: [
			{
				id: 3,
				title: 'menu.applications',
				icon: '/assets/icons/grid.svg',
				iconHeight: 22,
				link: AppUrl.APPLICATIONS_URL,
				isSelected: false
			},
		]
	},
	{
		entries: [
			{
				id: 2,
				title: 'menu.infrastructure',
				icon: '/assets/icons/menu-infra-outline.svg',
				iconHeight: 20,
				link: AppUrl.INFRASTRUCTURE_URL,
				isSelected: false
			}
		]
	},
	{
		entries: [
			{
				id: 8,
				title: 'menu.diagrams',
				icon: '/assets/icons/schemas.svg',
				iconHeight: 22,
				link: AppUrl.DIAGRAMS_URL,
				isSelected: false
			}
		]
	},
	{
		entries: [
			{
				id: 13,
				title: 'menu.extension',
				icon: '/assets/icons/extension.svg',
				iconHeight: 22,
				link: AppUrl.EXTENSION_URL,
				isSelected: false
			}
		]
	},
	{
		entries: [
			{
				id: 10,
				title: 'menu.support',
				icon: '/assets/icons/support-outline.svg',
				noFilter: true,
				iconHeight: 22,
				link: AppUrl.INTERCOM_URL,
				isSelected: false
			},
			{
				id: 9,
				title: 'menu.settings',
				icon: '/assets/icons/menu-gear-outline.svg',
				iconHeight: 20,
				link: AppUrl.SETTINGS_URL,
				isSelected: false
			},
		]
	}
];
