import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {TenantAccount} from "../../../../../../services/model/account.model";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter, map} from "rxjs/operators";
import {TenantService} from "../../../../../../services/tenant.service";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../../../services/front/organization-detail.service";
import {
	Organization,
	OrganizationService
} from "../../../../../../services/organization.service";
import {TenantAccountService} from "../../../../../../services/back/tenant-account.service";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-team-responsible',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, DeckContentLoaderModule, ContentLoaderModule, TranslateModule],
  templateUrl: './team-responsible.component.html',
  styleUrl: './team-responsible.component.scss',
})
export class TeamResponsibleComponent implements OnInit, OnDestroy {
	subscription: Subscription = new Subscription();

	_initializing: boolean;
	_loading: boolean;

	initialized: boolean = false;

	hovered: boolean = false;

	tenantAccounts: TenantAccount[] = [];
	tenantAccountFilteredList: TenantAccount[] = [];
	responsibles: TenantAccount[] = [];

	validators: Validators[] = [
		Validators.required,
		Validators.minLength(1),
		Validators.email
	];

	responsiblesControl: FormControl<TenantAccount[]> = new FormControl([], { nonNullable: true });
	searchUserControl: FormControl<string|null> = new FormControl('');

	tenantId: string;
	isEditor: boolean;
	organization: Organization;
	isTenantInactivePlan: boolean;

	constructor(protected tenantService: TenantService,
				protected organizationDetailService: OrganizationDetailService,
				protected tenantAccountService: TenantAccountService,
				protected organizationService: OrganizationService,
				protected snackbar: SnackbarService,
				protected translate: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap((data) => this.setOrganizationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setOrganizationDetailData(data: OrganizationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.organization = data.organization;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId),
					this.organizationService.getOrganizationResponsibles(this.tenantId, this.organization.organizationId)
				])),
				tap(([usersList, responsibles]) => this.setData(usersList, responsibles)),
				finalize(() => this.switchLoading())
			).subscribe(() => this.initialized = true));

		this.subscription.add(this.responsiblesControl.valueChanges
			.pipe(
				tap((value: TenantAccount[]) => {
					const toAdd = value.filter(user => !this.responsibles.find(responsible => responsible.account.accountId === user.account.accountId));
					const toRemove = this.responsibles.filter(responsible => !value.find(user => user.account.accountId === responsible.account.accountId));
					toAdd.forEach(user => this.addResponsible(user));
					toRemove.forEach(user => this.removeResponsible(user));
				})
			).subscribe());

		this.subscription.add(this.searchUserControl.valueChanges
			.pipe(
				tap(value => this.tenantAccountFilteredList = this.tenantAccounts.filter(user => {
					const search = value?.toLowerCase() || '';
					return user.account.firstName.toLowerCase().includes(search)
						|| user.account.lastName.toLowerCase().includes(search)
						|| user.account.email.toLowerCase().includes(search);
				}))
			).subscribe());
	}

	setData(users: TenantAccount[], responsibles: string[]): void {
		this.tenantAccountFilteredList = users
		this.tenantAccounts = users
		this.responsibles = users.filter(user => responsibles.includes(user.account.accountId))
		this.responsiblesControl.setValue(this.responsibles, {emitEvent: false});
	}

	onInviteResponsible(value: { value: string, toggle: boolean }): void {
		const existingUser = this.tenantAccounts.find(user => user.account.email === value.value);
		if (!existingUser) {
			this.tenantService.inviteUsers(this.tenantId, [{email: value.value, grantAccess: value.toggle}])
				.pipe(
					switchMap(() => this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId)),
					tap(users => {
						this.tenantAccounts = users
							.sort((a, b) => `${a.account.firstName} ${a.account.lastName}`.localeCompare(`${b.account.firstName} ${b.account.lastName}`))
							.sort((a, b) => a.pending ? 1 : -1)

						this.tenantAccountFilteredList = this.tenantAccounts;
					}),
					map(() => this.tenantAccounts.find(user => user.account.email === value.value)),
					filter(user => !!user),
					tap(user => this.addResponsible(user!)))
				.subscribe();
		} else {
			this.addResponsible(existingUser);
		}
	}

	addResponsible(user: TenantAccount): void {
		if (this.responsibles.find(responsible => responsible.account.accountId === user.account.accountId)) {
			this.snackbar.show(this.translate.instant('page.organization.overview.teamManagerAdded'));
		} else {
			this.organizationService.addOrganizationResponsible(this.tenantId, this.organization.organizationId, user.account.accountId)
				.pipe(
					filter(success => success),
					tap(() => this.snackbar.show(this.translate.instant('page.organization.overview.teamManagerAdded'))),
				).subscribe(() => {
				this.responsibles = [...this.responsibles, user];
				this.responsiblesControl.setValue(this.responsibles);
				this.organizationDetailService.refreshOrganization()
			})
		}
	}

	removeResponsible(user: TenantAccount): void {
		this.organizationService.removeOrganizationResponsible(this.tenantId, this.organization.organizationId, user.account.accountId)
			.pipe(
				filter(success => success),
				tap(() => this.snackbar.show(this.translate.instant('page.organization.overview.teamManagerRemoved'))),
			).subscribe(() => {
				this.responsibles = this.responsibles.filter(responsible => responsible.account.accountId !== user.account.accountId);
				this.responsiblesControl.setValue(this.responsibles);
				this.organizationDetailService.refreshOrganization()
			})
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	getInvertedIndex(index: number): number {
		const maxIndex = this.responsiblesControl.value.length - 1;
		return maxIndex - index;
	}
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

export interface TeamResponsibleData {
	data: TenantAccount[];
	selected: TenantAccount[];
}
