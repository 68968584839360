<div *ngIf="initialized"
	 (mouseenter)="hovered = true"
	 (mouseleave)="hovered = false"
	 class="flex-space-between pad-M">
	<div class="flex-column gap-S w-60">
		<div class="flex-align-center gap-S">
			<div class="font-MM fw-700">{{ 'page.appDetails.overview.responsible' | translate }}</div>
			<img [src]="applicationResponsiblesControl.value.length >= 1 ? 'assets/icons/check-circle-green.svg' : 'assets/icons/check-circle.svg'" height="14px" alt="">
		</div>
		<span>{{ 'page.appDetails.overview.responsibleSubtitle' | translate }}</span>
	</div>
	<div class="flex-align-center gap-S">
		<div class="flex-align-center translate-transition gap-S" style="z-index: 1" [style.transform]="!hovered && applicationResponsiblesControl.value.length < 4 ? 'translateX(50px)' : undefined">
			<div class="p-relative translate-transition" *ngFor="let user of applicationResponsiblesControl.value; let index = index"
				 [style.z-index]="getInvertedIndex(index)"
				 [style.transform]="index + 1 < applicationResponsiblesControl.value.length && !hovered ? 'translateX('+ getInvertedIndex(index) * 25 +'px)' : undefined">
				<div [style.opacity]="hovered ? 1 : 0" (click)="removeResponsible(user)" class="delete-responsible-btn">
					<img width="12" src="assets/icons/cross-black.svg" class="filter-white">
				</div>
				<app-display-user-logo [tooltipPosition]="'left'"
									   [tooltipTriggerFor]="responsibleTooltip"
									   style="cursor:default;"
									   [size]="40"
									   [fontSize]="16"
									   [user]="user"></app-display-user-logo>
				<app-tooltip #responsibleTooltip>
					<div class="flex-column" style="gap: 2px" *ngIf="user.account.email !== user.account.firstName && user.account.email !== user.account.lastName">
						<span class="fw-700">{{ user.account.firstName }} {{ user.account.lastName }}</span>
						<span class="fw-400">{{ user.account.email }}</span>
					</div>
					<div class="flex-column" style="gap: 2px" *ngIf="user.account.email === user.account.firstName || user.account.email === user.account.lastName">
						<span class="fw-400">{{ user.account.email }}</span>
					</div>
				</app-tooltip>
			</div>
		</div>
		<app-multi-select *ngIf="applicationResponsiblesControl.value.length < 4"
						  [addMinHeight]="true"
						  class="w-100"
						  [inputControl]="applicationResponsiblesControl"
						  multiple
						  [confirmButton]="false"
						  [disabled]="_initializing || _loading">
			<div select-trigger class="flex-align-center flex-justify-end h-100">
				<div class="add-responsible-no-selected">
					<img src="assets/icons/add.svg" class="filter-grey">
				</div>
			</div>
			<app-select-search [searchControl]="searchUserControl"></app-select-search>
			<app-select-insert [validators]="validators" placeholder="global.inputEmailAddress" [toggleText]="'global.addsToggles.responsible'" [withToggle]="true" [titlePath]="'global.adds.responsible'" (createWithToggle)="onInviteResponsible($event)"></app-select-insert>
			<app-select-option *ngFor="let user of tenantAccountFilteredList" [value]="user">
				<app-display-user [user]="user"></app-display-user>
			</app-select-option>
		</app-multi-select>
	</div>
</div>

<div class="pad-M" *ngIf="!initialized">
	<list-content-loader></list-content-loader>
</div>
