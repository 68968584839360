import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {AllActivityLog, ApplicationActivityLog, ServerActivityLog} from 'src/app/services/model/activity-log.model';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';

@Injectable()
export class ActivityLogService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/activity-logs`;

	constructor(protected httpClient: HttpClient) {
	}

	public getAllTenantActivityLog(tenantId: string, limit: number = 5, offset: number = 0): Observable<ApplicationActivityLog[]> {
		return this.httpClient.get<ApplicationActivityLog[]>(`${this.serviceUrl(tenantId)}/applications`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: new HttpParams().append('limit', limit).append('offset', offset)
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	public getAllApplicationActivityLog(tenantId: string, applicationId: string, limit: number = 5, offset: number = 0): Observable<ApplicationActivityLog[]> {
		return this.httpClient.get<ApplicationActivityLog[]>(`${this.serviceUrl(tenantId)}/applications/${applicationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: new HttpParams().append('limit', limit).append('offset', offset)
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	public getAllServerActivityLog(tenantId: string, serverId: string, limit: number = 5, offset: number = 0): Observable<ServerActivityLog[]> {
		return this.httpClient.get<ApplicationActivityLog[]>(`${this.serviceUrl(tenantId)}/servers/${serverId}`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: new HttpParams().append('limit', limit).append('offset', offset)
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}
}
