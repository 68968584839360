import {Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Organization, OrganizationService} from "../../../../../services/organization.service";
import {ApplicationGeneric, Health} from "../../../../../services/model/new-application.model";
import {Cost} from "../../../../../services/model/application-contract.model";
import {finalize, forkJoin, Observable, of, Subscription, switchMap} from "rxjs";
import {
	OrganizationDetailData,
	OrganizationDetailService
} from "../../../../../services/front/organization-detail.service";
import {filter, map, tap} from "rxjs/operators";
import {Rating} from "../../../../../services/usage.service";
import {ApplicationMiniWidgetModule} from "../../../../global/application-mini-widget/application-mini-widget.module";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {TendencyWidgetContent} from "../../../../global/application-mini-widget/application-mini-widget.component";
import {ColumnDefinition, TableComponent} from "../../../../design-system/table/table.component";
import {CriticalityLevel} from "../../../../../services/tenant.service";
import {ChipsModule} from "../../../../global/chips/chips/chips.module";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {
	ApplicationDetailComponent,
	ApplicationDetailInput
} from "../../../applications/application-detail/application-detail.component";
import {RightSliderService} from "../../../../../services/front/right-slider.service";
import {OrganizationType} from "../../../../../services/model/autodiscover.model";
import {MiniButtonModule} from "../../../../global/button/mini-button/mini-button.module";
import {AddAppsToTeamComponent, AddAppsToTeamDialogData} from "./add-apps-to-team/add-apps-to-team.component";
import {NewApplicationService} from "../../../../../services/back/new-application.service";
import {MatDialog} from "@angular/material/dialog";
import {SnackbarService} from "../../../../../services/front/snackbar.service";
import {ConfirmComponent} from "../../../../global/dialog/confirm/confirm.component";

@Component({
	selector: 'app-organization-applications-tab',
	standalone: true,
	imports: [CommonModule, ApplicationMiniWidgetModule, MatIconModule, TranslateModule, ChipsModule, DesignSystemModule, TableComponent, MiniButtonModule],
	templateUrl: './organization-applications-tab.component.html',
	styleUrl: './organization-applications-tab.component.scss'
})
export class OrganizationApplicationsTabComponent implements OnInit, OnDestroy {
	@Output()
	autodiscoveredApplicationsChanged: EventEmitter<number> = new EventEmitter<number>();

	@Output()
	autodiscoveredApplicationsSeen: EventEmitter<void> = new EventEmitter<void>();

	tenantId: string;
	isEditor: boolean;
	organization: Organization;
	isTenantInactivePlan: boolean;

	_initializing: boolean;
	_loading: boolean = false;
	initialized: boolean = true;

	data?: OrganizationApplicationsData;

	autodiscoveredApplications: ApplicationGeneric[] = [];

	allTenantApplications: ApplicationGeneric[];

	availabilityWidget: TendencyWidgetContent|undefined;
	tcoWidget: TendencyWidgetContent|undefined;
	performanceWidget: TendencyWidgetContent|undefined;

	columnDefinitions: ColumnDefinition<OrganizationApplication>[];

	@ViewChild('applicationTemplate', { static: true }) applicationTemplate: TemplateRef<any>;
	@ViewChild('healthTemplate', { static: true }) healthTemplate: TemplateRef<any>;
	@ViewChild('criticalityTemplate', { static: true }) criticalityTemplate: TemplateRef<any>;
	@ViewChild('satisfactionTemplate', { static: true }) satisfactionTemplate: TemplateRef<any>;

	subscription: Subscription = new Subscription();

	constructor(private organizationDetailService: OrganizationDetailService,
				private organizationService: OrganizationService,
				private rightSliderService: RightSliderService,
				private newApplicationService: NewApplicationService,
				private dialog: MatDialog,
				private snackbarService: SnackbarService,
				private translate: TranslateService) {
	}

	ngOnInit() {
		this.subscription.add(this.organizationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.organizationDetailService.getOrganizationDetailDataChanges()
			.pipe(tap((data) => this.setOrganizationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setOrganizationDetailData(data: OrganizationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.organization = data.organization;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.setColumnDefinitions()),
				switchMap(() => forkJoin([
					this.organizationService.getOrganizationApplicationsData(this.tenantId, this.organization.organizationId),
					this.organizationService.getOrganizationAutodiscoveredApplications(this.tenantId, this.organization.organizationId)
				])),
				tap(([data, autodiscoveredApps]) => {
					this.setData(data, autodiscoveredApps)
					this.autodiscoveredApplicationsSeen.emit()
					this.autodiscoveredApplicationsChanged.emit(autodiscoveredApps.length)
				}),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	setColumnDefinitions(): void {
		this.columnDefinitions = [
			{
				name: 'application',
				label: 'global.table.application.name',
				template: this.applicationTemplate,
				sortFunction: (a, b) => a.application.name.localeCompare(b.application.name),
				width: this.organization.type === OrganizationType.BUSINESS_UNIT ? 25 : 31,
			},
			{
				name: 'health',
				label: 'global.table.application.health',
				template: this.healthTemplate,
				sortFunction: (a, b) => (a.health?.percent || 100) - (b.health?.percent || 100),
				width: this.organization.type === OrganizationType.BUSINESS_UNIT ? 25 : 23,
			},
			{
				name: 'application.criticality',
				label: 'global.table.application.criticality',
				template: this.criticalityTemplate,
				sortFunction: (a, b) => {
					const order: (CriticalityLevel|null|undefined)[] = [CriticalityLevel.HIGH, CriticalityLevel.MEDIUM, CriticalityLevel.LOW, null, undefined];
					return order.indexOf(a.application.criticality) - order.indexOf(b.application.criticality);
				},
				width: this.organization.type === OrganizationType.BUSINESS_UNIT ? 25 : 23,
			},
			{
				name: 'satisfaction',
				label: 'global.table.application.satisfaction',
				template: this.satisfactionTemplate,
				sortFunction: (a, b) => {
					const satisfactionA = a.satisfaction?.value || 0;
					const satisfactionB = b.satisfaction?.value || 0;
					return satisfactionA - satisfactionB;
				},
				width: this.organization.type === OrganizationType.BUSINESS_UNIT ? 25 : 23,
			}
		]
	}

	onRowClick(event: OrganizationApplication): void {
		this.goToApp(event.application.id);
	}

	deleteAppFromTeam(application: OrganizationApplication): void {
		const data = {
			title: '',
			message: this.translate.instant('confirmModal.removeAppFromTeam'),
			confirmButton: this.translate.instant('button.delete'),
			closeButton: this.translate.instant('button.cancel')
		};
		this.dialog.open(ConfirmComponent, {data: data})
			.afterClosed()
			.pipe(
				filter(result => result),
				tap(),
				switchMap(() => this.organizationService.changeTeamApplications(this.tenantId, this.organization.organizationId, [{appId: application.application.id, checked: false}])))
			.subscribe(() => {
				this.organizationDetailService.refreshOrganization()
				this.snackbarService.show(this.translate.instant('global.announcements.announcementDeleted'))
			}, () => {
				this.snackbarService.show(this.translate.instant('global.announcements.error'), 'danger-snack')
			});
	}

	openLinkAppToTeam() {
		of(this.allTenantApplications)
			.pipe(
				switchMap(apps => !apps?.length
					? this.newApplicationService.getAllApplication(this.tenantId)
					: of(apps)),
				tap(apps => this.allTenantApplications = apps),
				map(apps => this.buildAddAppsToTeamDialogData(apps)),
				switchMap(data => this.dialog.open(AddAppsToTeamComponent, { position: { right: '162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(result => result && result.success),
				tap(() => this.snackbarService.show(this.translate.instant('page.organization.appsSuccessfullyAdded'))))
			.subscribe(() => this.organizationDetailService.refreshOrganization());
	}

	private buildAddAppsToTeamDialogData(apps: ApplicationGeneric[]): { organizationId: string, apps: AddAppsToTeamDialogData[] } {
		return {
			organizationId: this.organization.organizationId,
			apps: apps.map(r => ({
				id: r.id,
				name: r.name,
				logo: r.logo,
				selected: this.data ? this.data?.applications.some(la => la.application.id === r.id) : false,
				autodiscovered: this.autodiscoveredApplications.some(a => a.id === r.id)
			}))
		}
	}

	goToApp(applicationInstanceId: string): void {
		const data: ApplicationDetailInput = {
			applicationId: applicationInstanceId
		};
		this.rightSliderService.openComponent(ApplicationDetailComponent, data)
			.subscribe();
	}

	setData(data: OrganizationApplicationsData, autodiscoveredApps: ApplicationGeneric[]): void {
		this.data = data;
		this.autodiscoveredApplications = autodiscoveredApps;
		this.data.applications.sort((a, b) => a.application.name.localeCompare(b.application.name));
		this.availabilityWidget = {
			value: data.health.percent,
			day: data.health.queryType
		};
		this.tcoWidget = {
			value: data.cost.value,
			day: data.cost.queryType
		};
		this.performanceWidget = {
			value: data.performance,
			day: data.health.queryType
		};
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly OrganizationType = OrganizationType;
}

export interface OrganizationApplicationsData {
	health: Health;
	cost: Cost;
	performance?: number;
	applications: OrganizationApplication[];
}

export interface OrganizationApplication {
	application: ApplicationGeneric,
	health?: Health,
	satisfaction?: Rating
}
