<div *ngIf="users && users.length > 0 && !incognito" class="flex-column gap-S">
	<div class="flex-align-center flex-space-between">
		<div class="flex-align-center gap-S">
			<div class="font-MM fw-700">{{ 'page.organization.overview.users' | translate }}</div>
			<div class="flex-center border-primary-S pad-0-S fw-700" style="border-radius: 12px;">
				{{ getUsersCount(users) }}
			</div>
		</div>
		<div *ngIf="users.length > 0" class="flex-align-center gap-M">
			<div class="flex-align-center search-bar-container bkg-white b-radius-9">
				<app-text-button type="default"
								 color="primary"
								 class="search-btn"
								 panelClass="h-100-imp pad-0-imp"
								 (click)="openSearchPanel(input)">
					<div class="flex-center">
						<img src="assets/icons/search-md.svg" width="16" height="16" alt=""/>
					</div>
				</app-text-button>
				<div class="flex-space-between">
					<input #input class="search-input" [ngClass]="{'display-input': openSearch}"
						   [formControl]="searchUserFormControl"
						   [placeholder]="openSearch ? ('page.appDetails.usage.searchUser'|translate) : ''">
					<app-text-button (click)="clearInput()" *ngIf="openSearch && searchValue" type="default"
									 color="primary" panelClass="h-100-imp pad-0-imp" class="delete-text-button">
						<div class="flex-center">
							<img src="assets/icons/close.svg" alt="">
						</div>
					</app-text-button>
					<div *ngIf="openSearch && !searchValue" style="width: 30px; height: 30px;">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngFor="let user of filteredUsers" class="user-card">
		<div class="pl-s pr-m pt-s pb-s flex-space-between flex-align-center gap-S">
			<div class="flex-align-center flex-space-between w-100">
				<div class="disp-f justify-center a-i-c gap-S"
					 [style.filter]="isInactiveFor30Days(user.lastActivity) ? 'opacity(0.4)' : undefined">
					<div class="list-icon flex-center p-relative" style="border-radius: 50%;">
						<img *ngIf="(user.userName || user.deviceName) && !isInactiveFor30Days(user.lastActivity)"
							 src="assets/icons/userss.svg" class="filter-accent" alt="">
						<img *ngIf="(user.userName || user.deviceName) && isInactiveFor30Days(user.lastActivity)"
							 src="assets/icons/inactive-user.svg" class="filter-accent" alt="">
						<img *ngIf="!user.userName && !user.deviceName" src="assets/icons/anonymous-user.svg"
							 class="filter-accent" alt="">
					</div>
					<div class="flex-justify-center flex-column" style="max-width: 300px">
						<div *ngIf="user.userName" class="title-user text-overflow-ellipsis">{{ user.userName }}</div>
						<div *ngIf="user.deviceName"
							 class="grey subtitle-user text-overflow-ellipsis">{{ user.deviceName }}
						</div>
						<div *ngIf="!user.userName && !user.deviceName"
							 class="title-user text-overflow-ellipsis">{{ 'page.appDetails.usage.anonymousUser' | translate }}
						</div>
					</div>
				</div>
				<div class="inactive-bubble active"
					 *ngIf="!isInactiveFor30Days(user.lastActivity) && !isInactiveFor10Days(user.lastActivity)">
					{{ 'page.appDetails.usage.active' | translate }}
				</div>
				<div class="inactive-bubble inactive-30-days" *ngIf="isInactiveFor30Days(user.lastActivity)">
					{{ 'page.appDetails.usage.inactiveFor30Days' | translate }}
				</div>
				<div class="inactive-bubble inactive-10-days" *ngIf="isInactiveFor10Days(user.lastActivity)">
					{{ "page.appDetails.usage.inactiveForXDays" | translate: {days: getDiffDays(user.lastActivity)} }}
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="filteredUsers.length <= 0" class="flex-center flex-column gap-L" style="height: 200px;">
		<img class="mr-xs" src="assets/illustrations/no_announcement.svg" height="100" alt="">
		<span class="font-M fw-500 grey">{{ 'page.organization.overview.noUserSearch' | translate }}</span>
	</div>
</div>
<div *ngIf="users && users.length <= 0 && !incognito" class="flex-column gap-L">
	<div class="flex-align-center flex-space-between">
		<span class="font-MM fw-700">{{ 'page.organization.overview.users' | translate }}</span>
		<span class="font-MM fw-700">-</span>
	</div>
	<div class="no-users-placeholder h-100 flex-center flex-column gap-XL" style="padding: 60px 0;">
		<img src="assets/illustrations/no-users.svg" alt="">
		<span class="text-center w-70 grey font-MM">{{ 'page.organization.overview.incognito' | translate }}</span>
		<button type="button" class="deploy-btn" (click)="redirectToDoc()">
			<img width="20" src="assets/icons/alert-circle.svg" class="filter-grey" alt="">
			<span>{{ 'page.organization.overview.incognitoButton' | translate }}</span>
		</button>
	</div>
</div>
<div *ngIf="incognito" class="flex-column gap-L">
	<div class="flex-align-center flex-space-between">
		<span class="font-MM fw-700">{{ 'page.organization.overview.users' | translate }}</span>
		<span class="font-MM fw-700">-</span>
	</div>
	<div class="no-users-placeholder h-100 flex-center flex-column gap-XL" style="padding: 60px 0;">
		<img src="assets/illustrations/incognito.svg" alt="">
		<span class="text-center w-70 grey font-MM">Pour accéder à cette fonctionnalité, vous devez activer le suivi nominatif dans les paramètres de votre espace</span>
		<app-text-button (click)="goToSettings()" class="w-40" color="accent" type="flat">
			Activer le suivi nominatif
		</app-text-button>
	</div>
</div>
<div *ngIf="!users">
	<app-list-loader></app-list-loader>
</div>
