<div class="pad-L" style="height: 100%; width: 435px; overflow: hidden;">
	<div class="disp-f j-c-s a-i-c">
		<div>
			<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10"
				 style="height: 45px; width: 45px;">
				<img alt="" [src]="type === EquipmentMenu.FIREWALL ? 'assets/icons/firewall.svg' : 'assets/icons/router.svg'" class="svg-24 filter-grey">
			</div>
		</div>
		<div *ngIf="type === EquipmentMenu.ROUTER" class="ml-s flex-column gap-XS">
			<div class="font-L fw-700">{{ 'page.infrastructure.router.addARouter' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.infrastructure.router.addARouterSubtitle' | translate }}</div>
		</div>
		<div *ngIf="type === EquipmentMenu.FIREWALL" class="ml-s flex-column gap-XS">
			<div class="font-L fw-700">{{ 'page.infrastructure.router.addAFirewall' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.infrastructure.router.addAFirewallSubtitle' | translate }}</div>
		</div>
		<app-icon-button (click)="closeDialog(false)" class="ml-auto" [img]="'assets/icons/close.svg'" type="stroked"
						 background [panelClass]="'img-size-30 darken-icon'"></app-icon-button>
	</div>

	<form [formGroup]="formGroup">
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey" *ngIf="type === EquipmentMenu.ROUTER">{{ 'page.infrastructure.router.inputRouterName' | translate }}*</div>
			<div class="font-M fw-500 grey" *ngIf="type === EquipmentMenu.FIREWALL">{{ 'page.infrastructure.router.inputFirewallName' | translate }}*</div>
			<app-text-input *ngIf="type === EquipmentMenu.ROUTER" [input]="nameFormControl"
							[placeholder]="'page.infrastructure.router.inputRouterName' | translate"></app-text-input>
			<app-text-input *ngIf="type === EquipmentMenu.FIREWALL" [input]="nameFormControl"
							[placeholder]="'page.infrastructure.router.inputFirewallName' | translate"></app-text-input>
		</div>
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.infrastructure.router.ipAddress' | translate }}</div>
			<app-text-input [error]="formGroup.hasError('invalidNetworkAddress') && formGroup.dirty"
							[input]="ipAddressFormControl"
							[placeholder]="'page.infrastructure.router.ipAddressPlaceholder' | translate"></app-text-input>
			<span @validators-appear *ngIf="ipAddressFormControl.errors?.ipAddress && formGroup.dirty"
				  class="danger">{{ 'page.infrastructure.overview.ipAddressValidator' | translate }}</span>
		</div>
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.infrastructure.router.location' | translate }}</div>
			<app-text-input [input]="locationFormControl"
							[placeholder]="'page.infrastructure.router.locationPlaceholder' | translate"></app-text-input>
		</div>
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.infrastructure.network.description' | translate }}</div>
			<app-text-area [placeholder]="'page.infrastructure.network.inputDescription' | translate" [rows]="5"
						   [input]="descriptionFormControl"></app-text-area>
		</div>
		<div class="flex gap-M mt-l">
			<app-text-button type="stroked" class="w-50" [disabled]="_saving"
							 (click)="onGoBack.emit()">
				{{ 'page.appDetails.finance.contracts.cancel'|translate }}
			</app-text-button>
			<app-text-button type="flat" class="w-50" [disabled]="formGroup.invalid" [loading]="_saving"
							 (click)="submit()">
				{{ 'page.appDetails.files.dialog.add' | translate }}
			</app-text-button>
		</div>
	</form>
</div>
