import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {finalize, mergeMap, Observable, of, Subscription, takeWhile} from "rxjs";
import {ServerService} from "../../../../../../services/back/server.service";
import {CurrentTenantService} from "../../../../../../services/front/current-tenant.service";
import {filter, first, map, tap} from "rxjs/operators";
import {
	Server,
	ServerInformationForm, ServerOS,
	ServerType
} from "../../../../../../services/model/resource.model";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {ServerDetailData, ServerDetailService} from "../../../../../../services/front/server-detail.service";

@Component({
	selector: 'app-server-settings',
	standalone: true,
	imports: [CommonModule, DesignSystemModule, ReactiveFormsModule, TranslateModule],
	templateUrl: './server-settings.component.html',
	styleUrl: './server-settings.component.scss',
	animations: [
		trigger('validators-appear', [
			transition(':enter', [
				style({opacity: 0, display: 'none', transform: 'translateY(-10px)'}),
				animate('300ms ease-in-out', style({opacity: 1, display: 'block', transform: 'translateY(0)'})),
			])
		])
	]
})
export class ServerSettingsComponent implements OnInit, OnDestroy {
	tenantId: string;
	isEditor: boolean;
	server: Server;

	_initializing: boolean;
	_loading: boolean;
	initialized = false;

	formGroup: FormGroup;
	updateOnBlur = new EventEmitter<Form>();

	subscription: Subscription = new Subscription();

	constructor(private translate: TranslateService,
				private serverService: ServerService,
				private serverDetailService: ServerDetailService,
				private snackbarsService: SnackbarService) {
	}

	ngOnInit() {
		this.buildForm();
		this.subscription.add(this.serverDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.serverDetailService.getServerDetailDataChanges()
			.pipe(tap(data => this.setServerDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.setDefaultData()),
				tap(() => this.fixMarkAsTouched()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	buildForm() {
		this.formGroup = new FormGroup({
			[Form.name]: new FormControl(undefined, [Validators.required])
		});

		this.formGroup.disable();

		this.subscription.add(this.updateOnBlur
			.pipe(filter(() => !this.isFormValid()))
			.subscribe(() => this.setServerFormDefaultData(this.server)));
		this.updateOnBlur
			.pipe(
				filter(formName => this.isFormValid() && this.checkFormChanged(formName)),
				map(() => this.buildServerSettingForm()),
				mergeMap(form => this.serverService.updateServerInformation(this.tenantId, this.server.id, form)),
				tap(updateSucceed => this.displayUpdatedSucceed(updateSucceed)),
				tap(() => this.serverDetailService.refreshServer()),
				takeWhile(() => !this.subscription.closed))
			.subscribe();
	}

	private fixMarkAsTouched(): void {
		Object.values(Form)
			.map(name => this.formGroup.get(name)!)
			.filter(control => !!control.validator)
			.forEach(control => control.valueChanges.pipe(first()).subscribe(() => control.markAsTouched()));
	}

	private setServerDetailData(data: ServerDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.server = data.server;
	}

	private setDefaultData(): void {
		this.setServerFormDefaultData(this.server);
		this.checkFormDisabled();
	}

	private setServerFormDefaultData(data: Server): void {
		this.formGroup.get(Form.name)!.setValue(data.name);
	}

	checkFormDisabled(): void {
		if (!this.isEditor || this.server.automatic) {
			this.formGroup.disable();
		} else {
			this.formGroup.enable();
		}
	}

	isFormValid(): boolean {
		return this.formGroup.dirty && this.formGroup.valid && this.formGroup.enabled;
	}

	checkFormChanged(formName?: Form): boolean {
		return !formName
			? Object.values(Form).map(formName => this.checkForm(formName)).reduce((a, b) => a || b)
			: this.checkForm(formName);
	}

	private checkForm(formName: Form): boolean {
		switch (formName) {
			case Form.name:
				return this.formGroup.get(Form.name)!.value !== this.server.name;
		}
	}

	private buildServerSettingForm(): ServerInformationForm {
		return {
			name: this.formGroup.get(Form.name)!.value,
			ipAddress: this.server.ipAddress || undefined,
			location: this.server.location || undefined,
			manufacturer: this.server.manufacturer || undefined,
			type: this.server.type ? this.getServerType(this.server.type) || undefined : undefined,
			system: this.server.system ? this.getServerSystem(this.server.system) || undefined : undefined,
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackbarsService.show(this.translate.instant('page.application.detail.update.success'));
		} else {
			this.snackbarsService.show(this.translate.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	getServerType(type: string): ServerType {
		return ServerType[type.toUpperCase() as keyof typeof ServerType];
	}

	getServerSystem(system: string): ServerOS {
		return ServerOS[system.toUpperCase() as keyof typeof ServerOS];
	}

	get nameFormControl(): FormControl {
		return this.formGroup.get(Form.name) as FormControl;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly Form = Form;
}

enum Form {
	name = 'name'
}
