import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DiskMetric, StorageMetric} from "../../../../../../../services/model/resource.model";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {BaseChartDirective, NgChartsModule} from "ng2-charts";
import {Chart, ChartArea, ChartData, ChartOptions, ChartType, ScriptableLineSegmentContext} from "chart.js";
import {ColorEnum} from "../../../../../../../../_variables";

@Component({
	selector: 'app-server-storage-graph',
	standalone: true,
	imports: [CommonModule, NgChartsModule, TranslateModule],
	templateUrl: './server-storage-graph.component.html',
	styleUrl: './server-storage-graph.component.scss'
})
export class ServerStorageGraphComponent implements OnInit {

	constructor(protected translate: TranslateService,
				protected cdr: ChangeDetectorRef) {
	}

	@Input() data: StorageMetric[];

	public disks: DiskMetric[] = [];

	public tooltip = {
		display: "none",
		top: "0px",
		left: "0px",
		day: 0,
		month: 0,
		year: 0,
		value: 0,
		disks: this.disks,
		noData: false
	}

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	public chartType: ChartType = 'line';

	public lineChartData: ChartData<'line', {
		id: Date,
		disks: DiskMetric[],
		value: number,
		date: Date,
		noData: boolean
	} []> = {
		labels: [],
		datasets: []
	};

	public lineChartDataI: LineChartDataI[] = []

	public chartOptions: ChartOptions = {
		interaction: {
			intersect: false,
			mode: 'index',
		},
		animation: false,
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false,
				external: context => {
					const {chart, tooltip} = context;

					if (tooltip.opacity === 0) {
						if (this.tooltip.display !== "none") {
							this.tooltip.display = "none"
							this.cdr.detectChanges()
						}
						return
					}
					const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

					const canvasWidth = chart.canvas.getBoundingClientRect().width;

					let xPosition = positionX + tooltip.caretX;
					if (xPosition + 180 > canvasWidth) {
						xPosition = positionX + tooltip.caretX + 180 - canvasWidth
						xPosition = positionX + tooltip.caretX - xPosition
					}

					// @ts-ignore
					const disks: DiskMetric[] = tooltip.dataPoints[0].raw.disks;

					// @ts-ignore
					const noData: boolean = tooltip.dataPoints[0].raw.noData;

					// @ts-ignore
					const date: Date = tooltip.dataPoints[0].raw.id

					const day = date.getDate()

					const month = date.getMonth() + 1

					const year = date.getFullYear()

					const value = Math.floor(tooltip.dataPoints[0].parsed.y * 100) / 100;

					this.tooltip = {
						display: "block",
						left: xPosition + 'px',
						top: positionY + tooltip.caretY + 6 + 'px',
						day: day,
						month: month,
						year: year,
						value: value,
						disks: disks,
						noData: noData
					}

					this.cdr.detectChanges()
				},
			}
		},
		scales: {
			y: {
				suggestedMin: 0,
				suggestedMax: 100,
				display: true,
				grid: {
					lineWidth: 1,
					tickBorderDash: [2, 2],
					drawTicks: false,
					drawBorder: false,
					color: ColorEnum.medium_grey,
				},
				ticks: {
					callback: function (value) {
						if (typeof value == "number" && value % 1 === 0) {
							return value + "%";
						} else {
							return undefined
						}
					},
					padding: 6,
					font: {
						family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
						size: 9
					},
					color: "#1D3B58",
					maxTicksLimit: 5,
					count: 5
				}
			},
			x: {
				ticks: {
					font: {
						family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
						size: 9
					},
					color: "#B5B5C3",
					count: 30,
					maxTicksLimit: 30,
					maxRotation: 0,
					minRotation: 0,
				},
				grid: {
					lineWidth: 0,
					drawBorder: false
				}
			},

		}
	};

	ngOnInit() {
		this.data.forEach((value, index) => {
			const date = new Date(value.timestamp)
			const disks = value.disks
			let noData = true
			disks.forEach((disk, index) => {
				if (disk.load) noData = false
			})
			let max = 0;
			if (disks.length > 0) {
				max = disks.reduce((prev, current) => (prev.load > current.load) ? prev : current).load
			}
			this.lineChartDataI.push({day: date, disks: disks, value: max, noData: noData})
		})

		this.lineChartDataI.sort((a, b) => {
			return a.day.getTime() < b.day.getTime() ? -1 : 1;
		})

		this.onUpdateData();
	}

	getGradient(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
		let gradient;
		gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
		gradient.addColorStop(0, 'rgba(61, 192, 255, 0)');
		gradient.addColorStop(0.5, 'rgba(61, 192, 255, 0.2)');
		gradient.addColorStop(1, 'rgba(61, 192, 255, 0.6)');
		return gradient;
	}

	getGradientUnreachable(ctx: CanvasRenderingContext2D, chartArea: ChartArea) {
		let gradient;
		gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
		gradient.addColorStop(0, 'rgba(181, 181, 195, 0)');
		gradient.addColorStop(0.5, 'rgba(181, 181, 195, 0.2)');
		gradient.addColorStop(1, 'rgba(181, 181, 195, 0.6)');
		return gradient;
	}

	onUpdateData() {
		if (this.lineChartData.datasets && this.lineChartData.datasets[0]) {
			this.lineChartData.datasets = [];
			this.lineChartData.labels = [];
		}
		if (this.data && this.data.length > 0) {
			this.lineChartData.labels = this.lineChartDataI.map(day => day.day.getDate());
			const unreachableDash = (ctx: ScriptableLineSegmentContext, value: number[]) => {
				const index = ctx.p0DataIndex
				if (this.lineChartData.datasets[0].data[index].noData) return value;
				return undefined;
			}
			const unreachableColor = (ctx: ScriptableLineSegmentContext, value: string) => {
				const index = ctx.p0DataIndex
				if (this.lineChartData.datasets[0].data[index].noData) return value;
				return undefined;
			}
			const unreachableBgColor = (context: ScriptableLineSegmentContext, value: CanvasGradient) => {
				const index = context.p0DataIndex
				if (this.lineChartData.datasets[0].data[index].noData) return value;
				return undefined;
			}

			this.lineChartData.datasets.push(
				{
					label: this.translate.instant('page.dashboard.documentation.update'),
					data: this.lineChartDataI.map(data => {
						return {
							id: data.day,
							value: data.value,
							disks: data.disks,
							date: data.day,
							noData: data.noData
						}
					}),
					parsing: {
						xAxisKey: 'id',
						yAxisKey: 'value'
					},
					tension: 0.3,
					fill: true,
					pointStyle: "circle",
					pointRadius: 0,
					pointBorderColor: (context) => {
						const index = context.dataIndex;
						if (this.lineChartData.datasets[0].data[index].noData) return "#B5B5C3"
						return "#3DC0FF"
					},
					pointBorderWidth: 1.5,
					pointBackgroundColor: "#FFFFFF",
					pointHoverBackgroundColor: (context) => {
						const index = context.dataIndex;
						if (this.lineChartData.datasets[0].data[index].noData) return "#B5B5C3"
						return "#3DC0FF"
					},
					pointHoverRadius: 6,
					pointHoverBorderColor: (context) => {
						const index = context.dataIndex;
						if (this.lineChartData.datasets[0].data[index].noData) return "#B5B5C3"
						return "#3DC0FF"
					},
					borderWidth: 1.5,
					segment: {
						borderDash: context => unreachableDash(context, [5, 5]) || [5, 0],
						borderColor: context => unreachableColor(context, "#B5B5C3") || "#3DC0FF",
						backgroundColor: (context) => {
							const {ctx, chartArea} = (context as unknown as { chart: Chart }).chart;
							return unreachableBgColor(context, this.getGradientUnreachable(ctx, chartArea)) || this.getGradient(ctx, chartArea)
						}
					}
				}
			)
		}

		if (this.chart) {
			this.chart.update();
		}
	}
}


export interface LineChartDataI {
	day: Date,
	disks: DiskMetric[]
	value: number,
	noData: boolean
}
