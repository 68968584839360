<div class="flex-column gap-S">
	<div class="flex-align-center flex-space-between">
		<div class="font-MM fw-700">{{ 'page.infrastructure.network.network' | translate }}</div>
		<app-multi-select [customTrigger]="true" [inputControl]="networkFormControl"
						  [disabled]="_saving || _loading">

			<app-mini-button custom-trigger size="s">
				<img src="assets/icons/add.svg" class="filter-accent" alt="">
			</app-mini-button>

			<app-select-option *ngFor="let network of networks"
							   [value]="network">{{ network.name }}
			</app-select-option>
		</app-multi-select>
	</div>
	<div *ngIf="network" class="network-card">
		<div (click)="goToNetwork(network)"
			 class="pl-s pr-m pt-s pb-s flex-space-between flex-align-center gap-S c-p greyed-hover-2">
			<div class="flex-align-center c-p" style="gap: 60px">
				<div style="user-select: none" class="flex-center gap-S">
					<div class="network-icon flex-center" style="width: 38px; height: 38px">
						<img src="assets/icons/network.svg" width="24" class="filter-accent-secondary">
					</div>
					<div class="flex-justify-center flex-column gap-XS" style="width: 130px">
						<div class="text-overflow-ellipsis font-M fw-700">
							{{ network.name }}
						</div>
						<div class="text-overflow-ellipsis font-S">
							{{ 'page.infrastructure.network.roles.' + network.role | translate }}
						</div>
					</div>
				</div>
				<app-chips-text [warning]="server.ipAddress ? !isIpInRange(server.ipAddress, network.ipAddress, network.subnet) ? ('page.infrastructure.network.serverNotInRange' | translate) : undefined : undefined">
					<span class="pad-0-XS font-M">{{ network.ipAddress + '' + network.subnet }}</span>
				</app-chips-text>
			</div>
			<div class="flex-align-center gap-XL">
				<div style="margin-left: auto">
					<div class="disp-f justify-center a-i-c">
						<img src="assets/icons/arrow-right.svg">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="w-100 flex-center flex-column" *ngIf="!network && !_loading" style="gap: 30px; height: 280px;">
		<img src="../../../../../../../../assets/illustrations/no-server-network.svg" width="200">
		<span class="grey">{{ 'page.infrastructure.network.noNetworkLinkedToTheServer' | translate }}</span>
	</div>
</div>
