<div class="flex-align-center flex-space-between mb-m">
	<span class="font-MM fw-700">Stockage</span>
</div>
<div class="w-100" style="position: relative" *ngIf="data.length > 0" (mouseout)="tooltip.display = 'none'" [style.height.px]="185">
    <canvas baseChart
            [data]="lineChartData"
            [type]="chartType"
            [options]="chartOptions"
    ></canvas>
    <div class="tooltip" style="padding: 3px 7px; pointer-events: none;" [ngStyle]="{'top': tooltip.top, 'left': tooltip.left, 'display': tooltip.display}" >
        <div class="flex-space-between font-M fw-700">
            <div>
                <span>{{ tooltip.day }} {{ 'global.monthShort.' + tooltip.month | translate }} {{ tooltip.year }}</span>
            </div>
            <div>
                <span *ngIf="!tooltip.noData" style="color:#3DC0FF;">{{ tooltip.value }}%</span>
                <span *ngIf="tooltip.noData" style="color:#B5B5C3;">-</span>
            </div>
        </div>
        <div *ngIf="tooltip.disks && tooltip.disks.length > 0" class="font-S fw-500 pr-m pl-m">
            <ul style="padding-inline-start: 0; margin-block-start: 5px; margin-block-end: 5px;">
                <li *ngFor="let disk of tooltip.disks" class="disp-f a-i-c j-c-sb">
                    <span class="fw-700">{{ disk.name }}</span>
                    <span class="fw-700">{{ disk.load }} %</span>
                </li>
            </ul>
        </div>
    </div>
</div>
