import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DesignSystemModule} from "../../../../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {Server} from "../../../../../../../services/model/resource.model";
import {Subscription, tap} from "rxjs";
import {ServerDetailData, ServerDetailService} from "../../../../../../../services/front/server-detail.service";
import {ServerUrlService} from "../../../../../../../services/front/server-url.service";
import {ServerService} from "../../../../../../../services/back/server.service";

@Component({
	selector: 'app-server-technical-information',
	standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule],
	templateUrl: './server-technical-information.component.html',
	styleUrl: './server-technical-information.component.scss'
})
export class ServerTechnicalInformationComponent implements OnInit, OnDestroy {

	tenantId: string;
	isEditor: boolean;
	isTenantInactivePlan: boolean;

	_initializing: boolean;

	server: Server;

	subscription: Subscription = new Subscription();

	constructor(private serverDetailService: ServerDetailService) {
	}

	ngOnInit() {
		this.subscription.add(this.serverDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.serverDetailService.getServerDetailDataChanges()
			.pipe(tap(data => this.setServerDetailData(data)))
			.subscribe());
	}

	private setServerDetailData(data: ServerDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.server = data.server;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	getUptime(uptime: number | null): string {
		if (uptime === null) {
			return '-';
		}
		const days = Math.floor(uptime / 86400);
		const hours = Math.floor((uptime % 86400) / 3600);
		const minutes = Math.floor((uptime % 3600) / 60);
		const seconds = uptime % 60;
		return `${days}d ${hours}h ${minutes}m ${seconds}s`;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
