import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NetworkRole, SubnetMask} from "../../../../../services/model/resource.model";
import {ipAddressValidator, networkConfigurationValidator, subnetValidator} from "../../../../../utils/forms.utils";
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {NetworkForm, NetworkService} from "../../../../../services/back/network.service";
import {filter} from "rxjs/operators";
import {CurrentTenantService} from "../../../../../services/front/current-tenant.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {IpCheckerService} from "../../../../../services/front/ip-checker.service";

@Component({
	selector: 'app-add-network',
	standalone: true,
	imports: [CommonModule, DesignSystemModule, ReactiveFormsModule, TranslateModule],
	templateUrl: './add-network.component.html',
	styleUrl: './add-network.component.scss',
	animations: [
		trigger('validators-appear', [
			transition(':enter', [
				style({opacity: 0, display: 'none', transform: 'translateY(-10px)'}),
				animate('300ms ease-in-out', style({opacity: 1, display: 'block', transform: 'translateY(0)'})),
			])
		])
	]

})
export class AddNetworkComponent implements OnInit, OnDestroy {
	networkRoleList: NetworkRole[] = Object.values(NetworkRole);
	subnetMaskList: SubnetMask[] = Object.values(SubnetMask);

	formGroup: FormGroup = new FormGroup({
		name: new FormControl<string | null>(null, {validators: [Validators.required]}),
		role: new FormControl<NetworkRole | null>(null, [Validators.required]),
		ipAddress: new FormControl<string | null>(null, [Validators.required, ipAddressValidator()]),
		subnetMask: new FormControl<SubnetMask | null>(null, [Validators.required, subnetValidator()]),
		description: new FormControl<string | null>(null),
	}, {validators: [networkConfigurationValidator]});

	subscription: Subscription = new Subscription();

	_saving: boolean = false;

	networkService: NetworkService = inject(NetworkService);
	currentTenantService: CurrentTenantService = inject(CurrentTenantService);

	ipCheckerService: IpCheckerService = inject(IpCheckerService);

	tenantId: string;

	@Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onGoBack: EventEmitter<void> = new EventEmitter<void>();

	ngOnInit(): void {
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges().subscribe(tenantId => {
			this.tenantId = tenantId;
		}));
	}

	closeDialog(event: boolean): void {
		this.close.emit(event);
	}

	submit(): void {
		this.subscription.add(this.switchSaving()
			.pipe(
				filter(() => this.formGroup.valid),
				switchMap((form) => this.networkService.create(this.tenantId, this.buildForm())),
				finalize(() => this.switchSaving())
			).subscribe(result => {
				this.closeDialog(true);
			}));
	}

	buildForm(): NetworkForm {
		return {
			name: this.nameFormControl.value,
			role: this.roleFormControl.value,
			ipAddress: this.ipAddressFormControl.value,
			subnet: this.subnetMaskFormControl.value,
			description: this.descriptionFormControl.value
		}
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	cidrToMask(cidr: string): string {
		return this.ipCheckerService.cidrToSubnetMask(cidr);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	get nameFormControl(): FormControl {
		return this.formGroup.get('name') as FormControl;
	}

	get roleFormControl(): FormControl {
		return this.formGroup.get('role') as FormControl;
	}

	get ipAddressFormControl(): FormControl {
		return this.formGroup.get('ipAddress') as FormControl;
	}

	get subnetMaskFormControl(): FormControl {
		return this.formGroup.get('subnetMask') as FormControl;
	}

	get descriptionFormControl(): FormControl {
		return this.formGroup.get('description') as FormControl;
	}
}
