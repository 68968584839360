<form class="flex-column gap-M" [formGroup]="formGroup">
	<app-text-input [input]="searchFormControl" [placeholder]="'page.appDetails.searchResource'|translate">
		<!--img icon-prefix class="filter-grey" src="assets/icons/search-md.svg" height="16px" alt=""/-->
		<app-icon-button icon-suffix [img]="'assets/icons/close.svg'" size="xs" color="primary" [panelClass]="'img-size-30'"
						 *ngIf="searchFormControl.value?.length > 0"
						 (click)="searchFormControl.reset('')"></app-icon-button>
	</app-text-input>
	<div class="overflow-y form-array-height radius-L border-light-grey" [formArrayName]="formName.resources">
		<div *ngFor="let resourceGroup of resourceFormGroups let i = index" class="flex-center pad-M gap-M border-light-grey-bottom"
			 [formGroupName]="i" [ngClass]="{'greyed-out': filteredOut(resourceGroup)}">
			<div class="flex-center resource-logo h-100">
				<app-server-icon [server]="getResource(resourceGroup)" [size]="38"></app-server-icon>
			</div>
			<div class="flex-column w-100">
				<span class="font-L fw-700">{{getResource(resourceGroup).name}}</span>
				<span class="font-M fw-500">{{getResource(resourceGroup).os || '-'}}</span>
			</div>
			<mat-checkbox [formControlName]="formElementName.selected" style="margin-top: -3px;"></mat-checkbox>
		</div>
	</div>
</form>
<div class="flex gap-M pad-top-L">
    <app-text-button type="stroked" class="w-50" [disabled]="_saving"
                     (click)="cancel.emit()">
        {{'button.cancel'|translate}}
    </app-text-button>
    <app-text-button type="flat" class="w-50" [disabled]="_initializing || formGroup.invalid" [loading]="_saving"
                     (click)="save()">
        {{(!data.defaultData ? 'button.add' : 'button.edit')|translate}}
    </app-text-button>
</div>
